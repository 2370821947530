import type { EReklamationsgrund } from '../contact';
import type { DeliveryService } from './subscription-entry.model';

export interface IDeliveryComplaintsBody {
  complaints: IDeliveryComplaint[];
}

export interface ISendDeliveryComplaintResponse extends IDeliveryComplaint {
  success: boolean;
  failedReason?: string;
  errorCode?: EDeliveryCompaintsFailureReason;
}

export interface IDeliveryComplaint {
  Text?: string;
  AuftragsNr: string;
  Reklamationsgrund: EReklamationsgrund;
  ReklamationsgrundText: string;
  Reklamationsverursacher: DeliveryService;
  Gpnr: string;
  FehltSeit: string;
}

export interface IDeliveryComplaintSAPResponse {
  error: {
    code: EDeliveryCompaintsFailureReason;
    message: {
      lang: 'de' | 'en';
      value: string;
    };
    innererror: {
      application: {
        component_id: string;
        service_namespace: string;
        service_id: string;
        service_version: string;
      };
      transactionid: string;
      timestamp: string;
      Error_Resolution: {
        Sap_Transaction: string;
        Sap_Note: string;
      };
      errordetails: Array<{
        code: string;
        message: string;
        propertyref: string;
        severity: 'error' | 'warning' | 'info';
        target: string;
        transition: false;
      }>;
    };
  };
}

export enum EDeliveryCompaintsFailureReason {
  GPNR_MISSING = 'ZSUBSCRIBERSERVICE/100',
  COMPLAINT_CANNOT_BE_PROCESSED = 'JKWWW/315',
}

export class DeliveryComplaintError extends Error {
  message: string;
  failedComplaints: ISendDeliveryComplaintResponse[];

  constructor({ message, failedComplaints }: { message: string; failedComplaints: ISendDeliveryComplaintResponse[] }) {
    super(message);
    this.message = message;
    this.failedComplaints = failedComplaints;
  }
}
