import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type { HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type { AccountVerificationResponseModel } from '@candyland/generic/tt-com-anmeldung/models';

@Component({
  selector: 'candyland-change-email-success',
  templateUrl: './change-email-success.component.html',
  styleUrls: ['./change-email-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailSuccessComponent implements OnInit {
  success: HttpStatusModelTyped<AccountVerificationResponseModel>;
  httpError = null;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string,
    private changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (typeof window !== 'undefined') {
        this.http.post(`${this.profileApiEndpoint}/user/email/verify/${params['vtoken']}`, null).subscribe({
          next: (success: HttpStatusModelTyped<AccountVerificationResponseModel>) => this.successHandler(success),
          error: (error) => this.errorHandler(error),
        });
      }
    });
  }

  private successHandler(success: HttpStatusModelTyped<AccountVerificationResponseModel>) {
    this.success = success;
    this.changeDetection.markForCheck();
  }

  private errorHandler(error: HttpErrorResponse) {
    this.httpError = error.error;
    this.changeDetection.markForCheck();
    console.error(error);
  }
}
