import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input, Output, Optional, EventEmitter, PLATFORM_ID } from '@angular/core';
import { TT_ASSETS_ENDPOINT } from '@candyland/angular/shared/util-nmo';
import type { Subscription } from 'rxjs';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'oauth-pay-per-li-button',
  templateUrl: './pay-per-li-button.component.html',
  styleUrls: ['./pay-per-li-button.component.scss'],
})
export class PayPerLiButtonComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() redirectUrl: string;
  @Input() clickCallback: () => void;
  @Input() mouseDownCallback: () => void;
  @Output() loadingIndicator: EventEmitter<boolean> = new EventEmitter<boolean>();
  public clicked = false;

  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(TT_ASSETS_ENDPOINT) public assetsEndpoint: string,
    @Optional() @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        if (params.nmo_init_social_login === 'pay-per-li') {
          this.loadingIndicator.emit(true);
          console.log('payPerLi-button - starting pay-per-li login');
          this.doRedirect();
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  doRedirect(): void {
    console.log('payPerLi-button - doing redirect');
    this.clicked = true;

    if (typeof this.clickCallback === 'function') {
      this.clickCallback();
    }

    if (isPlatformBrowser(this.platformId)) {
      window.location.href = this.redirectUrl;
    } else {
      console.log('not redirecting, running on server');
    }
  }

  handleMouseDown(): void {
    if (typeof this.mouseDownCallback === 'function') {
      this.mouseDownCallback();
    }
  }
}
