import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import type {
  AppEnum,
  AppStoreModel,
  Toast,
  ToastResponse,
  WindowPlugins,
} from '@candyland/angular/shared/cordova/model';
import {
  appData,
  IS_CORDOVA,
  CORDOVA_WEB_DOMAIN,
  CORDOVA_EXTERNAL_APP_DOMAIN,
  CORDOVA_INTERNAL_IOS_APP_DOMAIN,
  CORDOVA_INTERNAL_ANDROID_APP_DOMAIN,
  Platform,
  UpdateStatus,
} from '@candyland/angular/shared/cordova/model';
import { differenceInSeconds } from 'date-fns';
import { CordovaInAppBrowserService } from './cordova-in-app-browser.service';
import { CordovaDomService } from './cordova-dom.service';
import { Router } from '@angular/router';
import { CordovaSplashScreenService } from './cordova-splashscreen.service';

@Injectable({ providedIn: 'root' })
export class CordovaService {
  private readonly bootTime: Date;
  private readonly appOrigin: string;

  constructor(
    private inAppBrowser: CordovaInAppBrowserService,
    public dom: CordovaDomService,
    public splashScreen: CordovaSplashScreenService,
    private router: Router,
    private zone: NgZone,
    @Optional() @Inject(CORDOVA_EXTERNAL_APP_DOMAIN) private externalAppDomain?: string,
    @Optional() @Inject(CORDOVA_INTERNAL_ANDROID_APP_DOMAIN) private internalAndroidAppDomain?: string,
    @Optional() @Inject(CORDOVA_INTERNAL_IOS_APP_DOMAIN) private internalIosAppDomain?: string,
    @Optional() @Inject(CORDOVA_WEB_DOMAIN) private webDomain?: string,
    @Optional() @Inject(IS_CORDOVA) private isCordova?: boolean
  ) {
    if (!isCordova) {
      return;
    }

    this.bootTime = new Date();
    const localWindow = this.dom.window;
    this.appOrigin = localWindow !== null ? localWindow.location.href : null;
    console.log('app base origin:', this.appOrigin);

    if (!localWindow.appOrigin) {
      console.log('building reboot function');
      localWindow.appOrigin = this.appOrigin;
      localWindow.restartApp = (): void => {
        window.location = window['appOrigin'];
      };
    }
  }

  public get toast(): Toast {
    const localPlugins: WindowPlugins = this.dom.windowPlugins;
    return !localPlugins && !localPlugins.toast ? null : localPlugins.toast;
  }

  public get platform(): Platform {
    if (!this.isCordova) {
      return Platform.BROWSER;
    }
    switch (this.dom.device.platform) {
      case 'Android':
        return Platform.ANDROID;
      case 'iOS':
        return Platform.IOS;
      default:
        return Platform.BROWSER;
    }
  }

  public get onlineTime() {
    return differenceInSeconds(new Date(), this.bootTime);
  }

  public registerExternalUrlProcessing(): void {
    this.zone.runOutsideAngular((): void => {
      if (!this.dom.window['nmo']) {
        this.dom.window['nmo'] = {};
      }
      if (!this.dom.window.nmo['openURL']) {
        this.dom.window.nmo['openURL'] = {};
      }
      this.dom.window.nmo.openURL.event = new Event('nmoOpenURL');
      this.dom.window.nmo.openURL.active = false;
      this.dom.window['handleOpenURL'] = function (url: string): void {
        const nmo = window['nmo'];
        console.log('nmoOpenURL:', url);
        nmo.openURL.event['url'] = url;
        nmo.openURL.active = true;
        window.dispatchEvent(nmo.openURL.event);
      };
    });
  }

  public openUrl(url: string): void {
    const localWindow = this.dom.window;
    localWindow.handleOpenURL(url);
  }

  public processExternalUrl(url: string): void {
    console.log('processing url:', url);
    if (!this.externalAppDomain || !this.internalIosAppDomain || !this.internalAndroidAppDomain || !this.webDomain) {
      console.error(
        `no app domain or web domain configured! externalAppDomain: ${this.externalAppDomain}, internalIosAppDomain: ${this.internalIosAppDomain}, internalAndroidAppDomain: ${this.internalAndroidAppDomain}, webDomain: ${this.webDomain}`
      );
      return;
    }
    const localWindow = this.dom.window;
    localWindow.nmo.openURL.active = false;
    if (
      url.indexOf(this.externalAppDomain) === -1 &&
      url.indexOf(this.internalIosAppDomain) === -1 &&
      url.indexOf(this.internalAndroidAppDomain) === -1 &&
      url.indexOf(this.webDomain) === -1
    ) {
      if (url.startsWith('http')) {
        console.log('url not meant for app, opening in system browser...');
        this.inAppBrowser.open(url, '_system');
      } else {
        console.error('unknown url, doing nothing!', url);
      }
      return;
    }
    const urlComponent: string = url
      .slice(0)
      .replace(this.externalAppDomain, '')
      .replace(this.internalIosAppDomain, '')
      .replace(this.internalAndroidAppDomain, '')
      .replace(this.webDomain, '');
    const index: number = urlComponent.indexOf('?');
    const route: string = index !== -1 ? urlComponent.substr(0, index) : urlComponent;
    const paramStr: string = index !== -1 ? urlComponent.substr(index + 1, urlComponent.length - index - 1) : undefined;

    const searchParams: URLSearchParams = new URLSearchParams(paramStr);

    if (searchParams.get('pk_source') === 'emarsys' && searchParams.get('pk_medium') === 'mobile-push') {
      const regex = /\/(artikel|go)\/([0-9]*)/g;
      const match: RegExpExecArray = regex.exec(route);
      if (match) {
        searchParams.set('pk_campaign', match[2]);
      }
    }
    searchParams.sort();

    this.zone.run((): void => {
      // phpstorm is thinking searchParams.size does not exist...
      const queryString: string = searchParams.toString();
      if (queryString.length === 0) {
        console.log('activating route:', route);
        this.router.navigate([route]).then();
      } else {
        const urlString = `${route}?${queryString.toString()}`;
        console.log('activating route with query:', urlString);
        this.router.navigateByUrl(urlString).then();
      }
    });
  }

  public processUpdateStatus(status: UpdateStatus, app: AppEnum): void {
    console.log(`processUpdateStatus - status ${status}`);
    let store = 'App';
    switch (status) {
      case UpdateStatus.AVAILABLE:
        switch (this.platform) {
          case Platform.ANDROID:
            store = 'Play';
            break;
        }
        this.toast.showWithOptions(
          {
            message: `Ein App-Update ist verfügbar. Bitte klicken Sie hier, um zum ${store}-Store zu gelangen.`,
            duration: 'long',
            position: 'center',
          },
          (res: ToastResponse): void => {
            if (res?.event && res.event === 'touch') {
              this.openStore(app);
            }
          }
        );
        break;
      case UpdateStatus.REQUIRED:
        this.zone.run(() => this.router.navigate(['/update-required']).then()).then();
        break;
    }
  }

  public restartApp(): void {
    // https://stackoverflow.com/questions/15477887/restart-my-phonegap-app-programmatically
    // Show splash screen (useful if your app takes time to load)
    this.splashScreen.show();
    // Reload original app url (ie your index.html file)
    this.dom.window.restartApp();
  }

  public openStore(app: AppEnum): void {
    const config: AppStoreModel = appData[app].appStoreConfig;
    if (this.platform === Platform.IOS) {
      this.inAppBrowser.open(`itms-apps://itunes.apple.com/app/${config.ios.storeId}?mt=8`, '_system');
    } else {
      this.inAppBrowser.open(`market://details?id=${config.android.storeId}`, '_system');
    }
  }
}
