<div class="router-wrap">
  <ng-container *ngIf="!disableLogin; else loginDisabledTemplate">
    <tt-com-anmeldung-ui-default-message-box *ngIf="maintenanceInfo" [boxType]="level">{{
      maintenanceInfo.message
    }}</tt-com-anmeldung-ui-default-message-box>
    <router-outlet></router-outlet>
  </ng-container>

  <ng-template #loginDisabledTemplate>
    <div class="sections"></div>
    <mat-card appearance="outlined" class="loginDisabled">
      <a href="https://www.tt.com">
        <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
      </a>
      <div class="message-box-wrapper">
        <tt-com-anmeldung-ui-default-message-box class="test" *ngIf="maintenanceInfo" [boxType]="level">{{
          maintenanceInfo.message
        }}</tt-com-anmeldung-ui-default-message-box>
      </div>
    </mat-card>
  </ng-template>
</div>

<tt-com-anmeldung-ui-simple-footer></tt-com-anmeldung-ui-simple-footer>
