import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import type { OnInit } from '@angular/core';
import type { HttpStatusModel, HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type { AccountCreateModel, AccountCreateResponseModel } from '@candyland/generic/shared/tt-com-anmeldung/models';
import { GrecaptchaComponent } from '../../grecaptcha/grecaptcha.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';
import { REGISTRATION_PAGE_SETTINGS, RegistrationPageSettings } from '../../token';
import {
  APPLE_LOGIN_REDIRECT_URL,
  ENABLE_APPLE_LOGIN,
  ENABLE_GOOGLE_LOGIN,
  ENABLE_MEDIA_KEY,
  ENABLE_PAY_PER_LI_LOGIN,
  GOOGLE_LOGIN_REDIRECT_URL,
  MEDIA_KEY_REDIRECT_URL,
  PAY_PER_LI_LOGIN_REDIRECT_URL,
  TT_COM_WWW_FRONTEND,
  WindowService,
} from '@candyland/angular/shared/util-nmo';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  passwordRepeatValidator,
  RepeatPasswordStateMatcher,
  scrollToInvalidInput,
} from '@candyland/angular/tt-com-anmeldung/ui';
import { from, of } from 'rxjs';
import type { Observable } from 'rxjs';
import { TermsComponent } from '@candyland/angular/shared/tt-com-anmeldung/feature';
import type { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import type { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'candyland-registration-step-email',
  templateUrl: './registration-step-email.component.html',
  styleUrls: ['./registration-step-email.component.scss'],
})
export class RegistrationStepEmailComponent implements OnInit {
  @Output()
  next = new EventEmitter<HttpStatusModelTyped<AccountCreateResponseModel>>();

  @ViewChild('recaptcha')
  recaptcha: GrecaptchaComponent;

  login_challenge: string;
  email: string = null;
  socialButtonsEnabled = false;
  linkSubscriptionMode: 'none' | 'single' = 'none';

  appearance: MatFormFieldAppearance = 'outline';
  showPassword = false;
  showPasswordRepeat = false;
  showRegisterForm = true;
  showHint = false;
  matcher = new RepeatPasswordStateMatcher();

  linkingType: 'customernumber' | 'telephone' = null;

  accountData: AccountCreateModel = null;
  submitted = false;
  httpError: Partial<HttpStatusModel> = null;
  appendixForEmailInUse = false;
  success: HttpStatusModelTyped<AccountCreateResponseModel> = null;

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', { validators: [Validators.required, Validators.email] }),
      password: new UntypedFormControl('', {
        validators: [Validators.minLength(this.settings.minPasswordLength), Validators.required],
      }),
      passwordRepeat: new UntypedFormControl('', {
        validators: [Validators.minLength(this.settings.minPasswordLength), Validators.required],
      }),
      termsAgreed: new UntypedFormControl(false, { validators: [Validators.required] }),
      subscriptionActivationForm: new UntypedFormControl(false),
    },
    { validators: passwordRepeatValidator }
  );

  public readonly enableMediaKey: boolean;
  public readonly enableGoogleLogin: boolean;
  public readonly enableAppleLogin: boolean;
  public readonly enablePayPerLiLogin: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private router: Router,
    private windowService: WindowService,
    @Inject(REGISTRATION_PAGE_SETTINGS) public settings: RegistrationPageSettings,
    @Inject(TT_COM_WWW_FRONTEND) public readonly ttComFrontendEndpoint: string,

    // media key
    @Inject(MEDIA_KEY_REDIRECT_URL) public readonly mediaKeyRedirectUrl: string,
    @Inject(ENABLE_MEDIA_KEY) enableMediaKeyString: string,

    // google
    @Inject(GOOGLE_LOGIN_REDIRECT_URL) public readonly googleLoginRedirectUrl: string,
    @Inject(ENABLE_GOOGLE_LOGIN) enableGoogleLoginString: string,

    // apple
    @Inject(APPLE_LOGIN_REDIRECT_URL) public readonly appleLoginRedirectUrl: string,
    @Inject(ENABLE_APPLE_LOGIN) enableAppleLoginString: string,

    // PayPerLi login
    @Inject(PAY_PER_LI_LOGIN_REDIRECT_URL) public readonly payPerLiLoginRedirectUrl: string,
    @Inject(ENABLE_PAY_PER_LI_LOGIN) enablePayPerLiLoginString: string,

    public dialog: MatDialog
  ) {
    this.enableMediaKey = this.toBoolean(enableMediaKeyString);
    this.enableGoogleLogin = this.toBoolean(enableGoogleLoginString);
    this.enableAppleLogin = this.toBoolean(enableAppleLoginString);
    this.enablePayPerLiLogin = this.toBoolean(enablePayPerLiLoginString);
  }

  ngOnInit(): void {
    this.login_challenge = this.activatedRoute.snapshot.queryParamMap.get('login_challenge');
    this.email = this.activatedRoute.snapshot.queryParamMap.get('nmo_email');
    this.socialButtonsEnabled = this.activatedRoute.snapshot.queryParamMap.get('social_buttons_enabled') === 'true';

    if (this.email !== null) {
      this.form.patchValue({ email: this.email });
    }
  }

  onSubmit(linkingType?: 'customernumber' | 'telephone'): void {
    if (!this.form.valid && typeof window !== 'undefined') {
      scrollToInvalidInput();
      return;
    }

    if (!this.form.controls.termsAgreed.value) {
      this.httpError = { message: 'Bitte akzeptieren Sie die AGBs.' };
      this.appendixForEmailInUse = false;
      return;
    }

    this.accountData = {
      email: this.form.controls.email.value,
      termsAgreed: this.form.controls.termsAgreed.value,
      password: this.form.controls.password.value,
      useOtp: true,
    };

    if (!linkingType) {
      of(this.accountData)
        .pipe(
          tap(() => {
            this.httpError = null;
            this.submitted = true;
            this.form.disable();
          }),
          switchMap((d) => this.activatedRoute.queryParamMap.pipe(map((q) => ({ qp: q, account: d })))),
          map((x) => this.mapQueryParamsToAccount(x.qp, x.account)),
          switchMap((d) => this.createReCaptchaExecuteObservable(d)),
          switchMap((d) => this.createReCaptchaValidateObservable(d)),
          switchMap((d) => this.registrationService.createAccount(d))
        )
        .subscribe({
          next: (responseData: HttpStatusModelTyped<AccountCreateResponseModel>) => {
            this.success = responseData;

            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: { email: this.accountData.email, otpPart: responseData.data.otpPart },
              queryParamsHandling: 'merge',
            });

            if (responseData.status === 201) {
              this.next.emit(responseData);
            } else {
              this.httpError = { message: 'Ein unbekannter Fehler ist aufgetreten.' };
            }
          },
          error: (error: HttpErrorResponse) => {
            this.errorHandler(error);
          },
        });
    } else {
      of(this.accountData)
        .pipe(
          tap(() => {
            this.httpError = null;
            this.form.disable();
          }),
          map((data) => data.email),
          switchMap((email) => this.registrationService.checkEmail(email))
        )
        .subscribe({
          next: (success: any) => {
            if (success.data.emailAlreadyUsed) {
              this.httpError = { message: 'Die angegebene E-Mailadresse wird bereits verwendet.' };
              this.appendixForEmailInUse = true;
            } else {
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { email: this.accountData.email },
                queryParamsHandling: 'merge',
              });
              this.linkingType = linkingType;
              this.showRegisterForm = false;
            }
            this.form.enable();
          },
          error: (error: HttpErrorResponse) => this.errorHandler(error),
        });
    }
  }

  openTermsDialog(): void {
    this.dialog.open(TermsComponent, {});
  }

  toggleHint(): void {
    this.showHint = !this.showHint;
  }

  toggleSingleSubscriptionLinkingArea(): void {
    this.linkSubscriptionMode = this.linkSubscriptionMode !== 'none' ? 'none' : 'single';
  }

  selectRegistrationErrorLink(): void {
    if (this.login_challenge) {
      this.router.navigate(['login'], {
        queryParamsHandling: 'merge',
      });
    } else if (this.windowService.isBrowser) {
      window.location.href = `${this.ttComFrontendEndpoint}/login`;
    }
  }

  private createReCaptchaExecuteObservable(
    data: AccountCreateModel
  ): Observable<{ recaptchaCode: string; account: AccountCreateModel }> {
    return from(this.recaptcha.execute()).pipe(map((code: string) => ({ recaptchaCode: code, account: data })));
  }

  private toBoolean = (input: string | boolean): boolean => input.toString().toLowerCase() === 'true';

  private createReCaptchaValidateObservable(data: {
    recaptchaCode: string;
    account: AccountCreateModel;
  }): Observable<AccountCreateModel> {
    return this.registrationService.validateReCaptcha(data.recaptchaCode).pipe(map(() => data.account));
  }

  private mapQueryParamsToAccount(queryParams: ParamMap, account: AccountCreateModel): AccountCreateModel {
    if (!queryParams || queryParams.keys.length === 0) {
      return account;
    }

    account.queryParams = {};
    queryParams.keys.forEach((k) => (account.queryParams[k] = queryParams.get(k)));
    return account;
  }

  private errorHandler(error: HttpErrorResponse): void {
    this.form.enable();
    const isHttpStatus = (input: HttpStatusModel): input is HttpStatusModel =>
      Object.prototype.hasOwnProperty.call(input, 'requestId');
    if (isHttpStatus(error.error)) {
      if (error.error.status === 400 && error.error.number === 2) {
        this.appendixForEmailInUse = true;
      }
    }
    this.httpError = error.error;
  }
}
