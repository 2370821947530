import { NgModule, Optional, TransferState } from '@angular/core';
import {
  MEDIA_KEY_REDIRECT_URL,
  ENABLE_MEDIA_KEY,
  GOOGLE_LOGIN_REDIRECT_URL,
  ENABLE_GOOGLE_LOGIN,
  APPLE_LOGIN_REDIRECT_URL,
  ENABLE_APPLE_LOGIN,
  PAY_PER_LI_LOGIN_REDIRECT_URL,
  ENABLE_PAY_PER_LI_LOGIN,
  runtimeConfigFactory,
  TT_ASSETS_ENDPOINT,
  TT_COM_WWW_FRONTEND,
  TT_COM_PROCESSING_ENDPOINT,
} from '@candyland/angular/shared/util-nmo';
import type { LoginPageSettings } from '@candyland/angular/tt-com-anmeldung/feature-login';
import { LOGIN_PAGE_SETTINGS } from '@candyland/angular/tt-com-anmeldung/feature-login';
import type { RegistrationPageSettings } from '@candyland/angular/tt-com-anmeldung/feature-registration';
import { REGISTRATION_PAGE_SETTINGS } from '@candyland/angular/tt-com-anmeldung/feature-registration';
import type { ChangePasswordPageSettings } from '@candyland/angular/tt-com-anmeldung/feature-reset-password';
import { CHANGE_PASSWORD_PAGE_SETTINGS } from '@candyland/angular/tt-com-anmeldung/feature-reset-password';
import { OAUTH_API_ENDPOINT } from '@candyland/angular/tt-com-anmeldung/model';
import { environment } from '../environments/environment';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import { MAINTENANCE_SCHEDULE, NCONF } from '@candyland/angular/shared/model-nmo';

@NgModule({
  providers: [
    {
      provide: OAUTH_API_ENDPOINT,
      useFactory: runtimeConfigFactory('OAUTH_API_ENDPOINT', environment.oauthApiEndpoint),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: PROFILE_API_ENDPOINT,
      useFactory: runtimeConfigFactory('PROFILE_API_ENDPOINT', environment.profileApiEndpoint),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: TT_ASSETS_ENDPOINT,
      useFactory: runtimeConfigFactory('TT_ASSETS_ENDPOINT', environment.ttAssetsEndpoint),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: TT_COM_WWW_FRONTEND,
      useFactory: runtimeConfigFactory('TT_COM_WWW_FRONTEND', environment.ttComFrontendEnpoint),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    // media key
    {
      provide: MEDIA_KEY_REDIRECT_URL,
      useFactory: runtimeConfigFactory('MEDIA_KEY_REDIRECT_URL', environment.mediaKeyRedirectUrl),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: ENABLE_MEDIA_KEY,
      useFactory: runtimeConfigFactory('ENABLE_MEDIA_KEY', environment.enableMediaKey),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    // google login
    {
      provide: GOOGLE_LOGIN_REDIRECT_URL,
      useFactory: runtimeConfigFactory('GOOGLE_LOGIN_REDIRECT_URL', environment.googleLoginRedirectUrl),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: ENABLE_GOOGLE_LOGIN,
      useFactory: runtimeConfigFactory('ENABLE_GOOGLE_LOGIN', environment.enableGoogleLogin),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    // apple login
    {
      provide: APPLE_LOGIN_REDIRECT_URL,
      useFactory: runtimeConfigFactory('APPLE_LOGIN_REDIRECT_URL', environment.appleLoginRedirectUrl),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: ENABLE_APPLE_LOGIN,
      useFactory: runtimeConfigFactory('ENABLE_APPLE_LOGIN', environment.enableAppleLogin),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    // apple login
    {
      provide: PAY_PER_LI_LOGIN_REDIRECT_URL,
      useFactory: runtimeConfigFactory('PAY_PER_LI_LOGIN_REDIRECT_URL', environment.payPerLiLoginRedirectUrl),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: ENABLE_PAY_PER_LI_LOGIN,
      useFactory: runtimeConfigFactory('ENABLE_PAY_PER_LI_LOGIN', environment.enablePayPerLiLogin),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: LOGIN_PAGE_SETTINGS,
      useValue: {
        resetPasswordUrlOrPath: '/reset-password',
        signUpUrlOrPath: '/registrieren',
        helpLinks: [{ text: 'Hilfe', url: 'https://benutzerkonto.tt.com/kontakt' }],
      } as LoginPageSettings,
    },
    {
      provide: REGISTRATION_PAGE_SETTINGS,
      useValue: {
        gRecaptchaSiteKey: '6Le-1vwUAAAAAM29mZ6n0J-X3wdl8JxJd92EhGuZ',
        minPasswordLength: 10,
      } as RegistrationPageSettings,
    },
    {
      provide: CHANGE_PASSWORD_PAGE_SETTINGS,
      useValue: {
        minPasswordLength: 10,
        otpLength: 6,
      } as ChangePasswordPageSettings,
    },
    {
      provide: MAINTENANCE_SCHEDULE,
      useFactory: runtimeConfigFactory('MAINTENANCE_SCHEDULE', ''),
      deps: [TransferState, [new Optional(), NCONF]],
    },
    {
      provide: TT_COM_PROCESSING_ENDPOINT,
      useFactory: runtimeConfigFactory('TT_COM_PROCESSING_ENDPOINT', environment.tt_com_processing_endpoint),
      deps: [TransferState, [new Optional(), NCONF]],
    },
  ],
})
export class AppConfigModule {}
