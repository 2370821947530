import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input, Optional, PLATFORM_ID } from '@angular/core';
import { CordovaInAppBrowserService } from '@candyland/angular/shared/cordova/util';
import { IS_CORDOVA } from '@candyland/angular/shared/cordova/model';
import { TT_ASSETS_ENDPOINT } from '@candyland/angular/shared/util-nmo';
import type { Subscription } from 'rxjs';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'oauth-apple-button',
  templateUrl: './apple-button.component.html',
  styleUrls: ['./apple-button.component.scss'],
})
export class AppleButtonComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() redirectUrl: string;
  @Input() clickCallback: () => void;
  @Input() mouseDownCallback: () => void;
  public clicked = false;

  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private inAppBrowser: CordovaInAppBrowserService,
    @Inject(TT_ASSETS_ENDPOINT) public assetsEndpoint: string,
    @Optional() @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(IS_CORDOVA) private isCordova?: boolean
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        if (params.nmo_init_social_login === 'apple') {
          console.log('google-button - starting apple login');
          this.doRedirect();
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  doRedirect(): void {
    this.clicked = true;

    if (typeof this.clickCallback === 'function') {
      this.clickCallback();
    }

    if (this.isCordova) {
      this.inAppBrowser.open(this.redirectUrl, '_blank');
    } else if (isPlatformBrowser(this.platformId)) {
      window.location.href = this.redirectUrl;
    } else {
      console.log('not redirecting, running on server');
    }
  }

  handleMouseDown() {
    if (typeof this.mouseDownCallback === 'function') {
      this.mouseDownCallback();
    }
  }
}
