import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type { Observable } from 'rxjs';
import { RegistrationPageSettings, REGISTRATION_PAGE_SETTINGS } from '../token';
import type { AccountCreateModel, AccountCreateResponseModel } from '@candyland/generic/shared/tt-com-anmeldung/models';
import type { HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type {
  AccountOTPVerificationRequestModel,
  AccountOTPVerificationResponseModel,
} from '@candyland/generic/tt-com-anmeldung/models';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(
    private http: HttpClient,
    @Inject(REGISTRATION_PAGE_SETTINGS) private settings: RegistrationPageSettings,
    @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string
  ) {}

  public createAccount(account: AccountCreateModel): Observable<HttpStatusModelTyped<AccountCreateResponseModel>> {
    return this.http.post<HttpStatusModelTyped<AccountCreateResponseModel>>(
      `${this.profileApiEndpoint}/user/account`,
      account
    );
  }

  public checkEmail(email: string) {
    return this.http.get<HttpStatusModelTyped<AccountCreateResponseModel>>(
      `${this.profileApiEndpoint}/user/email/check-availability/${email}`
    );
  }

  public validateReCaptcha(code: string): Observable<any> {
    const requestData = { siteKey: this.settings.gRecaptchaSiteKey, code: code };
    return this.http.post(`${this.profileApiEndpoint}/recaptcha`, requestData);
  }

  public verifyAccountWithOTP(
    otp: AccountOTPVerificationRequestModel
  ): Observable<HttpStatusModelTyped<AccountOTPVerificationResponseModel>> {
    return this.http.post<HttpStatusModelTyped<AccountOTPVerificationResponseModel>>(
      `${this.profileApiEndpoint}/user/account/verify-otp`,
      otp
    );
  }
}
