import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
  MatNativeDateModule,
} from '@angular/material/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularTtComAnmeldungFeatureActivateAccountModule } from '@candyland/angular/tt-com-anmeldung/feature-activate-account';
import { AngularTtComAnmeldungFeatureResetPasswordModule } from '@candyland/angular/tt-com-anmeldung/feature-reset-password';
import { AngularTtComAnmeldungUiModule } from '@candyland/angular/tt-com-anmeldung/ui';
import { AppConfigModule } from './app-config.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { registerLocaleData } from '@angular/common';
import { DefaultDateAdapter } from '@candyland/angular/shared/util-nmo';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'tt-com-anmeldung-oauth-ng' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AngularTtComAnmeldungFeatureResetPasswordModule,
    AngularTtComAnmeldungFeatureActivateAccountModule,
    AppRoutingModule,
    AngularTtComAnmeldungUiModule,
    AppConfigModule,
    MatSelectModule,
    OAuthModule.forRoot(),
    MatNativeDateModule,
    MatDatepickerModule,
    MatCardModule,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: DateAdapter, useClass: DefaultDateAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
