import type { ResetPasswordReturns } from '../../servicesV2/reset-password.service';
import { ResetPasswordService } from '../../servicesV2/reset-password.service';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import type { ResetPasswordRequestModel } from '@candyland/generic/tt-com-anmeldung/models';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'candyland-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', { validators: [Validators.required, Validators.email] }),
  });

  httpErrorMessage: string;

  constructor(
    private resetPasswordService: ResetPasswordService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  handleSubmit(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.formGroup.disable();

    const resetPasswordData: ResetPasswordRequestModel = {
      email: this.formGroup.controls.email.value,
    };

    of(resetPasswordData)
      .pipe(
        tap((): void => {
          this.httpErrorMessage = null;
          this.formGroup.disable();
        }),
        switchMap((resetModel: ResetPasswordRequestModel) =>
          this.route.queryParamMap.pipe(map((queryParams: ParamMap) => ({ qp: queryParams, model: resetModel })))
        ),
        map((reset: { qp: ParamMap; model: ResetPasswordRequestModel }) =>
          this.mapRouteParamsToModel(reset.qp, reset.model)
        ),
        switchMap((resetModel: ResetPasswordRequestModel) => this.resetPasswordService.resetPassword(resetModel))
      )
      .subscribe({
        next: (data: ResetPasswordReturns) => this.handleSuccess(data),
        error: (error) => this.handleError(error),
      });
  }

  private handleError(error: Error): void {
    this.httpErrorMessage =
      error?.message !== undefined && error?.message !== ''
        ? error.message
        : 'Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es noch einmal.';
    this.formGroup.enable();
  }

  private handleSuccess(data: ResetPasswordReturns): void {
    this.router
      .navigate(['change-password'], {
        queryParams: {
          otpPart: data.otpPart,
          email: data.email,
          ...(data.queryParams?.login_challenge ? { login_challenge: data.queryParams?.login_challenge } : {}),
        },
      })
      .then();
  }

  private mapRouteParamsToModel(queryParams: ParamMap, model: ResetPasswordRequestModel): ResetPasswordRequestModel {
    if (!queryParams || queryParams.keys.length === 0) {
      return model;
    }

    model.queryParams = {};
    queryParams.keys.forEach((paramKey: string) => (model.queryParams[paramKey] = queryParams.get(paramKey)));

    return model;
  }
}
