<div class="wrapper">
  <div class="divider" *ngIf="!hideSeparator">oder</div>
  <button
    mat-raised-button
    type="button"
    [disabled]="disabled || clicked"
    (click)="doRedirect()"
    (mousedown)="handleMouseDown()"
    data-cy="media-key-login"
  >
    <img
      ngSrc="{{ assetsEndpoint }}/assets/media-key/MediaKey_Logo_Red.svg"
      width="18"
      height="18"
      alt="MediaKey Logo"
    />
    <span class="login-text">Anmelden mit MediaKey</span>
    <span *ngIf="clicked" class="loading-overlay">
      <mat-spinner [diameter]="16"></mat-spinner>
    </span>
  </button>
</div>
