import { InjectionToken } from '@angular/core';

export interface LoginPageSettings {
  signUpUrlOrPath: string;
  resetPasswordUrlOrPath: string;
  helpLinks: { url: string; text: string }[];
}

export const LOGIN_PAGE_SETTINGS: InjectionToken<LoginPageSettings> = new InjectionToken<LoginPageSettings>(
  'LOGIN_PAGE_SETTINGS'
);
