<div class="wrapper">
  <button
    mat-raised-button
    type="button"
    [disabled]="disabled || clicked"
    (click)="doRedirect()"
    (mousedown)="handleMouseDown()"
    data-cy="pay-per-li-login"
  >
    <img ngSrc="{{ assetsEndpoint }}/assets/pay-per-link/Payperli.jpg" width="18" height="18" alt="PayPerLi Logo" />
    <span class="login-text">Anmelden mit Pay Per Link</span>
    <span *ngIf="clicked" class="loading-overlay">
      <mat-spinner [diameter]="16"></mat-spinner>
    </span>
  </button>
</div>
