import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import * as Constants from './devices.constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserSystemInfoService {
  userAgent = '';
  browser = Constants.GENERAL.UNKNOWN;
  browser_version = Constants.GENERAL.UNKNOWN;
  os = Constants.GENERAL.UNKNOWN;
  os_version = Constants.GENERAL.UNKNOWN;
  cookieEnabled = false;
  orientation = Constants.GENERAL.UNKNOWN;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      this.userAgent = window.navigator.userAgent;
    }
    this.setDeviceInfo(this.userAgent);
  }

  /**
   * Sets the initial value of the device when the service is initiated.
   */
  private setDeviceInfo(ua = this.userAgent): void {
    if (ua !== this.userAgent) {
      this.userAgent = ua;
    }
    const mappings = [
      { const: 'OS', prop: 'os' },
      { const: 'BROWSERS', prop: 'browser' },
      { const: 'OS_VERSIONS', prop: 'os_version' },
    ];

    mappings.forEach((mapping) => {
      this[mapping.prop] = Object.keys(Constants[`${mapping.const}`]).reduce((obj: any, item: any) => {
        obj[item] = this.test(ua, Constants[`${mapping.const}`][item]);
        return obj;
      }, {});
    });

    mappings.forEach((mapping) => {
      this[mapping.prop] =
        Object.keys(this[mapping.prop]).find((item) => !!this[mapping.prop][item]) || Constants.GENERAL.UNKNOWN;
    });

    if (this.browser !== Constants.GENERAL.UNKNOWN) {
      const re = Constants.BROWSER_VERSIONS_MAP[this.browser];
      const res = this.exec(ua, re);
      if (res) {
        this.browser_version = res[1];
      }
    }

    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      if (window.matchMedia) {
        this.orientation = window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
      }

      if (window.navigator) {
        this.cookieEnabled = window.navigator.cookieEnabled;
      }
    }
  }

  /**
   * Returns the device information
   */
  public getDeviceInfo(): DeviceInfo {
    return {
      browser: this.browser,
      browser_version: this.browser_version,
      cookieEnabled: this.cookieEnabled,
      orientation: this.orientation,
      os: this.os,
      os_version: this.os_version,
    };
  }

  protected test(str: string, regex: RegexType): boolean {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }
    if (regex instanceof RegExp) {
      return regex.test(str);
    } else if (regex && Array.isArray(regex.and)) {
      return regex.and.every((item: RegexType) => this.test(str, item));
    } else if (regex && Array.isArray(regex.or)) {
      return regex.or.some((item: RegexType) => this.test(str, item));
    } else if (regex?.not) {
      return !this.test(str, regex.not);
    } else {
      return false;
    }
  }

  protected exec(str: string, regex: RegexType): RegExpExecArray | null {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.exec(str);
    } else if (regex && Array.isArray(regex)) {
      return regex.reduce<RegExpExecArray | null>((res, item: RegexType) => res || this.exec(str, item), null);
    } else {
      return null;
    }
  }
}
export interface DeviceInfo {
  browser: string;
  browser_version: string;
  cookieEnabled: boolean;
  orientation: string;
  os: string;
  os_version: string;
}

type RegexType = RegExp | string | { and?: RegexType[]; or?: RegexType[]; not?: RegexType };
