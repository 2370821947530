<div class="close">
  <h3>TT-Benutzerkonto</h3>
  <span (click)="dispose()">X</span>
</div>

<div class="benefits-container">
  <p class="benefits-intro">
    Als TT-Abonnent genießen Sie durch die Erstellung eines Benutzerkontos folgende Vorteile:
  </p>

  <ul class="benefits">
    <li>Zugang zum TT-ePaper – lesen Sie die elektronische Version der gedruckten TT schon am Vortag ab 23 Uhr</li>
    <li>Teilnahme an exklusiven TT-Club-Online-Gewinnspielen</li>
    <li>Download ermäßigter Gutscheine auf club.tt.com</li>
    <li>Ermäßigte Veranstaltungstickets online auf club.tt.com kaufen</li>
    <li>TT-Abo verwalten</li>
    <li>TT-Club-Mitgliedschaft gratis für Familienmitglieder erweitern</li>
    <li>TT-Artikel online kommentieren</li>
  </ul>

  <p>
    Test- und Schnupperabonnenten haben Zugang zum TT-ePaper und können redaktionelle Artikel auf
    <a href="https://www.tt.com">www.tt.com</a> kommentieren.
  </p>
</div>
