import { Component, Inject } from '@angular/core';
import { MAINTENANCE_SCHEDULE } from '@candyland/angular/shared/model-nmo';
import type { MaintenanceFrontendModel } from '@candyland/generic/shared/model-nmo/global-settings';
import { MessageBoxType } from '@candyland/angular/tt-com-anmeldung/ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public maintenanceInfo: MaintenanceFrontendModel;
  public level: MessageBoxType = MessageBoxType.info;
  public disableLogin = false;

  constructor(@Inject(MAINTENANCE_SCHEDULE) public maintenanceScheduleString: string) {
    const maintenanceSchedule: MaintenanceFrontendModel[] = maintenanceScheduleString
      ? JSON.parse(maintenanceScheduleString)
      : [];
    for (const schedule of maintenanceSchedule) {
      const curr: Date = new Date();
      const startDate: Date = new Date(schedule.start);
      const endDate: Date = new Date(schedule.end);

      if (curr.getTime() - startDate.getTime() < 0) {
        break;
      }
      if (curr.getTime() - endDate.getTime() > 0) {
        break;
      }

      switch (schedule.level) {
        case 'info':
          this.level = MessageBoxType.info;
          break;
        case 'warning':
          this.level = MessageBoxType.warning;
          break;
        case 'error':
          this.level = MessageBoxType.error;
          break;
      }

      if (schedule.commands?.indexOf('disable-login') > -1) {
        this.disableLogin = true;
      }

      this.maintenanceInfo = schedule;
    }
  }
}
