import { InjectionToken } from '@angular/core';

type ObjectValues<T> = T[keyof T];

/**
 * ⚠️ session-api mode NOT compatible with having distinct session in each tab (like profile-ng using sessionStorage).
 *
 * https://mohomedia.atlassian.net/wiki/spaces/SSO/pages/3420422145/OAuth2+Longer+Sessions
 */
export const OAuth2Mode = {
  Direct: 'direct',
  SessionApi: 'session-api',
} as const;
// eslint-disable-next-line no-redeclare
export type OAuth2Mode = ObjectValues<typeof OAuth2Mode>;

/**
 * ⚠️ session-api mode NOT compatible with having distinct session in each tab (like profile-ng using sessionStorage).
 *
 * https://mohomedia.atlassian.net/wiki/spaces/SSO/pages/3420422145/OAuth2+Longer+Sessions
 */
export const OAUTH2_MODE = new InjectionToken<OAuth2Mode>('OAuth2 Mode');
