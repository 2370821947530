// for more information - `https://mohomedia.atlassian.net/wiki/spaces/EN/pages/3020324882/HttpRequestHandler+Utility`

import { catchError, concat, map, of } from 'rxjs';
import type { Observable } from 'rxjs';
import type { HttpErrorResponse } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import type { Signal } from '@angular/core';

export interface HttpResponseHandle<T, ErrorResponseType = HttpErrorResponse> {
  loading: boolean;
  data: T | null;
  error: ErrorResponseType | null;
}

export class HttpRequestHandler {
  public static makeTrackable<T, E = HttpErrorResponse>(
    httpRequest$: Observable<T>
  ): Observable<HttpResponseHandle<T, E>> {
    return concat(of(null), httpRequest$).pipe(
      map((data) => {
        if (!data) {
          return {
            loading: true,
            data: null,
            error: null,
          };
        }
        return {
          loading: false,
          data,
          error: null,
        };
      }),
      catchError((error: E) =>
        of({
          loading: false,
          data: null,
          error,
        })
      )
    );
  }

  public static httpToSignal<T>(
    httpRequest$: Observable<T>,
    options: { initialValue: T }
  ): Signal<HttpResponseHandle<T>> {
    const httpAction: Observable<HttpResponseHandle<T>> = HttpRequestHandler.makeTrackable(httpRequest$);

    return toSignal(httpAction, { initialValue: { data: options.initialValue, loading: true, error: null } });
  }
}
