import { Component, ChangeDetectionStrategy, Inject, Optional } from '@angular/core';
import { RESPONSE } from '@candyland/generic/shared/util-nmo';
import { Response } from 'express';

@Component({
  selector: 'tt-com-anmeldung-ui-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  constructor(@Optional() @Inject(RESPONSE) private response: Response) {
    if (this.response) {
      this.response.statusCode = 404;
    }
  }
}
