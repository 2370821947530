import type { AbstractControl, ValidatorFn } from '@angular/forms';

export function validateSubscriptionName(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const firstName =
      typeof control.parent?.controls?.['firstName']?.value === 'undefined'
        ? null
        : control.parent?.controls?.['firstName']?.value;

    const lastName =
      typeof control.parent?.controls?.['lastName']?.value === 'undefined'
        ? null
        : control.parent?.controls?.['lastName']?.value;
    const hasError = (firstName === null || firstName === '') && (lastName === null || lastName === '');
    return hasError ? { subscriptionNameError: true } : null;
  };
}
