import { Component, Input } from '@angular/core';

@Component({
  selector: 'tt-com-anmeldung-ui-generic-redirect-block',
  template: `
    <p>Folgende Möglichkeiten haben Sie nun mit Ihrem TT-Benutzerkonto:</p>
    <ul>
      <li *ngFor="let link of redirectLinks">
        <a [href]="link.url">{{ link.title }}</a>
      </li>
    </ul>
  `,
})
export class GenericRedirectBlockComponent {
  @Input() redirectLinks: {
    title: string;
    url: string;
  }[] = [
    {
      title: 'TT-Plus-Artikel lesen',
      url: 'https://www.tt.com/plus',
    },
    {
      title: 'tt.com-Startseite besuchen',
      url: 'https://www.tt.com',
    },
  ];
}
