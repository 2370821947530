export enum CountryCode {
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  BG = 'BG',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  GR = 'GR',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  JP = 'JP',
  LI = 'LI',
  LV = 'LV',
  LT = 'LT',
  LU = 'LU',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
}
