import type { HttpStatusMapModel } from '@candyland/generic/shared/model-nmo';

// Hydra Errors start at 200
// If you add or change texts here - please check back with the PlatformManagement team.

export enum HttpStatus200 {
  Ok = 1,
  AccountDeleted = 2,
  ProfileImageDeleted = 3,
  VerificationEmailSent = 4,
  EmailAddressVerified = 5,
  PasswordResetEmailSent = 6,
  AccountVerified = 7,
  PasswordSuccessfullyChanged = 8,
  PasswordSuccessfullySet = 9,
  AccountLinked = 10,
  LinkedAccountRemoved = 11,
  SubscriptionLinked = 12,
  FamilyMemberInvited = 13,
  SocialAccountRemoved = 14,
  FamilyMemberInvitedPostponed = 15,
}

export enum HttpStatus201 {
  AccountCreateEmailSent = 1,
  ProfileImageSuccessfullyProcessed = 2,
  EmailAddressSuccessfullyCreated = 3,
}

export enum HttpStatus400 {
  RequestInvalid = 1,
  EmailAddressInUse = 2,
  AtLeasOneEmailAddressRequired = 3,
  PasswordSimilarToOldOne = 4,
  EmailAddressNotFound = 5,
  EmailAddressAlreadyVerified = 6,
  EmailAddressNotValid = 7,
  ImageWrongFileFormat = 8,
  PhoneNumberNotValid = 9,
  PhoneNumberNoMobileNumber = 10,
  PhoneNumberNoLandLineNumber = 11,
  PhoneNumberNoNumberFromCountry = 12,
  TermsNotAgreed = 13,

  PasswordToShort = 14,
  EmailDoesNotMatchExistingOne = 15,
  FirstNameDoesNotMatchSubscriber = 16,
  LastNameDoesNotMatchSubscriber = 17,
  EmailDoesNotMatchSubscriber = 18,
  PhoneNumberNotAssignable = 19,
  VerificationTokenIsInvalid = 20,
  UidAlreadyUsedOnSubscription = 21,
  GpNrInvalid = 22,
  NoCountryCode = 23,

  EmailAddressesAreIdentical = 24,
  GpNrNotFound = 25,
  NameNotMatchingGpNr = 26,
  LoginChallengeMissing = 27,
  VerificationTokenAlreadyUsed = 28,
  VerificationTokenExpired = 29,
  NoFirstNameForSubscription = 30,
  NoLastNameForSubscription = 31,
  InvoiceDateMissing = 32,
  InvoiceIdMissing = 33,
  WrongPassword = 34,
  VerificationTokenExpiredEmailResent = 35,
  PhoneNumberMissing = 36,
  NewsPaperDeliveryPersonNotFound = 37,
  UidAlreadyUsedOnDifferentSubscription = 38,
  OwnMessage = 39,
  FirstNameToLong = 40,
  LastNameToLong = 41,
  VerificationTokenExpiredEmailSendFailed = 42,
  SubscriptionSuspensionToLong = 43,
  OrderTypeNotAllowed = 44,
  NoFirstName = 45,
  NoLastName = 46,
  SubscriptionLinkingFailedContactCustomerCenter = 47,
  InvalidCharacterInName = 48,

  SiteKeyDoesNotMatch = 50,
  ReCAPTCHAFailed = 51,

  EmailAddressAlreadyOccupied = 60,
  EmailAddressContainsUmlaut = 61,
  PasswordCriteriaNotFulfilled = 62,

  countryCodeNotAllowed = 70,

  OtpFrontendValidationError = 98,
  OtpError = 99,
  NecessaryTokensAreMissing = 100,

  UserAlreadyInvited = 101,
  NoInvitationTokenProvided = 102,
  TokenWrongOrInvitationNotFound = 103,
  InvitationExpired = 104,
  InvitationAcceptanceError = 105,
  ValidationError = 106,
  InvitationFamilyMemberToYoung = 107,
  UidAlreadyIsOrWasLinkedToASubscription = 108,
  AlreadyRegisteredContactCustomerCenter = 109,

  OneTimeLogonTokenEntryNotFound = 110,
  OneTimeLogonTokenDoesNotMatch = 111,
  OneTimeLogonTokenToOld = 112,

  UnknownException = 120,

  SelfServiceAlreadyProcessed = 121,
  SelfServiceStartDateBeforeMinimumStartDate = 122,
  SelfServiceIncorrectDateInput = 123,
  SelfServiceInputParameterMissing = 124,
  SelfServiceDurationToLong = 125,
  SelfServiceActionOnWrongType = 126,
  SelfServiceDurationSumToLong = 127,
  SelfServiceApprovedStartDateError = 128,
  SelfServiceNoDeliveryForTimeFrame = 129,
  SelfServiceSubscriptionNotYetActive = 130,
  SelfServiceSubscriptionNotActiveAnymore = 131,
  SelfServiceSubscriptionNotActiveSuspensionToLong = 132,
  SelfServiceStartEndOfOrderNotInRange = 133,
  SelfServiceDurationOverlap = 134,
  SelfServiceEntryLocked = 135,
  SelfServiceSubscriptionAlreadyCancelledPausingNotPossible = 136,
  SelfServiceSuspensionDoneByCustomerService = 137,

  LoginForSocialProviderNotAllowed = 141,
  PasswordChangeForSocialProviderNotAllowed = 142,
  EmailChangeForSocialProviderNotAllowed = 143,
  UserDataChangeForSocialProviderNotAllowed = 144,
  PasswordChangeForSocialProviderNotAllowedNoLink = 145,
  LoginForSocialProviderNotAllowedNoLink = 146,

  ItemNotFound = 150,

  RequiredDataNotProvidedByProvider = 151,

  InvalidIban = 160,
  SepaConfirmRequried = 161,

  ShareSubscriptionMainPersonNotAllowed = 170,
}

export enum HttpStatus401 {
  UnauthorizedOnResource = 1,
  WrongEmailOrPassword = 2,
  AccountLocked = 3,
  EmailAlreadyInSystem = 4,
  EmailAlreadyUsedForSocialLogin = 5,
  WrongEmail = 6,
  WrongPassword = 7,
  EmailNotVerified = 8,
  InvalidAuthToken = 9,
}

export enum HttpStatus403 {
  ResourceAccessForbidden = 1,
}

export enum HttpStatus404 {
  ResourceNotFound = 1,
  SubscriptionNotFound = 2,
  AddressNotFound = 3,
  AccountNotFound = 4,
  InvitationNotFound = 5,
  InvoiceNotFound = 6,
  PermissionsNotFound = 7,
  EmailAddressNotFound = 8,
  LoginChallengeNotFound = 9,

  OrderNotFound = 10,
  OrderGroupNotFound = 11,

  SelfServiceEntryNotFound = 12,

  ImmoUserSubscriptionInvalid = 13,
}

export enum HttpStatus409 {
  HydraResourceConflict = 200,
  HydraResourceConflictRegistration = 201,
}

export enum HttpStatus410 {
  HydraLoginChallengeWasUsedChangePassword = 210,
}

export enum HttpStatus429 {
  RateLimitExceeded = 1,
  ToManyEmailsSent = 2,
}

export enum HttpStatus500 {
  Internal = 1,
  EmailNullOrUndefined = 2,
  PhoneNullOrUndefined = 3,
  PasswordNullOrUndefined = 4,
  UnmappedError = 5,
  PictureUploadError = 6,
  LrEmailNotFound = 7,
  CommunicationWitLRFailed = 8,
  UnmappedErrorLogin = 9,

  CommunicationWithA1Failed = 60,
  MaximumNumberOfMailboxesReached = 61,

  PortalServiceUnknownError = 70,
  CommunicationWithPortalServiceFailed = 71,
  PortalServiceNoClueWhatToDoError = 72,

  CommunicationWithSAPFailed = 80,
  CommunicationWithSAPNoRight = 81,

  UnknownErrorContactCustomerService = 90,
}

const contactCustomerService = 'Sollte der Fehler weiter auftreten, wenden Sie sich bitte an das TT-Kundencenter.';

export const httpStatusMap: HttpStatusMapModel = {
  200: {
    // OK
    [HttpStatus200.Ok]: 'OK',
    [HttpStatus200.AccountDeleted]: 'Ihr Account wurde erfolgreich gelöscht.',
    [HttpStatus200.ProfileImageDeleted]: 'Ihr Profilbild wurde erfolgreich gelöscht.',
    [HttpStatus200.VerificationEmailSent]: 'Ein E-Mail zur Verifizierung wurde an {0} gesendet.',
    [HttpStatus200.EmailAddressVerified]: 'Eine E-Mail-Adresse {0} wurde erfolgreich verifiziert.',
    [HttpStatus200.PasswordResetEmailSent]:
      'Eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passwortes wurde an {0} gesendet.',
    [HttpStatus200.AccountVerified]: 'Ihr Account wurde erfolgreich verifiziert.',
    [HttpStatus200.PasswordSuccessfullyChanged]: 'Ihr Passwort wurde erfolgreich geändert.',
    [HttpStatus200.PasswordSuccessfullySet]: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
    [HttpStatus200.AccountLinked]: 'Ihr Account wurde verknüpft.',
    [HttpStatus200.LinkedAccountRemoved]: 'Der verknüpfte Account wurde entfernt.',
    [HttpStatus200.SubscriptionLinked]: 'Ihr Abo wurde erfolgreich hinzugefügt.',
    [HttpStatus200.FamilyMemberInvited]: 'Die Einladung wurde erfolgreich versendet.',
    [HttpStatus200.SocialAccountRemoved]: 'Der Anbieter wurde von Ihrem TT-Account entfernt.',
    [HttpStatus200.FamilyMemberInvitedPostponed]: 'Die Einladung wird demnächst versendet.',
  },
  201: {
    // CREATED
    [HttpStatus201.AccountCreateEmailSent]:
      'Vielen Dank, Sie haben Ihre Registrierung erfolgreich abgeschlossen. Sie erhalten in Kürze ein E-Mail mit einem Bestätigungslink an {0}, um Ihre E-Mail-Adresse zu bestätigen. Sollten Sie in Ihrem Posteingang keine E-Mail von no-reply@tt.com finden, prüfen Sie bitte Ihren SPAM-Ordner.',
    [HttpStatus201.ProfileImageSuccessfullyProcessed]: 'Ihr Profilbild wurde erfolgreich verarbeitet.',
    [HttpStatus201.EmailAddressSuccessfullyCreated]:
      '<p>Sehr geehrte Damen und Herren,</p><br><p>die Mailbox für die E-Mail-Adresse {0} wurde erfolgreich erstellt.</p><p>Richten Sie sich Mithilfe der <a href="https://emailanleitung.tirol.com">emailanleitung.tirol.com</a> Ihr Postfach in Ihrem E-Mail-Programm ein oder verwenden Sie das <a href="https://webmail.tirol.com/">Webmail</a></p><br><p>Sie haben Fragen oder Anregungen? Unter <a href="tel:+43504031500">+43 (0)5 04 03 - 1500</a> oder <a href="mailto:service@tt.com">service@tt.com</a> sind wir gerne für Sie da.</p><br><p>Mit freundlichen Grüßen<br>Ihr TT-Aboservice-Team</p>',
  },
  400: {
    // BAD_REQUEST
    [HttpStatus400.RequestInvalid]: 'Die Anfrage war fehlerhaft.',
    [HttpStatus400.UnknownException]: 'Es ist ein unbekannter Fehler aufgetreten.',
    [HttpStatus400.EmailAddressInUse]: 'Die angegebene E-Mail-Adresse wird bereits verwendet.', //gets appended in registration component
    [HttpStatus400.AtLeasOneEmailAddressRequired]:
      'Ihr Account benötigt mindestens eine E-Mail-Adresse, Sie können diese Adresse nicht löschen.',
    [HttpStatus400.PasswordSimilarToOldOne]:
      'Das neue Passwort konnte nicht übernommen werden, da es einem alten Passwort zu ähnlich ist.',
    [HttpStatus400.EmailAddressNotFound]: 'Die angegebene E-Mail-Adresse wurde nicht gefunden.',
    [HttpStatus400.EmailAddressAlreadyVerified]: 'Die angegebene E-Mail-Adresse ist schon verifiziert.',
    [HttpStatus400.EmailAddressNotValid]: 'Sie haben keine valide E-Mail-Adresse eingegeben.',
    [HttpStatus400.ImageWrongFileFormat]: 'Das gewählte Bild hat ein falsches Format. Erlaubte Formate sind {0}',
    [HttpStatus400.PhoneNumberNotValid]: `Die angegebene Telefonnummer ({0}) ist keine gültige Telefonnummer. ${contactCustomerService}`,
    [HttpStatus400.PhoneNumberNoMobileNumber]: 'Die angegebene Telefonnummer ist keine Mobiltelefonnummer.',
    [HttpStatus400.PhoneNumberNoLandLineNumber]: 'Die angegebene Telefonnummer ist keine Festnetznummer.',
    [HttpStatus400.PhoneNumberNoNumberFromCountry]: 'Die angegebene Telefonnummer ist keine Telefonnummer aus {0}.',
    [HttpStatus400.PasswordToShort]: 'Das Passwort ist zu kurz. Es muss mindestens {0} Zeichen lang sein.',
    [HttpStatus400.EmailDoesNotMatchExistingOne]:
      'Die angegebene E-Mail-Adresse stimmt nicht mit der vorhandenen E-Mail-Adresse überein.',
    [HttpStatus400.FirstNameDoesNotMatchSubscriber]:
      'Der angegebene Vorname ({0}) stimmt nicht mit dem Vornamen des Abos überein.',
    [HttpStatus400.LastNameDoesNotMatchSubscriber]:
      'Der angegebene Nachname ({0}) stimmt nicht mit dem Nachnamen des Abos überein.',
    [HttpStatus400.EmailDoesNotMatchSubscriber]:
      'Die angegebene E-Mail-Adresse ({0}) stimmt nicht mit der E-Mail-Adresse des Abos überein.',
    [HttpStatus400.PhoneNumberNotAssignable]:
      'Wir konnten die angegebene Telefonnummer nicht zuweisen (Typ {0}). Bitte setzen Sie sich mit dem TT-Aboservice in Verbindung.',
    [HttpStatus400.NoCountryCode]: 'Es wurde kein Ländercode angegeben.',
    [HttpStatus400.VerificationTokenIsInvalid]: 'Der übermittelte Verifizierungstoken ist ungültig.',
    [HttpStatus400.UidAlreadyUsedOnSubscription]: 'Ihr TT-Benutzerkonto ist bereits mit dem angegebenen Abo verknüpft.',
    [HttpStatus400.GpNrInvalid]: 'Die angegebene Kundennummer ist ungültig.',
    [HttpStatus400.TermsNotAgreed]: 'Die AGB wurden nicht akzeptiert.',

    [HttpStatus400.EmailAddressesAreIdentical]: 'Beide E-Mail-Adressen sind identisch.',
    [HttpStatus400.GpNrNotFound]: 'Die angegebene Kundennummer konnte nicht gefunden werden.',
    [HttpStatus400.NameNotMatchingGpNr]:
      'Der angegebene Vorname bzw. Nachname stimmt nicht mit den Kundendaten überein.',
    [HttpStatus400.LoginChallengeMissing]:
      'Bei Ihrerm Anmeldungsversuch scheint ein Fehler aufgetreten zu sein. Bitte gehen Sie zu der Seite, auf der Sie angemeldet sein wollen und starten Sie dort die Anmeldung erneut. (missing 400)',
    [HttpStatus400.VerificationTokenAlreadyUsed]:
      'Dieser Bestätigungslink wurde bereits verwendet. Sollten Sie Probleme beim Anmelden haben, wenden Sie sich bitte an service@tt.com.',
    [HttpStatus400.VerificationTokenExpired]: 'Der Verifizierungslink ist abgelaufen.',
    [HttpStatus400.VerificationTokenExpiredEmailSendFailed]:
      'Der Verifizierungslink für Ihr TT-Benutzerkonto (UID: {0}) ist bereits abgelaufen. Beim Versuch, Ihnen einen neuen Verifizierungslink per E-Mail zuzusenden, ist ein Fehler aufgetreten. Bitte wenden Sie sich daher an unser Kundencenter. Fehler Nummer: {1}, Code: {2}',
    [HttpStatus400.SubscriptionSuspensionToLong]:
      'Sie können Ihr Abo momentan nicht verknüpfen, da es unterbrochen ist.',
    [HttpStatus400.OrderTypeNotAllowed]: `Sie haben keine Berechtigung zum Verknüpfen des Abos. Bitte wenden Sie sich an das TT-Kundencenter.`,
    [HttpStatus400.NoFirstNameForSubscription]:
      'Um Ihr Abo erfolgreich zu verknüpfen, müssen Sie Ihren Vornamen angeben.',
    [HttpStatus400.NoLastNameForSubscription]:
      'Um Ihr Abo erfolgreich zu verknüpfen, müssen Sie Ihren Nachnamen angeben.',
    [HttpStatus400.InvoiceDateMissing]: 'Zum Herunterladen der Rechnung wird ein Rechnungsdatum benötigt.',
    [HttpStatus400.InvoiceIdMissing]: 'Für das angegebene Datum konnte keine Rechnung gefunden werden.',
    [HttpStatus400.WrongPassword]: 'Sie haben ein falsches Passwort angegeben.',
    [HttpStatus400.VerificationTokenExpiredEmailResent]:
      'Der Bestätigungslink ist abgelaufen. Es wurde Ihnen ein neuer Link per E-Mail an {0} gesendet.',
    [HttpStatus400.PhoneNumberMissing]: 'Bitte geben Sie eine Telefonnummer an.',
    [HttpStatus400.NewsPaperDeliveryPersonNotFound]:
      'Die Kundennummer des Zeitungsausträgers konnte nicht gefunden werden.',
    [HttpStatus400.UidAlreadyUsedOnDifferentSubscription]:
      'Die angegebene Kundennummer ist bereits mit einem anderem TT-Benutzerkonto verknüpft.',
    [HttpStatus400.OwnMessage]: '{0}',
    [HttpStatus400.FirstNameToLong]: 'Der angegebene Vorname ist zu lang. Der Name darf maximal 35 Zeichen lang sein.',
    [HttpStatus400.LastNameToLong]: 'Der angegebene Nachname ist zu lang. Der Name darf maximal 35 Zeichen lang sein.',
    [HttpStatus400.NoFirstName]: 'Es wurde kein Vorname angegeben.',
    [HttpStatus400.NoLastName]: 'Es wurde kein Nachname angegeben.',
    [HttpStatus400.SubscriptionLinkingFailedContactCustomerCenter]:
      'Ihr Abo kann nicht automatisch verknüpft werden. Bitte kontaktieren Sie das TT-Kundencenter.',
    [HttpStatus400.InvalidCharacterInName]: 'Der eingegebene Name enthält ein nicht unterstütztes Zeichen: "{0}"',
    [HttpStatus400.SelfServiceSubscriptionAlreadyCancelledPausingNotPossible]:
      'Sie können Ihr Abo nicht mehr unterbrechen, da es demnächst ausläuft.',

    // reCAPTCHA error
    [HttpStatus400.SiteKeyDoesNotMatch]: 'Die SiteKeys stimmen nicht überein.',
    [HttpStatus400.ReCAPTCHAFailed]: 'Die Überprüfung des reCAPTCHA ist fehlgeschlagen.',

    [HttpStatus400.EmailAddressAlreadyOccupied]: 'Die angegebene E-Mail-Adresse ist bereits vergeben.',
    [HttpStatus400.EmailAddressContainsUmlaut]:
      'Die angegebene E-Mail-Adresse enthält einen Umlaut. Umlaute werden nicht unterstützt.',
    [HttpStatus400.PasswordCriteriaNotFulfilled]:
      'Das von Ihnen gewählte Passwort entspricht nicht den erforderlichen Kriterien. Es muss mindestens einen Groß-, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',

    [HttpStatus400.countryCodeNotAllowed]:
      'Die angegebene Telefonnummer stammt aus einem Land, das nicht unterstützt wird.',

    [HttpStatus400.OtpFrontendValidationError]:
      'Beim Verarbeiten des Bestätigungscodes ist ein Fehler aufgetreten. Bitte versuchen Sie den Bestätigungscode erneut anzufordern.',
    [HttpStatus400.OtpError]:
      'Beim Verarbeiten des Bestätigungscodes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den TT-Kundenservice.',
    [HttpStatus400.NecessaryTokensAreMissing]: 'Die notwendigen Tokens sind nicht vorhanden. [MISSING: {0}]',
    [HttpStatus400.UserAlreadyInvited]: 'Diese Person wurde bereits eingeladen.',
    [HttpStatus400.NoInvitationTokenProvided]: 'Es wurde kein Token von der Einladung übermittelt.',
    [HttpStatus400.TokenWrongOrInvitationNotFound]:
      'Die Einladung konnte nicht gefunden werden. Dies könnte daran liegen, dass das Token falsch ist.',
    [HttpStatus400.InvitationExpired]: 'Der Link zur Einladung ist abgelaufen.',
    [HttpStatus400.InvitationAcceptanceError]: 'Es ist ein Fehler bei der Bearbeitung der Einladung aufgetreten - {0}',
    [HttpStatus400.ValidationError]: 'Der folgende Fehler ist aufgetreten: {0}',
    [HttpStatus400.InvitationFamilyMemberToYoung]:
      'Das Familienmitglied muss aus rechtlichen Gründen mindestens 14 Jahre alt sein.',
    [HttpStatus400.UidAlreadyIsOrWasLinkedToASubscription]:
      'Sie konnten nicht als TT-Club-Familienmitglied hinzugefügt werden, da Ihr Konto bereits ein verknüpftes Abo hat oder hatte. Bitte melden Sie sich beim Kundencenter, um die Einladung als TT-Club-Familienmitglied anzunehmen.',
    [HttpStatus400.AlreadyRegisteredContactCustomerCenter]:
      'Sie konnten nicht als TT-Club-Familienmitglied hinzugefügt werden, da Ihr Konto bereits existiert. Um das Problem zu lösen wenden Sie sich bitte an unseren TT-Aboservice.',
    [HttpStatus400.OneTimeLogonTokenEntryNotFound]: 'Für den Anmeldeversuch konnten keine Daten gefunden werden.',
    [HttpStatus400.OneTimeLogonTokenDoesNotMatch]: 'Das angegebene Token stimmt nicht mit dem vorhanden Token überein.',
    [HttpStatus400.OneTimeLogonTokenToOld]:
      'Das angegebene Token ist abgelaufen. Bitte starten Sie die Registrierung neu, um ein neues Token zu erhalten.',
    // SelfService
    [HttpStatus400.SelfServiceAlreadyProcessed]:
      'Die gewünschte Abo Aktion kann nicht gelöscht werden, da ihre Gültigkeit schon abgelaufen ist oder Sie bereits gelöscht wurde.',
    [HttpStatus400.SelfServiceStartDateBeforeMinimumStartDate]:
      'Das angestrebte Startdatum {0} liegt vor dem frühesten Startdatum ({1}).',
    [HttpStatus400.SelfServiceIncorrectDateInput]: 'Das angegebene Datum konnte nicht verarbeitet werden.',
    [HttpStatus400.SelfServiceInputParameterMissing]: 'Ein benötigter Eingabeparameter fehlt.',
    [HttpStatus400.SelfServiceDurationToLong]: 'Der Zeitraum für die Aktion ist zu lange gewählt.',
    [HttpStatus400.SelfServiceActionOnWrongType]: 'Die Aktion kann auf dieser Bestellung nicht ausgeführt werden.',
    [HttpStatus400.SelfServiceDurationSumToLong]:
      'Diese Abo-Unterbrechung kann nicht mehr hinzugefügt werden, da sie in Kombination mit den vorhandenen Unterbrechungen zu lange wäre.',
    [HttpStatus400.SelfServiceApprovedStartDateError]:
      'Bitte wählen Sie ein anderes Startdatum für Ihre Unterbrechung.',
    [HttpStatus400.SelfServiceNoDeliveryForTimeFrame]:
      'Es ist keine Unterbrechung möglich, da Ihnen für dieses Abo in dem ausgewählten Zeitraum keine Zeitungen zugestellt werden.',
    [HttpStatus400.SelfServiceSubscriptionNotYetActive]:
      'Sie können Ihr Abo zu diesem Zeitpunkt nicht unterbrechen, da das Abo noch nicht aktiv ist.',
    [HttpStatus400.SelfServiceSubscriptionNotActiveAnymore]:
      'Sie können Ihr Abo zu diesem Zeitpunkt nicht unterbrechen, da das Abo nicht mehr aktiv ist.',
    [HttpStatus400.SelfServiceSubscriptionNotActiveSuspensionToLong]:
      'Sie können Ihr Abo zu diesem Zeitpunkt nicht unterbrechen, da das Abo zum Ende der Unterbrechung nicht mehr aktiv ist.',
    [HttpStatus400.SelfServiceDurationOverlap]:
      'Sie können Ihr Abo zu diesem Zeitpunkt nicht unterbrechen, da schon eine Unterbrechung bereits aktiv ist.',
    [HttpStatus400.SelfServiceEntryLocked]:
      'Die gewünschte Aktion kann zum gegenwärtigen Zeitpunkt nicht durchgeführt werden, da der gewählte Eintrag gerade verarbeitet wird. Bitte versuchen Sie es etwas später erneut.',
    [HttpStatus400.SelfServiceStartEndOfOrderNotInRange]: 'Der Zeitraum ist ungültig.',
    [HttpStatus400.SelfServiceSuspensionDoneByCustomerService]:
      'Die Unterbrechung wurde bereits bearbeitet. Um das Problem zu lösen wenden Sie sich bitte an unseren TT-Aboservice',
    [HttpStatus400.LoginForSocialProviderNotAllowed]:
      'Es besteht bereits ein mit {0} verknüpftes TT-Benutzerkonto für diese E-Mail-Adresse. <a href="{1}">Jetzt mit {2} anmelden</a>.',
    [HttpStatus400.PasswordChangeForSocialProviderNotAllowed]:
      'Dieses TT-Benutzerkonto ist mit {0} verknüpft. Bitte setzen Sie Ihr Passwort bei {1} zurück. <a href="{2}">Zu {3}</a>',
    [HttpStatus400.EmailChangeForSocialProviderNotAllowed]:
      'Dieses TT-Benutzerkonto ist mit {0} verknüpft. Bitte ändern Sie dort ihre E-Mail.',
    [HttpStatus400.UserDataChangeForSocialProviderNotAllowed]:
      'Dieses TT-Benutzerkonto ist mit {0} verknüpft. Bitte ändern Sie dort ihre Daten.',
    [HttpStatus400.ItemNotFound]: 'Das gesuchte Item konnte nicht gefunden werden.',
    [HttpStatus400.PasswordChangeForSocialProviderNotAllowedNoLink]:
      'Dieses TT-Benutzerkonto ist mit {0} verknüpft. Bitte setzen Sie Ihr Passwort bei {1} zurück.',
    [HttpStatus400.LoginForSocialProviderNotAllowedNoLink]:
      'Es besteht bereits ein mit {0} verknüpftes TT-Benutzerkonto für diese E-Mail-Adresse.',
    [HttpStatus400.RequiredDataNotProvidedByProvider]: 'Es wurden nicht alle benötigten Daten von {0} übermittelt.',
    [HttpStatus400.InvalidIban]: 'Die IBAN enthält unzulässige Zeichen',
    [HttpStatus400.ShareSubscriptionMainPersonNotAllowed]: `Es ist ein Fehler aufgetreten, da die einladende Person derzeit nicht berechtigt ist, das Abo mit Ihnen zu teilen. Bei Fragen ist das TT-Kundencenter per E-Mail an <a href="mailto:service@tt.com">service@tt.com</a> oder telefonisch unter <a href="tel:+43504031500">+43 (0)5 04 03 - 1500</a> für Sie erreichbar.`,
    [HttpStatus400.SepaConfirmRequried]: 'Sie müssen bestätigen, dass ihr Abo mittels SEPA-Lastschrift bezahlt wird',
  },
  401: {
    // UNAUTHORIZED
    [HttpStatus401.UnauthorizedOnResource]:
      'Sie sind nicht berechtigt, auf diese Ressource (z.B. Bild oder Seite) zuzugreifen.',
    [HttpStatus401.WrongEmailOrPassword]: 'Sie haben eine falsche E-Mail-Adresse oder ein falsches Passwort angegeben.',
    [HttpStatus401.AccountLocked]: 'Ihr Account ist gesperrt. Bitte kontaktieren Sie den TT-Aboservice.',
    [HttpStatus401.EmailAlreadyInSystem]:
      'Sie können sich mit der angegebenen E-Mail-Adresse nicht anmelden/registrieren, da die Adresse bereits im System vorhanden ist.',
    [HttpStatus401.EmailAlreadyUsedForSocialLogin]:
      'Sie können sich mit der angegebenen E-Mail-Adresse nicht registrieren, da sie schon für einen Social Login verwendet wird.',
    [HttpStatus401.WrongEmail]: 'Die angegebene E-Mail-Adresse konnte nicht gefunden werden.',
    [HttpStatus401.WrongPassword]: 'Sie haben ein falsches Passwort angegeben.',
    [HttpStatus401.EmailNotVerified]: 'Ihre E-Mail-Adresse ist noch nicht verifiziert.',
    [HttpStatus401.InvalidAuthToken]: 'Das Format des Tokens ist ungültig oder er ist abgelaufen.',
  },
  403: {
    // FORBIDDEN
    [HttpStatus403.ResourceAccessForbidden]:
      'Sie sind nicht berechtigt, auf diese Ressource (z.B. Bild oder Seite) zuzugreifen.',
  },
  404: {
    // NOT_FOUND
    [HttpStatus404.ResourceNotFound]: 'Die angeforderte Ressource (z.B. Bild oder Seite) konnte nicht gefunden werden.',
    [HttpStatus404.SubscriptionNotFound]: 'Es konnte anhand dieser Daten kein Abo gefunden werden.',
    [HttpStatus404.AddressNotFound]: 'Es konnten keine Adressdaten für den Kunden/die Kundin gefunden werden.',
    [HttpStatus404.AccountNotFound]: 'Es konnte kein Account gefunden werden.',
    [HttpStatus404.InvitationNotFound]: 'Die Einladung konnte nicht gefunden werden.',
    [HttpStatus404.InvoiceNotFound]: 'Die angeforderte Rechnung konnte nicht gefunden werden.',
    [HttpStatus404.PermissionsNotFound]: 'Es konnte kein Rechteobjekt für den Kunden/die Kundin gefunden werden.',
    [HttpStatus404.EmailAddressNotFound]: 'Die gesuchte E-Mail-Adresse konnte nicht gefunden werden.',
    [HttpStatus404.LoginChallengeNotFound]:
      'Bei Ihrer Anmeldung scheint ein Fehler aufgetreten zu sein. Bitte gehen Sie zu der Seite, auf der Sie angemeldet sein wollen, und starten Sie dort die Anmeldung erneut. (not found 404)',
    [HttpStatus404.OrderNotFound]: 'Der Auftrag mit der Nummer {0} konnte nicht gefunden werden.',
    [HttpStatus404.OrderGroupNotFound]:
      'Der Auftrag mit der Nummer {0} und GruppenNummer {1} konnte nicht gefunden werden.',
    [HttpStatus404.SelfServiceEntryNotFound]: 'Die gewünschte Aktion auf dem Abo konnte nicht gefunden werden.',
    [HttpStatus404.ImmoUserSubscriptionInvalid]:
      'Name oder Kundennummer nicht korrekt. Die Schreibweise Ihres Namens muss exakt mit der Schreibweise auf der TT-Clubkarte übereinstimmen. Bitte überprüfen Sie Ihre Eingabe.',
  },
  409: {
    // CONFLICT
    [HttpStatus409.HydraResourceConflict]:
      'Aufgrund eines Ressourcenkonflikts können wir den laufenden Prozess leider nicht automatisch für Sie abschließen. Bitte gehen Sie gegebenenfalls zu der Seite, auf der Sie angemeldet sein wollen, und starten Sie die Anmeldung dort erneut. <a href="https://www.tt.com">Zur tt.com Startseite</a>, <a href="https://epaper.tt.com">Zum TT-ePaper</a>, <a href="https://benutzerkonto.tt.com">Zur Anmeldung in Ihr TT-Benutzerkonto</a>.',
    [HttpStatus409.HydraResourceConflictRegistration]:
      'Es ist ein Ressourcenkonflikt aufgetreten. Wir können Sie deshalb nicht automatisch anmelden und weiterleiten. Bitte gehen Sie gegebenenfalls direkt zu der Seite auf der Sie sich anmelden wollen.',
  },
  410: {
    // GONE
    [HttpStatus410.HydraLoginChallengeWasUsedChangePassword]:
      'Leider konnten wir Sie nicht automatisch weiterleiten. Bitte gehen Sie zu Ihrem Ausgangspunkt zurück und starten Sie dort die Anmeldung erneut.',
  },
  429: {
    // TOO_MANY_REQUESTS
    [HttpStatus429.RateLimitExceeded]:
      'Sie haben zu viele Anfragen gesendet. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    [HttpStatus429.ToManyEmailsSent]:
      'Das angeforderte E-Mail wurde schon zu oft an Sie versendet. Bitte versuchen Sie es in 24 Stunden erneut.',
  },
  500: {
    // INTERNAL_SERVER_ERROR.
    [HttpStatus500.Internal]: 'Es ist ein unbekannter Fehler aufgetreten.',
    [HttpStatus500.EmailNullOrUndefined]: `Das System konnte die E-Mail-Adresse nicht verarbeiten. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.PhoneNullOrUndefined]: `Das System konnte die Telefonnummer nicht verarbeiten. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.PasswordNullOrUndefined]: `Das System konnte das Passwort nicht verarbeiten. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.UnmappedError]: `Es ist ein unbekannter interner Fehler aufgetreten. Fehlernummer: {0}.{1}. ${contactCustomerService}`,
    [HttpStatus500.UnmappedErrorLogin]: `Es ist ein unbekannter interner Fehler aufgetreten. Bitte gehen Sie zu der Seite, auf der Sie angemeldet sein wollen und starten Sie die Anmeldung dort erneut. Die meisten Fehler lassen sich auf diese Art beheben. Fehlernummer: {0}.{1}. ${contactCustomerService}`,
    [HttpStatus500.PictureUploadError]: 'Das Verarbeiten des Bildes ist fehlgeschlagen.',
    [HttpStatus500.LrEmailNotFound]:
      'Wir konnten Ihr Profil finden, jedoch ist Ihre E-Mail-Adresse nicht korrekt zugeordnet. Wenden Sie sich bitte an das TT-Kundencenter.',
    [HttpStatus500.CommunicationWitLRFailed]: `Die Kommunikation mit der Benutzer-Schnittstelle ist fehlgeschlagen. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.CommunicationWithA1Failed]: `Die Kommunikation mit dem E-Mail-Anbieter ist fehlgeschlagen. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.MaximumNumberOfMailboxesReached]: `Momentan können keine Mailboxen angelegt werden. ${contactCustomerService}`,
    [HttpStatus500.PortalServiceUnknownError]: `Bei der Kommunikation mit der Abo-Verwaltung Schnittstelle ist ein unbekannter Fehler aufgetreten - Fehler: {0}. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.PortalServiceNoClueWhatToDoError]: `Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten - Fehler {0}. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. ${contactCustomerService}`,
    [HttpStatus500.CommunicationWithPortalServiceFailed]: `Die Kommunikation mit der Abo-Verwaltung Schnittstelle ist fehlgeschlagen. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.CommunicationWithSAPFailed]: `Die Kommunikation mit der Abo Schnittstelle ist fehlgeschlagen. Bitte versuchen Sie es erneut. ${contactCustomerService}`,
    [HttpStatus500.CommunicationWithSAPNoRight]: `Bei der Kommunikation mit der Abo Schnittstelle ist ein interner Berechtigungsfehler aufgetreten. Bitte versuchen Sie es später erneut. ${contactCustomerService}`,
    [HttpStatus500.UnknownErrorContactCustomerService]: `Es ist ein unerwarteter Fehler aufgetreten. ${contactCustomerService}`,
  },
};
