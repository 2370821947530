<h3 mat-dialog-title>Allgemeine Geschäftsbedingungen</h3>
<div mat-dialog-content>
  <p><b>Ja, die Gruppe der Tiroler Tageszeitung</b> (Schlüsselverlag J.S. Moser GmbH, MOHO Medienservice GmbH, New Media
    Online
    GmbH) darf mich bis auf jederzeitigen Widerruf unabhängig vom Bestand eines Vertragsverhältnisses unter Verwendung
    der angegebenen Kontaktinformationen (Name, Anschrift, E-Mail-Adresse, Telefonnummer) per Telefon, SMS, Telefax, oder
    eMail zu nachfolgenden Zwecken kontaktieren:
  </p>
  <ul>
    <li>Kundengewinnung und Kundenrückgewinnung in Bezug auf Aboverträge für Print- und Onlinemedien</li>
    <li>Einladung zu Kundenincentives und Übermittlung von Imagewerbung betreffend die Print- und Onlinemedien der
      Gruppe der Tiroler Tageszeitung
    </li>
    <li>Hinweis auf Aktionen, Vorteilsangebote, Gewinnspiele und Sonderthemen der Print- und Onlinemedien sowie
      Social-Media-Kanäle des Verbundes der Gruppe der Tiroler Tageszeitung
    </li>
  </ul>
  <p>
    Hierzu dürfen die Informationen, welche Vertragsbeziehungen zwischen mir und der Gruppe der Tiroler
    Tageszeitung bestanden haben und bestehen, ausgewertet und analysiert werden. Zu diesem Zweck dürfen meine oben
    angeführten Daten von der Gruppe der Tiroler Tageszeitung verarbeitet sowie innerhalb der Unternehmen der Gruppe der
    Tiroler Tageszeitung übermittelt werden.<br>
    <br>
    <b>Hinweis auf das Widerrufsrecht:</b> Diese Zustimmung kann jederzeit gegenüber
    der Schlüsselverlag J.S. Moser GmbH, Brunecker Straße 3, 6020 Innsbruck, <a
    href="mailto:datenschutz@moserholding.com">datenschutz&#64;moserholding.com</a> (als
    Ansprechpartner der Gruppe der Tiroler Tageszeitung) widerrufen werden. Der Widerruf wirkt, sobald die
    Widerrufserklärung zugeht und lässt bereits auf Grund der erteilten Zustimmung erfolgte Verarbeitungen unberührt und
    wirkt sich auf sonstige bestehende Vertragsbeziehungen mit den oben angeführten Gesellschaften in der Gruppe der
    Tiroler Tageszeitung nicht aus.
  </p>
  <p>
    Es gelten die Abo-AGB der TT, TT-ePaper, TT-Paid-Content und tt.com, welche Sie unter <a target="_blank" href="https://www.tt.com/agb">tt.com/agb</a> finden;
    diese erhalten Sie auch unter 05 04 03-1500 kostenlos zugesandt. Informationen zum Datenschutz finden Sie unter
    <a target="_blank" href="https://www.tt.com/datenschutz">tt.com/datenschutz</a>. Das Nutzungsentgelt ist im Voraus fällig. Alle Entgelte verstehen sich inkl. MwSt..
    Preisänderungen vorbehalten. Ich bestelle das TT-ePaper ausschließlich zum privaten Gebrauch. Weder ich noch in
    meinem Haushalt lebende Personen haben in den letzten sechs Monaten ein Abo der Tiroler Tageszeitung bezogen.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="close()">Schließen</button>
</div>
