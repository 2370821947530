import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  scrollToInvalidInput,
  SubmitButttonComponent,
  validateSubscriptionName,
} from '@candyland/angular/tt-com-anmeldung/ui';
import type { Observable, Subscription } from 'rxjs';
import { from, of } from 'rxjs';
import type { HttpStatusModel, HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import { map, switchMap, tap } from 'rxjs/operators';
import type { ParamMap } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { GrecaptchaComponent } from '../grecaptcha/grecaptcha.component';
import { RegistrationService } from '../services/registration.service';
import type {
  AccountCreateResponseModel,
  PhoneNumberCountryFrontend,
} from '@candyland/generic/shared/tt-com-anmeldung/models';
import { AccountCreateModel, countries, CountryCode } from '@candyland/generic/shared/tt-com-anmeldung/models';
import { PhoneNumberService } from '../services/phone-number.service';
import type { MatFormFieldAppearance } from '@angular/material/form-field';
import type { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'candyland-link-sub',
  templateUrl: './link-sub.component.html',
  styleUrls: ['./link-sub.component.scss'],
})
export class LinkSubComponent implements OnInit, OnDestroy {
  @ViewChild(SubmitButttonComponent, { read: ElementRef }) button: ElementRef;
  @ViewChild('recaptcha') recaptcha: GrecaptchaComponent;
  @Input() accountData: AccountCreateModel = null;
  @Input() linkingType: 'customernumber' | 'telephone';
  @Input() linkSubscriptionMode: 'none' | 'family' | 'single';
  @Input() useOTP = false;
  @Output() showRegisterForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() wasSuccess: EventEmitter<HttpStatusModelTyped<AccountCreateResponseModel>> = new EventEmitter<
    HttpStatusModelTyped<AccountCreateResponseModel>
  >();

  appearance: MatFormFieldAppearance = 'outline';

  linkSubForm: UntypedFormGroup = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', { validators: [validateSubscriptionName()] }),
      lastName: new UntypedFormControl('', { validators: [validateSubscriptionName()] }),
      customerNumber: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl(''),
      country: new UntypedFormControl(CountryCode.AT),
    },
    { validators: [] }
  );

  subscriptionActivated = false;
  subscriptionError: HttpStatusModel = null;
  redirectUrl: string = null;
  redirectError: HttpStatusModel = null;
  formLoading = false;

  httpError = null;
  success = null;
  submitted = false;

  countries: Array<PhoneNumberCountryFrontend>;
  countriesObj = countries;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private phoneNumber: PhoneNumberService
  ) {}

  ngOnInit(): void {
    if (this.linkingType === 'customernumber') {
      this.linkSubForm.addControl('customerNumber', new UntypedFormControl('', { validators: [Validators.required] }));
    } else {
      this.linkSubForm.addControl('country', new UntypedFormControl(CountryCode.AT));
      this.linkSubForm.addControl('phoneNumber', new UntypedFormControl('', { validators: [Validators.required] }));
      this.countries = this.phoneNumber.getCountries();
    }

    this.subscriptions.push(
      this.linkSubForm.get('firstName').valueChanges.subscribe(() => {
        this.linkSubForm.get('lastName').updateValueAndValidity({ emitEvent: false });
      })
    );

    this.subscriptions.push(
      this.linkSubForm.get('lastName').valueChanges.subscribe(() => {
        this.linkSubForm.get('firstName').updateValueAndValidity({ emitEvent: false });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onSubmit(): void {
    if (!this.linkSubForm.valid) {
      scrollToInvalidInput();
      return;
    }

    if (this.linkingType === 'customernumber') {
      this.accountData.gpNr = this.linkSubForm.controls.customerNumber.value.trim();
    } else {
      this.accountData.phone = {
        number: this.linkSubForm.controls.phoneNumber.value,
        country: this.linkSubForm.controls.country.value,
        type: null,
      };
      this.accountData.linkSubscription = true;
    }

    this.accountData.firstName = this.linkSubForm.value.firstName.trim();
    this.accountData.lastName = this.linkSubForm.value.lastName.trim();

    of(this.accountData)
      .pipe(
        tap((): void => {
          this.httpError = null;
          this.submitted = true;

          this.linkSubForm.disable();
          this.formLoading = true;
        }),
        switchMap((accountCreateModel: AccountCreateModel) =>
          this.route.queryParamMap.pipe(map((paramMAp: ParamMap) => ({ qp: paramMAp, account: accountCreateModel })))
        ),
        map((x) => this.mapQueryParamsToAccount(x.qp, x.account)),
        switchMap((accountCreateModel: AccountCreateModel) =>
          this.createReCaptchaExecuteObservable(accountCreateModel)
        ),
        switchMap((d) => this.createReCaptchaValidateObservable(d)),
        switchMap((accountCreateModel: AccountCreateModel) =>
          this.registrationService.createAccount(accountCreateModel)
        ),
        tap((d: HttpStatusModelTyped<AccountCreateResponseModel>): void => {
          this.success = d;
        })
      )
      .subscribe({
        next: (success: HttpStatusModelTyped<AccountCreateResponseModel>) => this.successHandler(success),
        error: (error) => this.errorHandler(error),
      });
  }

  doRedirect(): void {
    window.location.href = this.redirectUrl;
  }

  stepBack(): void {
    this.showRegisterForm.emit(true);
  }

  private successHandler(success: HttpStatusModelTyped<AccountCreateResponseModel>): void {
    this.linkSubForm.disable();
    this.formLoading = true;

    if (this.useOTP) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { email: this.accountData.email, otpPart: success.data.otpPart },
        queryParamsHandling: 'merge',
      });

      this.wasSuccess.emit(success);
      return;
    }

    if (success.data.redirect) {
      if (success.data.redirect.status === 200) {
        this.redirectUrl = success.data.redirect.data.url;
      } else {
        this.redirectError = success.data.redirect;
      }
    }
    if (success.data.subscriptionActivation) {
      if (success.data.subscriptionActivation.status === 200) {
        this.subscriptionActivated = true;
      } else {
        this.subscriptionError = success.data.subscriptionActivation;
      }
    }
  }

  private errorHandler(error: HttpErrorResponse): void {
    this.linkSubForm.enable();
    this.formLoading = false;
    this.httpError = error.error;
  }

  private createReCaptchaExecuteObservable(
    data: AccountCreateModel
  ): Observable<{ recaptchaCode: string; account: AccountCreateModel }> {
    return from(this.recaptcha.execute()).pipe(map((code: string) => ({ recaptchaCode: code, account: data })));
  }

  private createReCaptchaValidateObservable(data: {
    recaptchaCode: string;
    account: AccountCreateModel;
  }): Observable<AccountCreateModel> {
    return this.registrationService.validateReCaptcha(data.recaptchaCode).pipe(map(() => data.account));
  }

  private mapQueryParamsToAccount(queryParams: ParamMap, account: AccountCreateModel): AccountCreateModel {
    if (!queryParams || queryParams.keys.length === 0) {
      return account;
    }

    account.queryParams = {};
    queryParams.keys.forEach((k: string) => (account.queryParams[k] = queryParams.get(k)));
    return account;
  }
}
