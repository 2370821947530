import { Injectable } from '@angular/core';
import { CordovaDomService } from './cordova-dom.service';

@Injectable({ providedIn: 'root' })
export class CordovaInAppBrowserService {
  private settings: Map<string, string> = new Map();
  private readonly optionsStr: string;

  constructor(private dom: CordovaDomService) {
    this.settings.set('location', 'no');
    this.settings.set('closebuttoncaption', 'Schließen');
    this.settings.set('closebuttoncolor', '#008253');
    this.settings.set('toolbarcolor', '#C9C9C9');
    this.settings.set('hidenavigationbuttons', 'yes');
    this.settings.set('allowInlineMediaPlayback', 'yes');
    this.settings.set('zoom', 'no');
    this.settings.set('enableViewportScale', 'no');
    this.settings.set('footercolor', '#C9C9C9');
    this.settings.set('footer', 'yes');
    this.optionsStr = this.buildSettings(this.settings);
  }

  public open(
    url: string,
    target: '_system' | '_blank' | '_self',
    settings: Map<string, string> = new Map()
  ): Window | void {
    console.log(`trying to open inAppBrowser with target ${target}`);
    return this.dom.cordova.InAppBrowser.open(url, target, this.mergeSettings(settings));
  }

  private mergeSettings(settings: Map<string, string>): string {
    if (settings.size === 0) {
      return this.optionsStr;
    }
    const res: string[] = [];

    new Map([...this.settings, ...settings]).forEach((value: string, key: string) => res.push(`${key}=${value}`));
    return res.join(',');
  }

  private buildSettings(settings: Map<string, string>): string {
    const res: string[] = [];
    settings.forEach((value: string, key: string) => res.push(`${key}=${value}`));
    return res.join(',');
  }
}
