<div class="wrapper">
  <button
    mat-raised-button
    type="button"
    [disabled]="disabled || clicked"
    (click)="doRedirect()"
    (mousedown)="handleMouseDown()"
    data-cy="google-login"
  >
    <img
      ngSrc="{{ assetsEndpoint }}/assets/google/Standard.svg"
      width="14"
      height="14"
      alt="Google Login"
      class="google-logo"
    />
    <span class="login-text">Anmelden mit Google</span>
    <span *ngIf="clicked" class="loading-overlay">
      <mat-spinner [diameter]="16"></mat-spinner>
    </span>
  </button>
</div>
