import { inject, InjectFlags, InjectionToken, PLATFORM_ID } from '@angular/core';
import type { Observable} from 'rxjs';
import { of } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { ANMELDUNG_LOGIN_URL_BASE, ANMELDUNG_REGISTER_URL_BASE } from './auth.constants';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { IS_CORDOVA } from '@candyland/angular/shared/cordova/model';

export const ANMELDUNG_LOGIN_URL = new InjectionToken<Observable<string>>('Anmeldung.tt.com Login URL', {
  providedIn: 'root',
  factory: () => {
    const isCordova = inject(IS_CORDOVA, InjectFlags.Optional);
    if (isCordova) {
      return of('/cordova/login');
    }

    return getReturnUrl().pipe(
      map((returnUrl) => ANMELDUNG_LOGIN_URL_BASE + encodeURIComponent(returnUrl + 'token=')),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  },
});

export const ANMELDUNG_REGISTER_URL = new InjectionToken<Observable<string>>('Anmeldung.tt.com Register URL', {
  providedIn: 'root',
  factory: () =>
    getReturnUrl().pipe(
      map((returnUrl) => ANMELDUNG_REGISTER_URL_BASE + encodeURIComponent(returnUrl + 'conv=exklusiv')),
      shareReplay({ refCount: false, bufferSize: 1 })
    ),
});

export function getReturnUrl(): Observable<string> {
  const router = inject(Router);
  const location = inject(Location);
  const platformId = inject(PLATFORM_ID);
  if (!isPlatformBrowser(platformId)) {
    return of(null);
  }
  return router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(''),
    map(() => {
      let path = location.path();
      const urlTree = router.parseUrl(path);
      path += Object.keys(urlTree.queryParams).length === 0 ? '?' : '&';
      return path;
    }),
    map((path) => window.location.origin + path),
    shareReplay({ refCount: false, bufferSize: 1 })
  );
}
