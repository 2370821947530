<div class="sections">
  <mat-card appearance="outlined" class="section">
    <a href="https://www.tt.com">
      <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
    </a>
    <h2>Ein Fehler ist aufgetreten:</h2>

    <div class="error-box">
      <p>{{ error.error }}</p>
      <p>{{ error.desc }}</p>
      <p>{{ error.hint }}</p>
    </div>
    <p>
      Bitte <b>versuchen Sie es später erneut</b> oder klicken Sie auf den untenstehenden Button
      <b>“E-Mail an Kundencenter”</b>, um ein E-Mail mit detaillierten Informationen zu dem aufgetretenen Fehler an
      unser Kundencenter zu senden. Auf diese Weise können wir das Problem schnellstmöglich identifizieren und beheben.
    </p>
    <div class="actions">
      <a mat-raised-button color="primary" [href]="getMailTo(error)" class="button">E-Mail an Kundencenter</a>
    </div>

    <div>
      <ul>
        <li><a href="https://www.tt.com">Zur tt.com</a></li>
        <li><a href="https://epaper.tt.com/">TT-ePaper lesen</a></li>
        <li><a href="https://club.tt.com">TT-Club besuchen</a></li>
      </ul>
    </div>
  </mat-card>
</div>
