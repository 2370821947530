import type { ValidatorConstraintInterface } from 'class-validator';
import { ValidatorConstraint } from 'class-validator';
import { isValid, parseISO } from 'date-fns';

@ValidatorConstraint({ name: 'IsDateString', async: false })
// fallback to class validator solution because it has problems with iso timestamps
export class IsDateString implements ValidatorConstraintInterface {
  validate(dateString: string): boolean {
    return isValid(parseISO(dateString));
  }

  defaultMessage(): string {
    return 'Das Format des Datums ist falsch';
  }
}
