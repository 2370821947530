import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DefaultErrorStateMatcher, fieldsAreEqual, scrollToInvalidInput } from '@candyland/angular/tt-com-anmeldung/ui';
import { httpStatusMap, HttpStatus400 } from '@candyland/generic/tt-com-anmeldung/models';
import type { UserForgotPasswordModelResponse } from '@candyland/generic/tt-com-anmeldung/models';
import type { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import type { UserForgotPasswordModelEx, ResetPasswordError } from '../../servicesV2/reset-password.service';
import { ResetPasswordService } from '../../servicesV2/reset-password.service';
import { ChangePasswordPageSettings, CHANGE_PASSWORD_PAGE_SETTINGS } from '../../token';

@Component({
  selector: 'candyland-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss'],
})
export class ChangePasswordPageComponent implements OnInit, OnDestroy {
  formGroup = new UntypedFormGroup({
    otp: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(new RegExp(`^[0-9]{${this.passwordSettings.otpLength}}$`)),
    ]),
    password: new UntypedFormControl('', {
      validators: [Validators.required, Validators.minLength(this.passwordSettings.minPasswordLength)],
    }),
    passwordRepeat: new UntypedFormControl('', {
      validators: [Validators.required, fieldsAreEqual('password')],
    }),
  });

  minPasswordLength;
  showPassword = false;
  showPasswordRepeat = false;
  isOtpVerified = false;
  tokenInvalid = false;

  success: string;
  httpErrorMessage: string;
  errorMessage: string; // A different from httpErrorMessage because error might come because of broken URL
  detailError: DetailError;
  resetData: UserForgotPasswordModelEx;

  matcher = new DefaultErrorStateMatcher();
  redirectUrl: string = null;
  subscriptions: Subscription[] = [];
  queryParams: { email: string; login_challenge: string; otpPart: string };

  constructor(
    @Inject(CHANGE_PASSWORD_PAGE_SETTINGS) private passwordSettings: ChangePasswordPageSettings,
    private service: ResetPasswordService,
    private route: ActivatedRoute
  ) {
    this.minPasswordLength = passwordSettings.minPasswordLength;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.formGroup.get('password').valueChanges.subscribe(() => {
        const passwordRepeatControl = this.formGroup.get('passwordRepeat');
        if (passwordRepeatControl.value !== '') {
          passwordRepeatControl.updateValueAndValidity();
        }
      }),
      this.route.queryParamMap.subscribe((params) => {
        this.queryParams = {
          email: params.get('email'),
          otpPart: params.get('otpPart'),
          login_challenge: params.get('login_challenge'),
        };

        if (!(this.queryParams.email && this.queryParams.otpPart)) {
          this.errorMessage = httpStatusMap[400][HttpStatus400.VerificationTokenIsInvalid];
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  handleSubmit(form: UntypedFormGroup) {
    this.formGroup.disable();
    if (form.invalid) {
      this.formGroup.enable();
      scrollToInvalidInput();
      return;
    }

    this.resetData = {
      password: form.controls.password.value,
      otp: form.controls.otp.value,
      email: this.queryParams.email,
      otpPart: this.queryParams.otpPart,
    };

    if (this.queryParams.login_challenge) {
      this.resetData.login_challenge = this.queryParams.login_challenge;
    }

    of(this.resetData)
      .pipe(
        tap(() => {
          this.formGroup.disable();
          this.httpErrorMessage = null;
          this.detailError = null;
        }),
        switchMap((d) => this.service.changePassword(d))
      )
      .subscribe({
        next: (d) => this.successHandler(d),
        error: (error) => this.errorHandler(error),
      });
  }

  doRedirect() {
    window.location.href = this.redirectUrl;
  }

  private successHandler(d: UserForgotPasswordModelResponse): void {
    this.redirectUrl = d?.data?.redirect?.data?.url ?? null;
    this.success = d.message;
  }

  private errorHandler(error: ResetPasswordError) {
    const message =
      error?.message !== undefined && error?.message !== ''
        ? error.message
        : 'Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es noch einmal.';

    this.httpErrorMessage = message;
    this.detailError = {
      message: message,
      messageNumber: error.number,
      url: window.location.origin + window.location.pathname,
      stack: error.stack,
      email: this.resetData.email,
      httpStatus: error.status,
      requestId: error.requestId,
    };

    this.formGroup.enable();
    // 98 -> HttpStatus400.OtpFrontendValidationError
    // 99 -> HttpStatus400.OtpError
    if (error.number !== 98 && error.number !== 99) {
      this.tokenInvalid = false;
    } else {
      this.tokenInvalid = true;
    }
  }
}

interface DetailError {
  message: string;
  messageNumber: number;
  url: string;
  stack: string;
  email: string;
  httpStatus: number;
  requestId: string;
}
