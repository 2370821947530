import { Component } from '@angular/core';
import type { OnInit } from '@angular/core';
import type { HttpStatusModel } from '@candyland/generic/shared/model-nmo';

@Component({
  selector: 'candyland-regristration-otp',
  templateUrl: './registration-otp.component.html',
  styleUrls: ['./registration-otp.component.scss'],
})
export class RegistrationOtpComponent implements OnInit {
  step = 'email';
  unknownError = false;
  message: string;

  constructor() {}

  ngOnInit(): void {}

  emailRegistered(response: HttpStatusModel): void {
    if (response.status === 201) {
      this.step = 'verification';
      this.message = response.message;
    } else {
      this.unknownError = true;
    }
  }
}
