<mat-card appearance="outlined" class="section reset">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>
  <p>Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.</p>

  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <mat-form-field [appearance]="'outline'">
      <mat-label>E-Mail Adresse</mat-label>
      <input matInput type="email" placeholder="E-Mail Adresse" formControlName="email" />
      <mat-error *ngIf="formGroup.controls.email.hasError('email')"
        >Bitte geben Sie eine gültige E-Mail Adresse an.
      </mat-error>
      <mat-error *ngIf="formGroup.controls.email.hasError('required')"
        >Bitte geben Sie Ihre E-Mail Adresse an.
      </mat-error>
    </mat-form-field>
    <button mat-raised-button type="submit" [color]="'primary'" [disabled]="formGroup.disabled">
      <mat-spinner *ngIf="formGroup.disabled" [diameter]="16"></mat-spinner>
      Absenden
    </button>

    <tt-com-anmeldung-ui-default-message-box *ngIf="httpErrorMessage" [boxType]="2">
      <span [innerHtml]="httpErrorMessage"></span>
    </tt-com-anmeldung-ui-default-message-box>
  </form>
</mat-card>
