<div class="wrapper">
  <button
    mat-raised-button
    type="button"
    [disabled]="disabled || clicked"
    (click)="doRedirect()"
    (mousedown)="handleMouseDown()"
    data-cy="apple-login"
  >
    <img
      ngSrc="{{ assetsEndpoint }}/assets/apple/Standard_Black.svg"
      width="12"
      height="18"
      alt="Apple Login"
      class="apple-logo"
    />
    <span class="login-text">Mit Apple anmelden</span>
    <span *ngIf="clicked" class="loading-overlay">
      <mat-spinner [diameter]="16"></mat-spinner>
    </span>
  </button>
</div>
