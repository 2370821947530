import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, ElementRef, Input } from '@angular/core';
import { MessageBoxType } from '../../enums/message-box-type.enum';
import { getTopOffset } from '../../helper/form-error-scroller';

@Component({
  selector: 'tt-com-anmeldung-ui-default-message-box',
  templateUrl: './default-message-box.component.html',
  styleUrls: ['./default-message-box.component.scss'],
})
export class DefaultMessageBoxComponent implements OnInit, AfterViewInit {
  @Input()
  boxType: MessageBoxType = null;
  @Input()
  showIcon = true;
  @Input()
  message: string = null;
  @Input()
  scrollOnAppear = true;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.scrollOnAppear && typeof window !== 'undefined' && typeof window.scroll === 'function') {
      window.scroll({
        top: getTopOffset(this.elRef.nativeElement),
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
