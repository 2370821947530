<div
  class="message-container"
  data-cy="login-message-container"
  [class.success]="boxType === 0"
  [class.warn]="boxType === 1"
  [class.error]="boxType === 2"
  [class.info]="boxType === 3"
>
  <div class="content">
    <mat-icon *ngIf="showIcon && boxType === 1" data-cy="login-message-warning-icon">warning</mat-icon>
    <mat-icon *ngIf="showIcon && boxType === 2" data-cy="login-message-error-icon">error</mat-icon>
    <span data-cy="login-message-text"><ng-content></ng-content></span>
  </div>
</div>
<tt-com-anmeldung-ui-default-message-expander
  *ngIf="message !== null"
  [message]="message"
></tt-com-anmeldung-ui-default-message-expander>
