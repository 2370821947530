<mat-card appearance="outlined" class="section verify">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>
  <form [formGroup]="form">
    <h3>E-Mail-Adresse verifizieren</h3>
    <ng-container *ngIf="!success">
      <div class="info-text">
        <p>
          Vielen Dank. Bitte geben Sie unten den Bestätigungscode ein, den wir an {{ email ? email : 'Sie' }} geschickt
          haben, um Ihre Registrierung abzuschließen.
        </p>
      </div>
      <mat-form-field [appearance]="'outline'">
        <mat-label>Bestätigungscode</mat-label>
        <input
          matInput
          formControlName="otp"
          autocomplete="noautoa"
          type="text"
          name="otp"
          placeholder="Bestätigungscode"
          required
        />
        <mat-error *ngIf="form.controls.otp.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        <mat-error *ngIf="form.controls.otp.hasError('pattern')">Ungültiger Bestätigungscode</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="form.disabled || isLoading">
        <mat-spinner *ngIf="form.disabled && !success" [diameter]="16"></mat-spinner>
        <span> Registrierung abschließen </span>
      </button>

      <tt-com-anmeldung-ui-default-message-box class="message-box-error" *ngIf="errorMessage" [boxType]="2">
        {{ errorMessage }}
      </tt-com-anmeldung-ui-default-message-box>

      <tt-com-anmeldung-ui-default-message-box
        class="message-box-subscription-error"
        *ngIf="subscriptionError"
        [boxType]="1"
      >
        <h4>Bei der Verknüpfung Ihres Abos mit dem TT-Benutzerkonto ist ein Fehler aufgetreten.</h4>
        <p>{{ subscriptionError.message }}</p>
        <p>In Ihrem Profil können Sie zu einem späteren Zeitpunkt erneut versuchen, die Verknüpfung vorzunehmen.</p>
        <p>
          Sollte der Fehler erneut auftreten kontaktieren Sie bitte das TT-Aboservice unter
          <a href="tel:+43504031500">+43(0)50403-1500</a> oder <a href="mailto:service@tt.com">service&#64;tt.com</a>.
        </p>
      </tt-com-anmeldung-ui-default-message-box>

      <tt-com-anmeldung-ui-default-message-box class="message-box-redirect-error" *ngIf="redirectError" [boxType]="1">
        {{ redirectError.message }}
      </tt-com-anmeldung-ui-default-message-box>
    </ng-container>

    <ng-container *ngIf="success">
      <div class="wrapper-check">
        <svg class="animated-check" viewBox="0 0 24 24">
          <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
        </svg>
      </div>
      <tt-com-anmeldung-ui-default-message-box
        class="message-box-success"
        [boxType]="0"
        [scrollOnAppear]="!showVerificationWarning"
      >
        <div>
          <p>
            Sie haben sich erfolgreich
            {{ email ? 'mit der E-Mail-Adresse ' + email + ' registriert.' : 'registriert.' }}
          </p>
        </div>
        <div *ngIf="redirectUrl !== null">
          <p>
            Durch Klick auf “Weiter” gelangen Sie zur Ursprungsseite, von der aus Sie den Registrierungsprozess
            gestartet haben.
          </p>
          <button mat-raised-button (click)="doRedirect()">Weiter</button>
        </div>
        <div *ngIf="redirectUrl === null">
          <p>Folgende Möglichkeiten haben Sie nun mit Ihrem TT-Benutzerkonto:</p>
          <ul>
            <ng-container *ngIf="subscriptionActivated; else noSubscriptionActivated">
              <li><a href="https://www.tt.com/plus">TT-Plus-Artikel lesen</a></li>
              <li><a href="https://epaper.tt.com/">TT-ePaper lesen</a></li>
              <li><a href="https://club.tt.com">TT-Club Website besuchen</a></li>
              <li><a href="https://www.tt.com">tt.com-Startseite besuchen</a></li>
            </ng-container>
            <ng-template #noSubscriptionActivated>
              <li><a href="https://www.tt.com/plus">TT-Plus-Artikel lesen</a></li>
              <li><a href="https://www.tt.com">tt.com-Startseite besuchen</a></li>
            </ng-template>
          </ul>
        </div>
      </tt-com-anmeldung-ui-default-message-box>
    </ng-container>
  </form>
</mat-card>
