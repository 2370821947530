import type { OnInit, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import type { Params } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { SitedataService } from '../services/sitedata.service';
import type { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { scrollToInvalidInput } from '@candyland/angular/tt-com-anmeldung/ui';
import type {
  PhoneNumberCountry,
  UserAccountProfileModel,
  PhoneNumber,
} from '@candyland/generic/shared/tt-com-anmeldung/models';
import { CountryCode, countries } from '@candyland/generic/shared/tt-com-anmeldung/models';
import { PhoneNumberService } from '../services/phone-number.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { TermsComponent } from '@candyland/angular/shared/tt-com-anmeldung/feature';
import type { InvitationAcceptRequestModel } from '@candyland/generic/tt-com-anmeldung/models';
import type { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import type { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'candyland-family-member-acceptance',
  templateUrl: './family-member-acceptance.component.html',
  styleUrls: ['./family-member-acceptance.component.scss'],
})
export class FamilyMemberAcceptanceComponent implements OnInit, OnDestroy {
  otpStep = false;

  appearance: MatFormFieldAppearance = 'outline';
  genders = [
    { value: 'male', viewValue: 'Herr' },
    { value: 'female', viewValue: 'Frau' },
  ];
  httpError = null;
  success = null;
  minPasswordLength = 10;

  hasAnAccount = true;
  uid = null;
  form = new UntypedFormGroup(
    {
      gender: new UntypedFormControl(''),
      firstname: new UntypedFormControl('', { validators: [Validators.required] }),
      lastname: new UntypedFormControl('', { validators: [Validators.required] }),
      email: new UntypedFormControl('', { validators: [Validators.email] }),
      dateOfBirth: new UntypedFormControl('', { validators: [] }),
      phone: new UntypedFormControl('', { validators: [Validators.pattern(/^\+?\d+$/)] }),
      country: new UntypedFormControl(CountryCode.AT, { validators: [Validators.required] }),
      termsAgreed: new UntypedFormControl(false, { validators: [Validators.required] }),
    },
    { validators: [] }
  );

  acceptInvitationSub = null;
  subscriptions: Subscription[] = [];

  countries: Array<PhoneNumberCountry>;
  countriesObj = countries;

  minDate = new Date(1900, 0, 1);
  maxDate = new Date();

  invitationError: boolean;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: SitedataService,
    private phoneNumber: PhoneNumberService,
    public dialog: MatDialog,
    private oAuthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.countries = this.phoneNumber.getCountries();
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        this.uid = params.uid;
        this.getInvitedUser(params.uid);
      })
    );
  }

  getInvitedUser(uid: string): void {
    this.subscriptions.push(
      this.dataService.getFamilyInvitation(uid).subscribe({
        next: (resp) => {
          this.isLoading = false;
          const data = resp?.data;
          this.form.get('gender').setValue(data.gender);
          this.form.get('firstname').setValue(data.firstName);
          this.form.get('lastname').setValue(data.lastName);
          this.form.get('email').setValue(data.email);
          this.hasAnAccount = data.hasAnAccount;

          // If user has an account, we can apply all the data we have
          if (this.hasAnAccount) {
            this.subscriptions.push(
              this.dataService.getFullProfile().subscribe((userData: UserAccountProfileModel) => {
                this.form.get('dateOfBirth').setValue(userData.subscription.dateOfBirth);

                const phone: PhoneNumber = this.phoneNumber.getPhoneData(
                  userData.subscription.phone.mobile || userData.subscription.phone.landLine
                );

                if (phone) {
                  this.form.get('country').setValue(phone.country);
                  this.form.get('phone').setValue(phone.number);
                }
              })
            );
          } else {
            this.form.addControl(
              'password',
              new UntypedFormControl('', {
                validators: [Validators.minLength(this.minPasswordLength), Validators.required],
              })
            );
            this.form.addControl(
              'passwordRepeat',
              new UntypedFormControl('', {
                validators: [
                  Validators.minLength(this.minPasswordLength),
                  Validators.required,
                  this.passwordRepeatValidator.bind(this),
                ],
              })
            );
          }
        },
        error: () => {
          this.invitationError = true;
          this.isLoading = false;
        },
      })
    );
  }

  onSubmit(): void {
    if (!this.form.valid) {
      scrollToInvalidInput();
      return;
    }

    if (!this.form.controls.termsAgreed.value) {
      this.httpError = { message: 'Bitte akzeptieren Sie die AGBs.' };
      return;
    }

    if (this.acceptInvitationSub) {
      this.acceptInvitationSub.unsubscribe();
    }

    const formData: InvitationAcceptRequestModel = {
      gender: this.form.get('gender').value,
      firstName: this.form.get('firstname').value,
      lastName: this.form.get('lastname').value,
      email: this.form.get('email').value,
      birthday: this.form.get('dateOfBirth').value ? new Date(this.form.get('dateOfBirth').value).toISOString() : null,
      phone: this.phoneNumber.buildFormData(this.form.get('country').value, this.form.get('phone').value),
      termsAgreed: this.form.get('termsAgreed').value,
    };

    if (!this.hasAnAccount) {
      formData.password = this.form.get('password').value;
    }

    this.acceptInvitationSub = of(formData)
      .pipe(
        tap(() => {
          this.form.disable();
          this.clearMessages();
        }),
        switchMap((d) => this.dataService.acceptFamilyInvitation(d, this.uid)),
        tap((s: { data: UserAccountProfileModel }) => this.successHandler(s)),
        catchError((e) => this.errorHandler(e))
      )
      .subscribe();
  }

  openTermsDialog() {
    this.dialog.open(TermsComponent, {});
  }

  denyAcceptance(): void {
    this.redirectToLogin();
  }

  passwordRepeatValidator() {
    if (!this.form?.controls.password || !this.form.controls.passwordRepeat) {
      return;
    }

    const password = this.form.controls.password.value;
    const passwordRepeat = this.form.controls.passwordRepeat.value;
    return password === passwordRepeat ? null : { notSame: true };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.acceptInvitationSub) {
      this.acceptInvitationSub.unsubscribe();
    }
  }

  private successHandler(success: { data: UserAccountProfileModel }) {
    this.form.enable();
    this.success = success;

    if (success.data) {
      //only verify email by OTP if user does not already exist
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { email: this.form.get('email').value, otpPart: success.data.otpPart },
        queryParamsHandling: 'merge',
      });

      this.otpStep = true;
    } else {
      setTimeout(() => {
        this.redirectToLogin();
      }, 3000);
    }
  }

  private errorHandler(error: HttpErrorResponse) {
    console.error(error);
    this.form.enable();
    this.httpError = error.error;
    return of(error);
  }

  private clearMessages() {
    this.httpError = null;
    this.success = null;
  }

  private redirectToLogin() {
    const state = {
      path: '/',
    };

    this.oAuthService.initCodeFlow(JSON.stringify(state));
  }
}
