import { AppEnum } from '../enums';

export interface AppModel {
  appStoreConfig: AppStoreModel;
}

export interface AppStoreModel {
  ios: AppStorePlatformModel;
  android: AppStorePlatformModel;
}

export interface AppStorePlatformModel {
  storeId: string;
}

export const appData: Record<AppEnum, AppModel> = {
  [AppEnum.CLUB]: {
    appStoreConfig: {
      ios: {
        storeId: 'id588326254',
      },
      android: {
        storeId: 'com.tt.club',
      },
    },
  },
  [AppEnum.TT]: {
    appStoreConfig: {
      ios: {
        storeId: 'id377850185',
      },
      android: {
        storeId: 'com.tt.tt.com',
      },
    },
  },
};
