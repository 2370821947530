import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { CordovaInAppBrowserService, CordovaService } from '@candyland/angular/shared/cordova/util';
import {
  CORDOVA_EXTERNAL_APP_DOMAIN,
  CORDOVA_INTERNAL_ANDROID_APP_DOMAIN,
  CORDOVA_INTERNAL_IOS_APP_DOMAIN,
} from '@candyland/angular/shared/cordova/model';

@Injectable({
  providedIn: 'root',
})
export class CordovaLoginService {
  private _browserRef = null;
  constructor(
    private inAppBrowser: CordovaInAppBrowserService,
    private cordovaService: CordovaService,
    private _ngZone: NgZone,
    @Optional() @Inject(CORDOVA_EXTERNAL_APP_DOMAIN) private externalAppDomain?: string,
    @Optional() @Inject(CORDOVA_INTERNAL_ANDROID_APP_DOMAIN) private internalAndroidAppDomain?: string,
    @Optional() @Inject(CORDOVA_INTERNAL_IOS_APP_DOMAIN) private internalIosAppDomain?: string
  ) {}

  public openInAppBrowser(url: string, target: '_system' | '_blank' | '_self' = '_blank'): void {
    this._ngZone.runOutsideAngular((): void => {
      const settings: Map<string, string> = new Map([]);
      let hide = false;
      if (url.indexOf('/oauth2/sessions/logout') !== -1) {
        settings.set('hidden', 'yes');
        hide = true;
      }
      this._browserRef = this.inAppBrowser.open(url, target, settings);
      this._browserRef.addEventListener('loadstart', (event: { url: string }) => this.processCordovaUrl(event.url));
      if (hide) {
        // so we show it if there was some sort of logout error,
        // but normally we close it on android before the user sees the protocol error.
        this._browserRef.addEventListener('loadstop', () =>
          setTimeout((): void => {
            if (this._browserRef !== null) {
              this._browserRef.show();
            }
          }, 100)
        );
      }
    });
  }

  private processCordovaUrl(url: string): void {
    if (!this.externalAppDomain || !this.internalIosAppDomain || !this.internalAndroidAppDomain) {
      console.error(
        `appDomain not set! externalAppDomain: ${this.externalAppDomain}, internalIosAppDomain: ${this.internalIosAppDomain}, internalAndroidAppDomain: ${this.internalAndroidAppDomain}`
      );
    }
    // Android does add a http:// in front of the scheme...
    url = url
      .replace(`http://${this.externalAppDomain}`, this.externalAppDomain)
      .replace(`https://${this.externalAppDomain}`, this.externalAppDomain)
      .replace(`http://${this.internalIosAppDomain}`, this.internalIosAppDomain)
      .replace(`https://${this.internalIosAppDomain}`, this.internalIosAppDomain)
      .replace(`http://${this.internalAndroidAppDomain}`, this.internalAndroidAppDomain)
      .replace(`https://${this.internalAndroidAppDomain}`, this.internalAndroidAppDomain);
    console.log('processCordovaUrl - url:', url);
    if (
      url.startsWith(this.externalAppDomain) ||
      url.startsWith(this.internalIosAppDomain) ||
      url.startsWith(this.internalAndroidAppDomain)
    ) {
      this.cordovaService.openUrl(url);
      this._browserRef.close();
      this._browserRef = null;
    }
  }
}
