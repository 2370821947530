import { EmailOtpVerificationStepComponent } from './components/email-otp-verification-step/email-otp-verification-step.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SimpleFooterComponent } from './components/simple-footer/simple-footer.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SubmitButttonComponent } from './components/submit-buttton/submit-buttton.component';
import { DefaultMessageBoxComponent } from './components/default-message-box/default-message-box.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DefaultMessageExpanderComponent } from './components/default-message-box/default-message-expander/default-message-expander.component';
import { GenericRedirectBlockComponent } from './components/generic-redirect-block/generic-redirect-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SimpleHeaderComponent,
    SimpleFooterComponent,
    DefaultMessageBoxComponent,
    DefaultMessageExpanderComponent,
    NotFoundComponent,
    SubmitButttonComponent,
    GenericRedirectBlockComponent,
    EmailOtpVerificationStepComponent,
  ],
  exports: [
    SimpleHeaderComponent,
    SimpleFooterComponent,
    DefaultMessageBoxComponent,
    SubmitButttonComponent,
    NotFoundComponent,
    GenericRedirectBlockComponent,
    EmailOtpVerificationStepComponent,
  ],
})
export class AngularTtComAnmeldungUiModule {}
