<button
  mat-raised-button
  type="submit"
  [color]="'primary'"
  [disabled]="form.disabled || formDisabled || buttonDisabled"
  data-cy="login-submit"
>
  <mat-spinner *ngIf="!buttonDisabled && (form.disabled || formDisabled) && !success" [diameter]="16"></mat-spinner>
  <mat-icon *ngIf="success">check</mat-icon>
  <span>
    {{ buttonText }}
  </span>
</button>
