<form [formGroup]="formGroup" (ngSubmit)="handleSubmit(formGroup)">
  <mat-form-field [appearance]="appearance">
    <mat-label>E-Mail</mat-label>
    <input
      matInput
      type="email"
      formControlName="email"
      autocomplete="email"
      placeholder="E-Mail"
      data-cy="login-username"
      required
    />
    <mat-error *ngIf="formGroup.controls.email.invalid">Bitte geben Sie eine gültige E-Mail Adresse an.</mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="appearance">
    <mat-label>Passwort</mat-label>
    <input
      matInput
      [type]="!showPassword ? 'password' : 'text'"
      formControlName="password"
      autocomplete="current-password"
      placeholder="Passwort"
      data-cy="login-password"
      required
    />
    <button
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="showPassword"
      type="button"
    >
      <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="formGroup.controls.password.invalid">Bitte geben Sie Ihr Passwort an.</mat-error>
  </mat-form-field>

  <div class="remember-container">
    <tt-com-anmeldung-ui-submit-buttton [form]="formGroup" [buttonText]="'Anmelden'">
    </tt-com-anmeldung-ui-submit-buttton>

    <a
      class="reset-password-link"
      *ngIf="resetPasswordUrlOrPath"
      [routerLink]="resetPasswordUrlOrPath"
      queryParamsHandling="merge"
      data-cy="reset-password"
    >
      Passwort zurücksetzen
    </a>
  </div>

  <tt-com-anmeldung-ui-default-message-box [showIcon]="true" [boxType]="2" *ngIf="errorText">
    <span [innerHTML]="errorText"></span>
  </tt-com-anmeldung-ui-default-message-box>
</form>
