import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import type { OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import type {
  AccountOTPVerificationResponseModel,
  AccountOTPVerificationRequestModel,
} from '@candyland/generic/tt-com-anmeldung/models';
import type { HttpStatusModel, HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import { EmailOtpVerificationStepService } from './email-otp-verification-step.service';

@Component({
  selector: 'tt-com-anmeldung-ui-email-otp-verification',
  templateUrl: './email-otp-verification-step.component.html',
  styleUrls: ['./email-otp-verification-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EmailOtpVerificationStepComponent implements OnInit {
  email: string;
  isLoading = false;

  form = new UntypedFormGroup({
    otp: new UntypedFormControl('', {
      validators: [Validators.required, Validators.pattern(/^\d{6}$/)],
    }),
  });

  success = false;
  errorMessage: string = null;
  redirectUrl: string = null;
  redirectError: HttpStatusModel = null;
  showVerificationWarning = false;

  subscriptionActivated = false;
  subscriptionError: HttpStatusModel = null;

  private login_challenge: string;
  private otpPart: string;

  constructor(
    private otpService: EmailOtpVerificationStepService,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.login_challenge = this.activatedRoute.snapshot.queryParamMap.get('login_challenge');
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.otpPart = this.activatedRoute.snapshot.queryParamMap.get('otpPart');
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.form.disable();

    const body: AccountOTPVerificationRequestModel = {
      email: this.email,
      otpPart: this.otpPart,
      otp: this.form.controls['otp'].value,
      login_challenge: this.login_challenge,
    };

    this.otpService.verifyAccountWithOTP(body).subscribe((response) => {
      const { loading, data, error } = response;
      this.isLoading = loading;
      if (loading) return;

      if (error) {
        this.form.enable();
        this.errorMessage =
          error.error.number === 99
            ? 'Bestätigungscode ungültig: Bitte überprüfen Sie den eingegebenen Code.'
            : error.error.message;
        this.ref.markForCheck();
        return;
      }

      this.errorMessage = null;
      this.success = true;
      this.successHandler(data);
    });
  }

  doRedirect(): void {
    window.location.href = this.redirectUrl;
  }

  private successHandler(success: HttpStatusModelTyped<AccountOTPVerificationResponseModel>): void {
    if (success.data.redirect) {
      if (success.data.redirect.status === 200) {
        this.redirectUrl = success.data.redirect.data.url;
        if (this.activatedRoute.snapshot.queryParamMap.get('verification_warning') === 'true') {
          this.showVerificationWarning = true;
        }
      } else {
        this.redirectError = success.data.redirect;
      }
    }

    if (success.data.subscriptionActivation) {
      if (success.data.subscriptionActivation.status === 200) {
        this.subscriptionActivated = true;
      } else {
        this.subscriptionError = success.data.subscriptionActivation;
      }
    }

    this.ref.markForCheck();
  }
}
