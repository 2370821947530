import type { OnDestroy } from '@angular/core';
import { EventEmitter, Inject, Injectable, Injector, NgZone, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import type { Subscription } from 'rxjs';
import type { Request } from 'express';
import { REQUEST } from '@candyland/generic/shared/util-nmo';

@Injectable({
  providedIn: 'root',
})
export class WindowService implements OnDestroy {
  public data: Record<string, unknown> = {};
  public onScroll: EventEmitter<ScrollPosition> = new EventEmitter<ScrollPosition>();
  public onResize: EventEmitter<null> = new EventEmitter<null>();
  public isBrowser = false;
  public isDesktop = false;
  public isWebView = false;
  public location: Record<string, string> = {};
  private url: string;
  private query: Record<string, unknown>;
  private scrollDebounce: NodeJS.Timeout;
  private resizeDebounce: NodeJS.Timeout;
  private resizeDebounceMs = 300;
  private scrollDebounceMs = 10;
  private request: Request = null;
  private subscriptions: Subscription[] = [];

  constructor(
    @Optional() @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private zone: NgZone,
    private injector: Injector
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      this.request = this.injector.get(REQUEST);
    }

    this.init(platformId);
    this.subscriptions.push(
      this.router.events.subscribe((val): void => {
        if (val instanceof NavigationEnd) {
          this.url = this.router.routerState.snapshot.url;
          this.query = this.router.routerState.root.snapshot.queryParams;
        }
      })
    );

    this.location = {
      get href(): string {
        return this.isBrowser ? window.location.href : `${this.request?.protocol}//${this.request?.originalUrl}`;
      },
      set href(url: string) {
        if (this.isBrowser) {
          window.location.href = url;
        } else {
          this.router.navigate([url]);
        }
      },
      get pathname() {
        return this.isBrowser ? window.location.pathname : this.url;
      },
      get search() {
        if (this.isBrowser) {
          return window.location.search || '';
        } else if (this._query) {
          let queryStr = '';
          Object.keys(this._query).forEach((key: string): void => {
            queryStr += `${key}=${this._query[key]}&`;
          });
          return '?' + queryStr.replace(/\&$/g, '');
        }
        return '?';
      },
    };
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public init(platformId: Object): void {
    this.isBrowser = isPlatformBrowser(platformId);
    this.checkIsDesktop();
    this.checkIsWebview();

    if (this.isBrowser) {
      this.zone.runOutsideAngular((): void => {
        this.addScrollEventListener();
        this.addResizeEventListener();
      });
    }
  }

  public set(values: Record<string, unknown>): void {
    Object.keys(values).forEach((prop: string): void => {
      if (this.isBrowser) {
        window[prop] = values[prop];
      } else {
        this.data[prop] = values[prop];
      }
    });
  }

  public open(url: string, windowName = '', windowFeatures: string = null): Window | null {
    if (this.isBrowser) {
      return window.open(url, windowName, windowFeatures);
    }
    return null;
  }

  public getScrollPosition(): ScrollPosition {
    if (!this.isBrowser) {
      return { x: 0, y: 0 };
    }
    return {
      x: window.scrollX || document.documentElement.scrollLeft,
      y: window.scrollY || document.documentElement.scrollTop,
    };
  }

  public addEventListener(event: string, callback: (ev: Event) => void, options: AddEventListenerOptions = null): void {
    if (!this.isBrowser) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      window.addEventListener(
        event,
        (ev: Event) => {
          callback(ev);
        },
        options
      );
    });
  }

  public scrollTo(x = 0, y = 0, behavior?: ScrollBehavior): void {
    if (!this.isBrowser) {
      return;
    }
    if (!behavior) {
      window.scrollTo(x, y);
    } else {
      window.scrollTo({
        left: x,
        top: y,
        behavior: behavior,
      });
    }
  }

  public scrollBy(x = 0, y = 0, behavior?: ScrollBehavior): void {
    if (!this.isBrowser) {
      return;
    }

    const start = Number(new Date());
    const from_y = window.scrollY;
    const to_y = from_y + y;
    const from_x = window.pageXOffset;
    const to_x = from_x + x;
    const duration = 350;

    if (from_y === to_y && from_x === to_x) {
      return;
    }

    const animate: () => void = (): void => {
      const now = Number(new Date());
      let eased = 1;
      let time = 1;
      if (behavior === 'smooth') {
        time = Math.min(1, (now - start) / duration);
        eased = 0.5 * (1 - Math.cos(Math.PI * time));
      }

      const goal_y = eased * (to_y - from_y) + from_y;
      const goal_x = eased * (to_x - from_x) + from_x;
      window.scrollTo(goal_x, goal_y);

      this.zone.runOutsideAngular(() => {
        if (time < 1) {
          setTimeout(() => animate(), 0);
        }
      });
    };

    animate();
  }

  public scroll(top: number, left: number, behavior: ScrollBehavior = null): void {
    if (!this.isBrowser) {
      return;
    }
    if (behavior === null) {
      window.scroll(left, top);
    } else {
      window.scroll({ left: left, top: top, behavior: behavior });
    }
  }

  public requestAnimationFrame(step: () => void): void {
    if (!this.isBrowser) {
      return;
    }
    this.zone.runOutsideAngular((): void => {
      window.requestAnimationFrame((): void => step());
    });
  }

  public getWindowWidth(): number {
    if (!this.isBrowser) {
      return 0;
    }
    const d: Document = document;
    const e: HTMLElement = d.documentElement;
    const g: HTMLBodyElement = d.getElementsByTagName('body')[0];
    return window.innerWidth || e.clientWidth || g.clientWidth;
  }

  public checkIsDesktop(): boolean {
    if (this.isBrowser) {
      this.isDesktop = this.getWindowWidth() >= 1024;
    } else {
      // the return value is a string!
      if (this.request.get('cloudfront-is-desktop-viewer') === 'true') {
        this.isDesktop = true;
      } else if (this.request.get('cloudfront-is-mobile-viewer') === 'true') {
        this.isDesktop = false;
      } else if (this.request.get('cloudfront-is-tablet-viewer') === 'true') {
        this.isDesktop = false;
      } else {
        const userAgent = this.request.get('user-agent');
        this.isDesktop = userAgent
          ? // eslint-disable-next-line
            !/mobile|ip(hone|od|ad)|android|blackBerry|iemobile|kindle|netfront|silk-accelerated|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/.test(
              userAgent.toLowerCase()
            )
          : false;
      }
    }

    return this.isDesktop;
  }

  private addScrollEventListener(): void {
    window.addEventListener(
      'scroll',
      (): void => {
        if (this.scrollDebounce) {
          clearTimeout(this.scrollDebounce);
        }

        this.scrollDebounce = setTimeout(() => {
          this.onScroll.emit(this.getScrollPosition());
        }, this.scrollDebounceMs);
      },
      { passive: true }
    );
  }

  private addResizeEventListener(): void {
    window.addEventListener('resize', (): void => {
      if (this.resizeDebounce) {
        clearTimeout(this.resizeDebounce);
      }

      this.resizeDebounce = setTimeout((): void => {
        this.zone.run((): void => this.onResize.emit(null));
      }, this.resizeDebounceMs);
    });
  }

  // Check if site is used in a webview
  private checkIsWebview(): void {
    if (this.isBrowser) {
      const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
      const isWebView: boolean = urlSearchParams.has('isWebView') && urlSearchParams.get('isWebView') === 'true';

      if (isWebView) {
        this.isWebView = true;

        // Apply theme to webview
        const webViewTheme: string = urlSearchParams.has('webViewTheme') && urlSearchParams.get('webViewTheme');
        if (webViewTheme === 'light') {
          document.body.style.backgroundColor = '#FFFFFF';
        } else if (webViewTheme === 'dark') {
          document.body.style.backgroundColor = '#1E1F28';
        }

        // Find the existing viewport meta tag to change it. Possible fix for IOS height detection
        const viewportMeta: HTMLMetaElement = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
          viewportMeta.content = 'width=device-width, initial-scale=1, shrink-to-fit=yes';
        }
      }
    } else {
      this.isWebView = this.request.originalUrl?.includes('isWebView=true');
    }
  }
}

interface ScrollPosition {
  x: number;
  y: number;
}
