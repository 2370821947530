import { InjectionToken } from '@angular/core';

export const TT_OLD_API_ENDPOINT = new InjectionToken<string>('Older API Endpoint');
export const TT_SHARED_API_ENDPOINT = new InjectionToken<string>('TT SHARED - API endpoint');

export const EMARSYS_PUSH_CHANNEL_API_ENDPOINT = new InjectionToken<string>('push channel api endpoint');

export const APPLICATION_VERSION = new InjectionToken<string>('Tag version currently running');

export const MAINTENANCE_SCHEDULE = new InjectionToken<string>('maintenance info to broadcast');
