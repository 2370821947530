import type { OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import type { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import type { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnChanges {
  appearance: MatFormFieldAppearance = 'outline';
  showPassword = false;

  @Output() formSubmit: EventEmitter<{ [key: string]: string }> = new EventEmitter<{ [key: string]: string }>();
  @Input() errorText: string;
  @Input() resetPasswordUrlOrPath: string;
  @Input() email;
  formGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    notRemember: [false],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email']?.currentValue) {
      this.formGroup.patchValue({ email: this.email });
    }
    const errorTextChange: SimpleChange = changes['errorText'];
    if (errorTextChange?.currentValue) {
      this.formGroup.enable();
    }
  }

  handleSubmit(formGroup: UntypedFormGroup): void {
    formGroup.markAllAsTouched();
    if (formGroup.valid) {
      this.formSubmit.emit(formGroup.value);
      this.formGroup.disable();
    }
  }
}
