<ng-container *ngIf="!loadingIndicator; else loadingIndicatorRef">
  <ng-container *ngIf="formState === 'login'; then loginForm; else otpForm"></ng-container>
</ng-container>
<ng-template #loginForm>
  <div class="sections">
    <mat-card appearance="outlined" class="section login">
      <a href="https://www.tt.com">
        <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
      </a>
      <p class="intro">
        <ng-container *ngIf="uiVariant === 'profile'; else defaultVariant">
          Im <button (click)="showOverlay()" class="action">TT-Benutzerkonto</button> sind Ihre persönlichen Daten
          gespeichert. Bitte bestätigen Sie daher Ihre Identität, indem Sie Ihre Zugangsdaten eingeben.
        </ng-container>
        <ng-template #defaultVariant>
          Bitte melden Sie sich mit Ihrem
          <button (click)="showOverlay()" class="action">TT-Benutzerkonto</button>
          an oder registrieren Sie sich neu.
        </ng-template>
      </p>
      <login-form
        (formSubmit)="login($event)"
        [errorText]="errorResponseText"
        [resetPasswordUrlOrPath]="loginPageSettings?.resetPasswordUrlOrPath"
        [email]="email"
      ></login-form>
      <div class="resend-verification-email" *ngIf="emailNotVerified">
        <button
          id="resend-verification-email-action"
          class="verification"
          (click)="resendVerificationEmail()"
          data-cy="login-verify"
        >
          Jetzt verifizieren
        </button>
        <h3 class="register flex" *ngIf="verificationMailSent">
          <mat-icon color="primary">done</mat-icon>Email erfolgreich gesendet.
        </h3>
      </div>
      <h3 class="register">
        Sie haben noch kein TT-Benutzerkonto?
        <a [routerLink]="loginPageSettings.signUpUrlOrPath" queryParamsHandling="merge" data-cy="login-register"
          >Jetzt registrieren</a
        >
      </h3>
      <oauth-media-key-button
        *ngIf="enableMediaKeyLogin$ | async"
        [redirectUrl]="mediaKeyRedirectUrl + login_challenge"
      >
      </oauth-media-key-button>
      <oauth-google-button
        *ngIf="enableGoogleLogin$ | async"
        [redirectUrl]="googleLoginRedirectUrl + login_challenge"
        (loadingIndicator)="showLoadingIndicator($event)"
      >
      </oauth-google-button>
      <oauth-apple-button *ngIf="enableAppleLogin$ | async" [redirectUrl]="appleLoginRedirectUrl + login_challenge">
      </oauth-apple-button>
      <oauth-pay-per-li-button
        *ngIf="enablePayPerLiLogin$ | async"
        [redirectUrl]="payPerLiLoginRedirectUrl + login_challenge"
        (loadingIndicator)="showLoadingIndicator($event)"
      ></oauth-pay-per-li-button>
    </mat-card>

    <div class="section infos" *ngIf="loginPageSettings?.helpLinks">
      <ul class="list">
        <li *ngFor="let lnk of loginPageSettings.helpLinks">
          <a [href]="lnk.url" target="_blank">
            {{ lnk.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #otpForm>
  <tt-com-anmeldung-ui-email-otp-verification></tt-com-anmeldung-ui-email-otp-verification>
</ng-template>

<ng-template #loadingIndicatorRef>
  <div class="loading-indicator">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <p class="loading-indicator">Sie werden in Kürze weitergeleitet</p>
</ng-template>
