import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import type { Observable } from 'rxjs';
import type { SubscriptionInvitationRequestModel } from '@candyland/generic/tt-com-anmeldung/models';
import type { HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type { UserAccountProfileModel } from '@candyland/generic/shared/tt-com-anmeldung/models';
import type { IdentityClaims } from '@candyland/angular/shared/model-nmo';

@Injectable({
  providedIn: 'root',
})
export class SitedataService {
  constructor(
    private http: HttpClient,
    @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string,
    private oauthService: OAuthService
  ) {}

  identityClaims = (): IdentityClaims => this.oauthService.getIdentityClaims() as IdentityClaims;

  getFullProfile(): Observable<UserAccountProfileModel> {
    return this.http.get<UserAccountProfileModel>(
      `${this.profileApiEndpoint}/user/profile/full/${this.identityClaims()?.sub}`,
      {
        headers: new HttpHeaders().set('Authorization', this.oauthService.authorizationHeader()),
      }
    );
  }

  getFamilyInvitation(token: string): Observable<HttpStatusModelTyped<SubscriptionInvitationRequestModel>> {
    return this.http.get<HttpStatusModelTyped<SubscriptionInvitationRequestModel>>(
      `${this.profileApiEndpoint}/user/subscription/invitation/${token}`,
      {
        headers: new HttpHeaders().set('Authorization', this.oauthService.authorizationHeader()),
      }
    );
  }

  acceptFamilyInvitation(body, uid: string): Observable<{ data: UserAccountProfileModel }> {
    return this.http.post<{ data: UserAccountProfileModel }>(
      `${this.profileApiEndpoint}/user/subscription/invitation/accept/${uid}`,
      body
    );
  }
}
