<mat-card appearance="outlined" class="section register">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>
  <form [formGroup]="form" *ngIf="showRegisterForm; else showLinkSubEl">
    <h3>TT-Benutzerkonto erstellen</h3>
    <ng-container>
      <mat-form-field [appearance]="appearance">
        <mat-label>E-Mail</mat-label>
        <input
          matInput
          formControlName="email"
          autocomplete="off"
          type="email"
          name="emailnoauto"
          placeholder="E-Mail"
          required
        />
        <mat-error *ngIf="form.controls.email.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('email')"
          >Bitte geben Sie eine gültige E-Mail Adresse an.</mat-error
        >
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Passwort</mat-label>
        <input
          matInput
          formControlName="password"
          autocomplete="off"
          [type]="!showPassword ? 'password' : 'text'"
          name="password"
          [errorStateMatcher]="matcher"
          placeholder="Passwort"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="showPassword = !showPassword"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="showPassword"
          type="button"
        >
          <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-hint>Minimum {{ settings.minPasswordLength }} Zeichen</mat-hint>
        <mat-error *ngIf="form.controls.password.dirty && form.controls.password.hasError('minlength')"
          >Das Passwort muss mindestens {{ settings.minPasswordLength }} Zeichen haben.</mat-error
        >
        <mat-error *ngIf="form.controls.password.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
      </mat-form-field>

      <mat-form-field [appearance]="appearance" class="password-repeat">
        <mat-label>Passwort wiederholen</mat-label>
        <input
          matInput
          formControlName="passwordRepeat"
          autocomplete="off"
          [type]="!showPasswordRepeat ? 'password' : 'text'"
          [errorStateMatcher]="matcher"
          placeholder="Passwort wiederholen"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="showPasswordRepeat = !showPasswordRepeat"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="showPasswordRepeat"
          type="button"
        >
          <mat-icon>{{ !showPasswordRepeat ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="form.controls.passwordRepeat.hasError('minlength')"
          >Das Passwort muss mindestens {{ settings.minPasswordLength }} Zeichen haben.
        </mat-error>
        <mat-error *ngIf="form.controls.passwordRepeat.hasError('required')"
          >Dieses Feld ist ein Pflichtfeld.</mat-error
        >
        <mat-error *ngIf="form.hasError('notSame')">Passwörter stimmen nicht überein.</mat-error>
      </mat-form-field>

      <mat-checkbox color="primary" formControlName="termsAgreed">
        Ich akzeptiere die <a href="javascript:void(0);" (click)="openTermsDialog()">AGB</a>
      </mat-checkbox>

      <mat-checkbox
        color="primary"
        (change)="toggleSingleSubscriptionLinkingArea()"
        class="sub-activation-form-checkbox"
        formControlName="subscriptionActivationForm"
        infobox
      >
        Ich habe bereits ein TT-Abo
      </mat-checkbox>

      <div class="link-sub-buttons" *ngIf="linkSubscriptionMode !== 'none'">
        <div class="activate">
          <button
            mat-raised-button
            color="primary"
            class="link-sub-btn"
            (click)="onSubmit('customernumber')"
            [disabled]="form.disabled"
          >
            <mat-spinner *ngIf="form.disabled && !success" [diameter]="16"></mat-spinner>
            <span>Aktivieren</span><br />Kundennummer erforderlich
          </button>

          <div class="hint-wrap">
            <a (click)="toggleHint()">Wo finde ich meine Kundennummer?</a>
            <div class="hint" *ngIf="showHint">
              <span (click)="toggleHint()" (keydown)="toggleHint()">x</span>Die Kundennummer finden Sie auf Ihrer TT-Club-Karte, Ihrer
              Abobestätigung oder auf einer Ihrer Rechnungen.
            </div>
          </div>
        </div>
        <button class="no-customer-number" (click)="onSubmit('telephone')">
          Mit Hilfe des Kundencenters aktivieren
        </button>
      </div>

      <button
        mat-raised-button
        (click)="onSubmit(null)"
        color="primary"
        [disabled]="form.disabled"
        *ngIf="linkSubscriptionMode === 'none'"
        class="submit-btn"
      >
        <mat-spinner *ngIf="form.disabled && !success" [diameter]="16"></mat-spinner>
        <mat-icon *ngIf="success">check</mat-icon>
        <span> Registrieren </span>
      </button>

      <tt-com-anmeldung-ui-default-message-box
        class="message-box-subscription-error"
        *ngIf="subscriptionError"
        [boxType]="1"
      >
        <h4>Die Verknüpfung Ihres Accounts mit Ihrem Konto ist Fehlgeschlagen.</h4>
        <p>Fehler: {{ subscriptionError.message }}</p>
        <p>Sie können die Verknüpfung jederzeit in Ihrem Profil vornhemen.</p>
      </tt-com-anmeldung-ui-default-message-box>

      <tt-com-anmeldung-ui-default-message-box class="message-box-http-error" *ngIf="httpError" [boxType]="2">
        {{ httpError.message }}
        <span *ngIf="appendixForEmailInUse"
          >Versuchen Sie sich <a (click)="selectRegistrationErrorLink()">hier</a> anzumelden.</span
        >
      </tt-com-anmeldung-ui-default-message-box>

      <oauth-media-key-button
        [disabled]="form.disabled"
        *ngIf="enableMediaKey === true || socialButtonsEnabled"
        [redirectUrl]="mediaKeyRedirectUrl + login_challenge"
      ></oauth-media-key-button>
      <oauth-google-button
        [disabled]="form.disabled"
        *ngIf="enableGoogleLogin === true || socialButtonsEnabled"
        [redirectUrl]="googleLoginRedirectUrl + login_challenge"
      ></oauth-google-button>
      <oauth-apple-button
        [disabled]="form.disabled"
        *ngIf="enableAppleLogin === true || socialButtonsEnabled"
        [redirectUrl]="appleLoginRedirectUrl + login_challenge"
      ></oauth-apple-button>
      <oauth-pay-per-li-button
        [disabled]="form.disabled"
        *ngIf="enablePayPerLiLogin === true"
        [redirectUrl]="appleLoginRedirectUrl + login_challenge"
      ></oauth-pay-per-li-button>
    </ng-container>

    <div class="recaptcha">
      <candyland-grecaptcha #recaptcha></candyland-grecaptcha>
    </div>
  </form>

  <ng-template #showLinkSubEl>
    <candyland-link-sub
      [accountData]="accountData"
      [linkingType]="linkingType"
      [linkSubscriptionMode]="linkSubscriptionMode"
      [useOTP]="true"
      (showRegisterForm)="linkingType = null; showRegisterForm = $event"
      (wasSuccess)="next.emit($event)"
    >
    </candyland-link-sub>
  </ng-template>
</mat-card>
