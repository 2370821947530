import { Inject, Injectable, Optional } from '@angular/core';
import type { DomNavigator } from '@candyland/angular/shared/cordova/model';
import { IS_CORDOVA } from '@candyland/angular/shared/cordova/model';
import { CordovaDomService } from './cordova-dom.service';

@Injectable({ providedIn: 'root' })
export class CordovaSplashScreenService {
  constructor(private dom: CordovaDomService, @Optional() @Inject(IS_CORDOVA) private isCordova?: boolean) {}

  public show = () => {
    console.log('requested splashscreen');
    const localNavigator: DomNavigator = this.dom.navigator;
    if (!this.isCordova) {
      console.log('not running cordova, ignoring...');
      return;
    }
    if (localNavigator?.splashscreen) {
      console.log('showing splashscreen');
      localNavigator.splashscreen.show();
    }
  };
  public hide = () => {
    console.log('requested splashscreen hide');
    const localNavigator: DomNavigator = this.dom.navigator;
    if (!this.isCordova) {
      console.log('not running cordova, ignoring...');
      return;
    }
    if (localNavigator?.splashscreen) {
      console.log('hiding splashscreen');
      localNavigator.splashscreen.hide();
    }
  };
}
