<candyland-registration-step-email
  *ngIf="step === 'email'"
  (next)="emailRegistered($event)"
></candyland-registration-step-email>

<tt-com-anmeldung-ui-email-otp-verification
  *ngIf="step === 'verification'"
></tt-com-anmeldung-ui-email-otp-verification>

<tt-com-anmeldung-ui-default-message-box class="message-box-unknown-error" *ngIf="unknownError" [boxType]="2">
  Ein unbekannter Fehler ist aufgetreten.
</tt-com-anmeldung-ui-default-message-box>

<div class="section infos">
  <ul class="list">
    <li>
      Hier finden Sie die <a href="http://eprospekt.tt.com/AGB_TTAbo/#page=1" target="_blank">Abo-AGB</a> der Tiroler Tageszeitung und die
      <a href="https://www.tt.com/datenschutz" target="_blank">Datenschutzbestimmungen</a>.
    </li>
    <li>
      <a target="_blank" href="https://club.tt.com">Informationen über die Clubmitgliedschaft</a>
    </li>
    <li>
      <a target="_blank" href="https://abo.tt.com">Ich möchte ein TT-Abo abschließen.</a>
    </li>
  </ul>
</div>
