<div class="section">
  <mat-card appearance="outlined">
    <mat-card-title>Details</mat-card-title>
    <mat-card-content>
      <p>
        Bitte klicken Sie auf den untenstehenden Button “E-Mail an Kundencenter”, um ein E-Mail mit detaillierten Informationen zu dem 
        aufgetretenen Fehler an unser Kundencenter zu senden. Auf diese Weise können wir das Problem schnellstmöglich identifizieren und beheben.
      </p>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Fehlerdaten
          </mat-panel-title>
          <mat-panel-description>
            Für Details klicken
          </mat-panel-description>
        </mat-expansion-panel-header>
        <pre [innerText]="json"></pre>
      </mat-expansion-panel>
    </mat-card-content>
    <mat-card-actions>
      <a mat-raised-button color="primary" [href]="href" class="button">E-Mail an Kundencenter</a>
    </mat-card-actions>
  </mat-card>
</div>
