import { InjectionToken } from '@angular/core';

export const OAUTH_API_ENDPOINT = new InjectionToken('NMO OAuth API');

export const S3_BUCKET_NAME = new InjectionToken('S3 Bucket Name');
export const S3_ASSETS_URL = new InjectionToken('S3 Assets Url');

export const SELF_SERVICE_API_ENDPOINT = new InjectionToken('Anmeldung Self Service');
export const NEW_SAP_NAME_CHANGE_ENABLED = new InjectionToken(
  'Enables new name change over SAP and form toggle for active subscriptions'
);
