import type { AbstractControl, ValidatorFn } from '@angular/forms';

export function fieldsAreEqual(formControlNameLinked: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const formGroup = control.parent;

    if (!formGroup?.controls) {
      return errorState;
    }

    const areEqual = formGroup.controls[formControlNameLinked].value === control.value;
    return areEqual ? null : errorState;
  };
}

const errorState = {
  notequal: true,
};
