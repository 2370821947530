import { HttpClient } from '@angular/common/http';
import type { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import { OAUTH_API_ENDPOINT } from '@candyland/angular/tt-com-anmeldung/model';
import type {
  CompletedRequestModel,
  DefaultProviderLoginRequest,
  ResendEmailVerificationByOTPResponse,
} from '@candyland/generic/tt-com-anmeldung/models';
import type { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string,
    @Inject(OAUTH_API_ENDPOINT) private oauthApiEndpoint: string
  ) {}

  login(body: DefaultProviderLoginRequest): Observable<CompletedRequestModel> {
    const url = `${this.oauthApiEndpoint}/login/providers/default`;

    return this.http.post<CompletedRequestModel>(url, body);
  }

  resendEmailVerificationWithOtp(email: string): Observable<ResendEmailVerificationByOTPResponse> {
    const url = `${this.profileApiEndpoint}/user/account/resend-otp-verification/${encodeURIComponent(email)}`;

    return this.http
      .post<ResendEmailVerificationByOTPResponse>(url, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => new Error(error.error.message))));
  }
}
