import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type { HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type {
  AccountVerificationResponseModel,
  AccountEmailVerificationRequestModel,
} from '@candyland/generic/tt-com-anmeldung/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivateAccountService {
  constructor(private http: HttpClient, @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string) {}

  public activateAccount(
    vToken: string,
    uid: string
  ): Observable<HttpStatusModelTyped<AccountVerificationResponseModel>> {
    const url = `${this.profileApiEndpoint}/user/account/verify`;
    const body: AccountEmailVerificationRequestModel = {
      vtoken: vToken,
      uid: uid,
    };
    return this.http.post<HttpStatusModelTyped<AccountVerificationResponseModel>>(url, body);
  }
}
