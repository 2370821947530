import { Injectable } from '@angular/core';
import type {
  PhoneNumber,
  PhoneNumberCountry,
  PhoneNumberCountryFrontend,
  CountryCode} from '@candyland/generic/shared/tt-com-anmeldung/models';
import {
  getCountryData,
  countries
} from '@candyland/generic/shared/tt-com-anmeldung/models';

@Injectable({ providedIn: 'root' })
export class PhoneNumberService {
  constructor() {}

  getCountries(): Array<PhoneNumberCountryFrontend> {
    const countryRes: PhoneNumberCountryFrontend[] = [];

    for (const country of Object.values(countries)) {
      countryRes.push({
        ...country,
        displayName: `${country.name} (${country.code})`,
      });
    }

    return countryRes;
  }

  getPhoneData(phone: PhoneNumber) {
    if (!phone) {
      return null;
    }
    if (phone.country === null) {
      return phone;
    }
    const country: PhoneNumberCountry = getCountryData(phone.country);
    phone.number = phone.number.replace(country.prefix, '');

    return phone;
  }

  buildFormData(countryCode: CountryCode, number: string): object {
    console.log(`countryCode: ${countryCode}, number: ${number}`);
    const res = {
      Nummer: number,
    };

    const country: PhoneNumberCountry = getCountryData(countryCode);

    if (country.name !== null) {
      res['Vorwahl'] = country.prefix;
      res['Land'] = country.name;
      res['Code'] = country.code;
    }
    return res;
  }
}
