// Regexps for browser/os detection

export const GENERAL = {
    UNKNOWN: 'Unknown',
};

export const OS: any = {
    'Windows': {
        and: [{ or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] }, { not: /\bWindows Phone\b/ }],
    },
    'Mac': {
        and: [/\bMac OS\b/, { not: { or: [/\biPhone\b/, /\biPad\b/, /\biPod\b/, /\bWindows Phone\b/] } }],
    },
    'iOS': {
        and: [{ or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] }, { not: /\bWindows Phone\b/ }],
    },
    'Android': { and: [/\bAndroid\b/, { not: /\bWindows Phone\b/ }] },
    'Linux': /\bLinux\b/,
    'Unix': /\bUNIX\b/,
    'Firefox-OS': { and: [/\bFirefox\b/, /Mobile\b/] },
    'Chrome-OS': /\bCrOS\b/,
    'Windows-Phone': { or: [/\bIEMobile\b/, /\bWindows Phone\b/] },
};

export const BROWSERS: any = {
    'Chrome': {
        and: [
            { or: [/\bChrome\b/, /\bCriOS\b/, /\bHeadlessChrome\b/] },
            {
                not: {
                    or: [/\bOPR\b/, /\bEdg(e|A|iOS)\b/, /\bEdg\/\b/, /\bSamsungBrowser\b/, /\bUCBrowser\b/],
                },
            },
        ],
    },
    'Firefox': { or: [/\bFirefox\b/, /\bFxiOS\b/] },
    'Safari': {
        and: [
            /^((?!CriOS).)*\Safari\b.*$/,
            {
                not: {
                    or: [/\bOPR\b/, /\bEdg(e|A|iOS)\b/, /\bEdg\/\b/, /\bWindows Phone\b/, /\bSamsungBrowser\b/, /\bUCBrowser\b/],
                },
            },
        ],
    },
    'Opera': { or: [/Opera\b/, /\bOPR\b/] },
    'IE': {
        or: [/\bMSIE\b/, /\bTrident\b/, /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\)$/],
    },
    'MS-Edge': { or: [/\bEdg(e|A|iOS)\b/] },
    'MS-Edge-Chromium': /\bEdg\/\b/,
};

export const OS_VERSIONS_MAP: any = {
    'windows-95': /(Windows 95|Win95|Windows_95)/,
    'windows-me': /(Win 9x 4.90|Windows ME)/,
    'windows-98': /(Windows 98|Win98)/,
    'windows-ce': /Windows CE/,
    'windows-2000': /(Windows NT 5.0|Windows 2000)/,
    'windows-xp': /(Windows NT 5.1|Windows XP)/,
    'windows-server-2003': /Windows NT 5.2/,
    'windows-vista': /Windows NT 6.0/,
    'windows-7': /(Windows 7|Windows NT 6.1)/,
    'windows-8-1': /(Windows 8.1|Windows NT 6.3)/,
    'windows-8': /(Windows 8|Windows NT 6.2)/,
    'windows-10': /(Windows NT 10.0)/,
    'windows-phone-7-5': /(Windows Phone OS 7.5)/,
    'windows-phone-8-1': /(Windows Phone 8.1)/,
    'windows-phone-10': /(Windows Phone 10)/,
    'windows-nt-4-0': {
        and: [/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/, { not: /Windows NT 10.0/ }],
    },
    'mac-os-x': /(MAC OS X\s*[^ 0-9])/,
    'mac-os-x-3': /(Darwin 10.3|Mac OS X 10.3)/,
    'mac-os-x-4': /(Darwin 10.4|Mac OS X 10.4)/,
    'mac-os-x-5': /(Mac OS X 10.5)/,
    'mac-os-x-6': /(Mac OS X 10.6)/,
    'mac-os-x-7': /(Mac OS X 10.7)/,
    'mac-os-x-8': /(Mac OS X 10.8)/,
    'mac-os-x-9': /(Mac OS X 10.9)/,
    'mac-os-x-10': /(Mac OS X 10.10)/,
    'mac-os-x-11': /(Mac OS X 10.11)/,
    'mac-os-x-12': /(Mac OS X 10.12)/,
    'mac-os-x-13': /(Mac OS X 10.13)/,
    'mac-os-x-14': /(Mac OS X 10.14)/,
    'mac-os-x-15': /(Mac OS X 10.15)/,
    'iOS': /(iPhone OS\s*[0-9_]+)/,
    'Linux x86_64': /(Linux x86_64)/,
};

export const BROWSER_VERSIONS_MAP: any = {
    "Chrome": [/\bChrome\/([\d\.]+)\b/, /\bCriOS\/([\d\.]+)\b/, /\bHeadlessChrome\/([\d\.]+)\b/],
    'Firefox': [/\bFirefox\/([\d\.]+)\b/, /\bFxiOS\/([\d\.]+)\b/],
    'Safari': [/\bVersion\/([\d\.]+)\b/, /\bSafari\/([\d\.]+)\b/],
    'Opera': [/\bVersion\/([\d\.]+)\b/, /\bOPR\/([\d\.]+)\b/],
    'IE': [/\bMSIE ([\d\.]+\w?)\b/, /\brv:([\d\.]+\w?)\b/],
    'MS-Edge': /\bEdg(?:e|A|iOS)\/([\d\.]+)\b/,
    'MS-Edge-Chromium': /\bEdg\/([\d\.]+)\b/,
};

export const OS_VERSIONS: any = Object.keys(OS_VERSIONS_MAP).reduce((obj, key: string) => {
    obj[key] = OS_VERSIONS_MAP[key];
    return obj;
}, {});

export const BROWSER_VERSIONS: any = Object.keys(BROWSER_VERSIONS_MAP).reduce((obj, key: string) => {
    obj[key] = BROWSER_VERSIONS_MAP[key];
    return obj;
}, {});