import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

/**
 * Changed DateAdapter to adapt Material Datepicker input to DD.MM.YYYY format
 */
@Injectable()
export class DefaultDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str = value.split('.');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    if (value.length === 8 && value.match(/(\d\d)(\d\d)(\d\d\d\d)/)) {
      const m = value.match(/(\d\d)(\d\d)(\d\d\d\d)/);
      return new Date(Number(m[3]), Number(m[2]) - 1, Number(m[1]));
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    date = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
    displayFormat = { ...displayFormat, timeZone: 'utc' };

    const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
    return dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}
