import { catchError, throwError, map } from 'rxjs';
import type { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import type { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type {
  ResetPasswordRequestModel,
  ResetPasswordResponseModel,
  UserForgotPasswordByOtpRequestModel,
  UserForgotPasswordModelResponse,
} from '@candyland/generic/tt-com-anmeldung/models';
import type { OAuth2QueryParamsModel } from '@candyland/generic/shared/tt-com-anmeldung/models';

export interface ResetPasswordReturns extends ResetPasswordResponseModel {
  email: string;
  queryParams?: OAuth2QueryParamsModel;
}

export interface UserForgotPasswordModelEx extends UserForgotPasswordByOtpRequestModel {
  email: string;
}

export class ResetPasswordError extends Error {
  constructor(message: string, public status: number, public requestId: string, public number: number) {
    super(message);
    this.name = 'ResetPasswordError';
  }
}

@Injectable({ providedIn: 'root' })
export class ResetPasswordService {
  private readonly passwordForgotUrl: string;
  constructor(private http: HttpClient, @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string) {
    this.passwordForgotUrl = `${this.profileApiEndpoint}/user/password/forgot/otp`;
  }

  public resetPassword(data: ResetPasswordRequestModel): Observable<ResetPasswordReturns> {
    return this.http.put<{ data: ResetPasswordResponseModel }>(this.passwordForgotUrl, data).pipe(
      map((response) => ({ ...response.data, email: data.email, queryParams: data.queryParams })),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(error.error.message)))
    );
  }

  public changePassword(data: UserForgotPasswordModelEx): Observable<UserForgotPasswordModelResponse> {
    const body: UserForgotPasswordByOtpRequestModel = {
      email: data.email,
      password: data.password,
      otp: data.otp,
      otpPart: data.otpPart,
      ...(data.login_challenge ? { login_challenge: data.login_challenge } : {}),
    };

    return this.http
      .post<UserForgotPasswordModelResponse>(this.passwordForgotUrl, body)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new ResetPasswordError(error.error.message, error.error.status, error.error.requestId, error.error.number)
          )
        )
      );
  }
}
