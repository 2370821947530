import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'candyland-tt-com-anmeldung-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<TermsComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
