import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AngularTtComAnmeldungUiModule } from '@candyland/angular/tt-com-anmeldung/ui';
import { ChangePasswordPageComponent as ChangePasswordPageComponentV2 } from './componentsV2/change-password-page/change-password-page.component';
import { ResetPasswordPageComponent as ResetPasswordPageComponentV2 } from './componentsV2/reset-password-page/reset-password-page.component';
import { ResetPasswordService as ResetPasswordServiceV2 } from './servicesV2/reset-password.service';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    AngularTtComAnmeldungUiModule,
    RouterModule,
  ],
  providers: [ResetPasswordServiceV2],
  declarations: [ChangePasswordPageComponentV2, ResetPasswordPageComponentV2],
  exports: [],
})
export class AngularTtComAnmeldungFeatureResetPasswordModule {}
