import { Inject, Injectable, Optional } from '@angular/core';
import type { Cordova, Device, DomNavigator, DomWindow, WindowPlugins } from '@candyland/angular/shared/cordova/model';
import { IS_CORDOVA } from '@candyland/angular/shared/cordova/model';

@Injectable({ providedIn: 'root' })
export class CordovaDomService {
  private readonly isCordova: boolean;
  constructor(@Optional() @Inject(IS_CORDOVA) private isCordovaInjectable?: boolean) {
    this.isCordova = this.isCordovaInjectable === true;
  }

  public get window(): DomWindow | null {
    return this.isCordova ? window : null;
  }

  public get cordova(): Cordova | null {
    const localWindow = this.window;
    return !localWindow && !localWindow.cordova ? null : localWindow.cordova;
  }

  public get device(): Device | null {
    const localWindow: DomWindow = this.window;
    return !localWindow && !localWindow.device ? null : localWindow.device;
  }

  public get document(): Document | null {
    return this.isCordova ? document : null;
  }

  public get navigator(): DomNavigator | null {
    return this.isCordova ? navigator : null;
  }

  public get windowPlugins(): WindowPlugins | null {
    const localWindow: DomWindow = this.window;
    return !localWindow && !localWindow.plugins ? null : localWindow.plugins;
  }
}
