import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DISPOSE } from './di-tokens';

@Component({
  selector: 'login-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInfoComponent {
  constructor(@Inject(DISPOSE) public dispose: () => void) {}
}
