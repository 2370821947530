import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'tt-com-anmeldung-ui-submit-buttton',
  templateUrl: './submit-buttton.component.html',
  styleUrls: ['./submit-buttton.component.scss'],
})
export class SubmitButttonComponent {
  @Input() form: UntypedFormGroup;
  @Input() success: boolean;
  @Input() buttonDisabled: false;
  @Input() formDisabled = false;
  @Input() buttonText: string;

  constructor() {}
}
