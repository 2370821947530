import { InjectionToken } from '@angular/core';

export const TT_ASSETS_ENDPOINT: InjectionToken<string> = new InjectionToken<string>('TT SHARED - Assets S3 endpoint');
export const TT_ASSETS_BUCKET_NAME: InjectionToken<string> = new InjectionToken<string>('tt assets bucket name');
export const TT_COM_WETTER_API_ENDPOINT: InjectionToken<string> = new InjectionToken<string>('TT Wetter API endpoint');
export const TT_COM_WWW_FRONTEND: InjectionToken<string> = new InjectionToken<string>('tt.com frontend endpoint');
export const ENVIRONMENT: InjectionToken<string> = new InjectionToken<string>('Environment that is currently running');

export const TT_DATA_USER_ENDPOINT: InjectionToken<string> = new InjectionToken<string>(
  'TT SHARED - Anmeldung/User Data API endpoint'
);

export const COMMENTS_BACKEND_API_ENDPOINT: InjectionToken<string> = new InjectionToken<string>(
  'Comments Backend API Endpoint'
);
export const TT_COM_ANMELDUNG_IMAGE_BUCKET: InjectionToken<string> = new InjectionToken<string>(
  'tt-com-anmeldung assets bucket'
);

export const MEDIA_KEY_REDIRECT_URL: InjectionToken<string> = new InjectionToken('URL to redirect to media key');
export const ENABLE_MEDIA_KEY: InjectionToken<string> = new InjectionToken<string>(
  'enable or disable media key feature'
);

export const GOOGLE_LOGIN_REDIRECT_URL: InjectionToken<string> = new InjectionToken('URL to redirect to  google login');
export const ENABLE_GOOGLE_LOGIN: InjectionToken<string> = new InjectionToken<string>(
  'enable or disable google login feature'
);

export const APPLE_LOGIN_REDIRECT_URL: InjectionToken<string> = new InjectionToken('URL to redirect to apple login');
export const ENABLE_APPLE_LOGIN: InjectionToken<string> = new InjectionToken<string>(
  'enable or disable apple login feature'
);

export const PAY_PER_LI_LOGIN_REDIRECT_URL: InjectionToken<string> = new InjectionToken<string>(
  'URL to redirect to PayPerLi login'
);
export const ENABLE_PAY_PER_LI_LOGIN: InjectionToken<string> = new InjectionToken<string>(
  'enable or disable PayPerLi login feature'
);
export const POSTSCRIBE_MODULE: InjectionToken<string> = new InjectionToken('Postscribe Module Inject');

export const NEWSLETTER_OPT_IN_ENDPOINT: InjectionToken<string> = new InjectionToken('Newsletter Opt In route');
export const NEWSLETTER_API_ENDPOINT: InjectionToken<string> = new InjectionToken('Newsletter api route');
export const TT_COM_PROCESSING_ENDPOINT: InjectionToken<string> = new InjectionToken('TT processing endpoint');
export const LIVE_BLOG_ASSETS_BUCKET_NAME: InjectionToken<string> = new InjectionToken('Liveblog s3 bucket name');
