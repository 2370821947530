import type { OnDestroy, OnInit } from '@angular/core';
import { EventEmitter, Output, PLATFORM_ID, Component, Inject, Input, Optional } from '@angular/core';
import { IS_CORDOVA } from '@candyland/angular/shared/cordova/model';
import { CordovaLoginService } from '../../services/cordova-login.service';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import type { Subscription } from 'rxjs';
import { TT_ASSETS_ENDPOINT } from '@candyland/angular/shared/util-nmo';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'oauth-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
})
export class GoogleButtonComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() redirectUrl: string;
  @Input() hideSeparator?: boolean;
  @Input() clickCallback: () => void;
  @Input() mouseDownCallback: () => void;
  @Output() loadingIndicator: EventEmitter<boolean> = new EventEmitter<boolean>();
  public clicked = false;
  redirectFromApp: string | null = null;

  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cordovaLogin: CordovaLoginService,
    @Inject(TT_ASSETS_ENDPOINT) public assetsEndpoint: string,
    @Optional() @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(IS_CORDOVA) private isCordova?: boolean
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        if (params.nmo_init_social_login === 'google') {
          console.log('google-button - starting google login');
          this.loadingIndicator.emit(true);
          this.doRedirect();
        } else if (params.nmo_app === 'tt') {
          console.log('google-button - found app: tt');
          this.redirectFromApp = 'ttcomwww://app/login?nmo_init_social_login=google';
        } else if (params.nmo_app === 'club') {
          console.log('google-button - found app: club');
          this.redirectFromApp = 'ttcomclub://app/login?nmo_init_social_login=google';
        }
      })
    );
  }
  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  doRedirect(): void {
    this.clicked = true;

    if (this.redirectFromApp !== null) {
      console.log('google-button - redirecting back to app');
      this.redirectUrl = this.redirectFromApp;
    }

    if (typeof this.clickCallback === 'function') {
      this.clickCallback();
    }

    if (this.isCordova) {
      this.cordovaLogin.openInAppBrowser(this.redirectUrl);
    } else if (isPlatformBrowser(this.platformId)) {
      window.location.href = this.redirectUrl;
    } else {
      console.log('not redirecting, running on server');
    }
  }

  handleMouseDown(): void {
    if (typeof this.mouseDownCallback === 'function') {
      this.mouseDownCallback();
    }
  }
}
