import { Injector, Injectable } from '@angular/core';
import type { OverlayRef } from '@angular/cdk/overlay';
import { Overlay, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AccountInfoComponent } from '../account-info/account-info.component';
import type { Observable } from 'rxjs';
import { merge, using } from 'rxjs';
import { filter, tap, take } from 'rxjs/operators';
import { DISPOSE } from '../account-info/di-tokens';

@Injectable()
export class OverlayService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  showOverlay() {
    this.overlayRef = this.createOverlay();
    const injector: Injector = Injector.create({
      providers: [{ provide: DISPOSE, useValue: this.overlayRef.dispose.bind(this.overlayRef) }],
    });
    const accountInfoPortal: ComponentPortal<AccountInfoComponent> = new ComponentPortal(
      AccountInfoComponent,
      null,
      injector
    );
    this.overlayRef.attach(accountInfoPortal);

    return using(
      (): { unsubscribe: () => void } => ({ unsubscribe: () => this.overlayRef.dispose() }),
      () => this.getCloseEvents(this.overlayRef)
    ).pipe(
      take(1),
      tap(() => this.overlayRef.dispose())
    );
  }

  private createOverlay(): OverlayRef {
    const positionStrategy: GlobalPositionStrategy = new GlobalPositionStrategy()
      .centerHorizontally()
      .centerVertically();
    return this.overlay.create({
      hasBackdrop: true,
      width: '90%',
      maxWidth: 600,
      height: '90%',
      maxHeight: 600,
      backdropClass: 'overlay-backdrop',
      panelClass: 'overlay-panel',
      positionStrategy,
    });
  }

  private getCloseEvents(overlayRef: OverlayRef): Observable<any> {
    return merge(
      overlayRef.backdropClick(),
      overlayRef.keydownEvents().pipe(filter((keyEvent: KeyboardEvent): boolean => keyEvent.key === 'Escape'))
    );
  }
}
