import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'tt-com-anmeldung-ui-simple-footer',
  templateUrl: './simple-footer.component.html',
  styleUrls: ['./simple-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFooterComponent {
  @Input()
  showTOC? = false;

  contactPage =
    isPlatformBrowser(this.platformId) && window.location.hostname.indexOf('stage.oauth.nmo.at') !== -1
      ? 'https://tt-com-anmeldung-profile-ng.stage.oauth.nmo.at/kontakt'
      : 'https://benutzerkonto.tt.com/kontakt';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
}
