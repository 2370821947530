/**
 * Scrolls in a form to very first input, which has a validation error
 */
export function scrollToInvalidInput(): void {
  const firstElementWithError: HTMLElement = document.querySelector('form .ng-invalid');

  if (firstElementWithError) {
    try {
      window.scroll({
        top: getTopOffset(firstElementWithError),
        left: 0,
        behavior: 'smooth',
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export function getTopOffset(controlEl: HTMLElement, labelOffset = 20): number {
  return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
}
