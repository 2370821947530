import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, PLATFORM_ID } from '@angular/core';
import type { Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserSystemInfoService } from '@candyland/angular/shared/util-nmo';
import { isPlatformBrowser } from '@angular/common';
import { format } from 'date-fns';

@Component({
  selector: 'login-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent implements OnInit {
  error: { error: string; desc: string; hint: string };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private userSystemInfoService: UserSystemInfoService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params): void => {
      if (params.error) {
        this.handleError(params.error, params.error_description, params.error_hint);
      }
    });
  }

  private handleError(error, desc, hint): void {
    this.error = {
      error,
      desc,
      hint,
    };
    console.error(error, desc, hint);
  }

  getMailTo(errorData): string {
    const mailAddress = 'service@tt.com';
    const subject = 'Fehler bei der Anmeldung (login.tt.com Seite)';
    const userSystem = this.userSystemInfoService.getDeviceInfo();

    let body = '\n\n';
    body +=
      'Die folgenden Informationen wurden automatisch bei einem Fehler im Anmeldeprozess erstellt. (Hydra Error Page)';
    body += '\n\n';
    body += `Zeitpunkt: ${format(new Date(), 'dd.MM.yyyy HH:mm:ss')}\n`;
    body += `Location: ${this.href()}\n`;
    body += `Browser: ${userSystem.browser} ${userSystem.browser_version}\n`;
    body += `Betriebssystem: ${userSystem.os} (${userSystem.os_version}) \n`;
    body += `Cookie: ${userSystem.cookieEnabled ? 'Aktiviert' : 'Deaktiviert'}\n\n`;
    body += this.prettyStringify({ errorData });

    return `mailto:${mailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  prettyStringify(jsonSafeObject: object): string {
    return JSON.stringify(jsonSafeObject, null, 2);
  }

  private href(): string {
    return isPlatformBrowser(this.platformId)
      ? window.location.href
      : '## ERROR: Cannot get location.href on server ##';
  }
}
