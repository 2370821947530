<mat-card appearance="outlined" class="section activation">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>
  <div class="error-success-section section">
    <div class="title">Email verifizieren</div>
    <tt-com-anmeldung-ui-default-message-box *ngIf="httpError" [boxType]="2" [scrollOnAppear]="false"
      ><span [innerHtml]="httpError.message"></span
    ></tt-com-anmeldung-ui-default-message-box>
    <tt-com-anmeldung-ui-default-message-box *ngIf="success" [boxType]="0" [scrollOnAppear]="false">{{
      success.message
    }}</tt-com-anmeldung-ui-default-message-box>
    <a href="https://anmeldung.tt.com" mat-raised-button color="primary">zum TT-Benutzerkonto</a>
  </div>
</mat-card>
