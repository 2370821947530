import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import {
  ActivateAccountPageComponent,
  ChangeEmailSuccessComponent,
} from '@candyland/angular/tt-com-anmeldung/feature-activate-account';
import { ErrorPageComponent, LoginPageComponent } from '@candyland/angular/tt-com-anmeldung/feature-login';
import {
  RegistrationOtpComponent,
  FamilyMemberAcceptanceComponent,
} from '@candyland/angular/tt-com-anmeldung/feature-registration';
import { ChangePasswordV2, ResetPasswordV2 } from '@candyland/angular/tt-com-anmeldung/feature-reset-password';
import { NotFoundComponent } from '@candyland/angular/tt-com-anmeldung/ui';
import { GlobalFrontendPaths } from '@candyland/generic/shared/tt-com-anmeldung/models';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'registrieren',
    component: RegistrationOtpComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordV2.ResetPasswordPageComponent,
  },
  {
    path: GlobalFrontendPaths.emailVerification,
    component: ActivateAccountPageComponent,
  },
  {
    path: GlobalFrontendPaths.passwordChange,
    component: ChangePasswordV2.ChangePasswordPageComponent,
  },
  {
    path: GlobalFrontendPaths.emailChange,
    component: ChangeEmailSuccessComponent,
  },
  {
    path: `${GlobalFrontendPaths.invitationAccept}/:uid`,
    component: FamilyMemberAcceptanceComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
