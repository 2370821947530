import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserSystemInfoService } from '@candyland/angular/shared/util-nmo';

@Component({
  selector: 'tt-com-anmeldung-ui-default-message-expander',
  templateUrl: './default-message-expander.component.html',
  styleUrls: ['./default-message-expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultMessageExpanderComponent implements OnChanges {
  @Input() message: any = null;
  json: string = null;
  href: string = null;

  constructor(private userSystemInfoService: UserSystemInfoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
      this.href = this.getMailTo(changes.message.currentValue);
      this.json = this.prettyStringify(changes.message.currentValue);
    }
  }

  prettyStringify(jsonSafeObject: object) {
    return JSON.stringify(jsonSafeObject, null, 2);
  }

  getMailTo(data: any) {
    const mailAddress = 'service@tt.com';
    const subject = 'Automatisch generierte Nachricht von login.tt.com';
    const userSystem = this.userSystemInfoService.getDeviceInfo();

    let body = '\n\n';
    body += 'Die folgenden Informationen wurden automatisch erstellt.' + '\n\n';
    body += `Zeitpunkt: ${new Date().toISOString()} \n\n`;
    body += 'Browser: ' + userSystem.browser + ' ' + userSystem.browser_version + '\n';
    body += 'Betriebssystem: ' + userSystem.os + ' (' + userSystem.os_version + ') \n';
    body += 'Cookie: ' + (userSystem.cookieEnabled ? 'Aktiviert' : 'Deaktiviert') + '\n\n';
    body += this.prettyStringify({ data });

    return `mailto:${mailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
