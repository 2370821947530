import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Optional } from '@angular/core';
import type { Params } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, Subject } from 'rxjs';
import type { Subscription, Observable } from 'rxjs';
import { OverlayService } from './overlay.service';
import { filter, takeUntil } from 'rxjs/operators';
import type {
  DefaultProviderLoginRequest,
  CompletedRequestModel,
  ResendEmailVerificationByOTPResponse,
} from '@candyland/generic/tt-com-anmeldung/models';
import { LOGIN_PAGE_SETTINGS, LoginPageSettings } from '../token';
import { OAUTH_API_ENDPOINT } from '@candyland/angular/tt-com-anmeldung/model';
import {
  MEDIA_KEY_REDIRECT_URL,
  ENABLE_MEDIA_KEY,
  GOOGLE_LOGIN_REDIRECT_URL,
  ENABLE_GOOGLE_LOGIN,
  APPLE_LOGIN_REDIRECT_URL,
  ENABLE_APPLE_LOGIN,
  PAY_PER_LI_LOGIN_REDIRECT_URL,
  ENABLE_PAY_PER_LI_LOGIN,
  TT_COM_WWW_FRONTEND,
  WindowService,
} from '@candyland/angular/shared/util-nmo';
import { LoginService } from '../../login.service';

@Component({
  selector: 'login-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OverlayService],
})
export class LoginPageComponent implements OnDestroy, OnInit {
  errorResponseText: string;
  enableGoogleLogin$: Observable<boolean> = this.activatedRoute.queryParams.pipe(
    filter((params: Params) => !!params.nmo_disable_login_provider),
    map((params: Params) => this.allowLoginProvider(params.nmo_disable_login_provider, 'google')),
    startWith(this.enableGoogleLogin === 'true')
  );
  enableAppleLogin$: Observable<boolean> = this.activatedRoute.queryParams.pipe(
    filter((params: Params) => !!params.nmo_disable_login_provider),
    map((params: Params) => this.allowLoginProvider(params.nmo_disable_login_provider, 'apple')),
    startWith(this.enableAppleLogin === 'true')
  );
  enableMediaKeyLogin$: Observable<boolean> = this.activatedRoute.queryParams.pipe(
    filter((params: Params) => !!params.nmo_disable_login_provider),
    map((params: Params) => this.allowLoginProvider(params.nmo_disable_login_provider, 'media-key')),
    startWith(this.enableMediaKey === 'true')
  );
  enablePayPerLiLogin$: Observable<boolean> = this.activatedRoute.queryParams.pipe(
    filter((params: Params) => !!params.nmo_disable_login_provider),
    map((params: Params) => this.allowLoginProvider(params.nmo_disable_login_provider, 'pay-per-li')),
    startWith(this.enablePayPerLiLogin === 'true')
  );
  formData;
  emailNotVerified = false;
  public login_challenge: string;
  email: string = null;
  uiVariant: string;
  verificationMailSent = false;
  loadingIndicator = false;

  formState: 'login' | 'verification' = 'login';

  subscriptions: Subscription[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private defaultProviderLoginPath = '/login/providers/default';

  constructor(
    private activatedRoute: ActivatedRoute,
    private overlayService: OverlayService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginService: LoginService,
    private router: Router,
    private windowService: WindowService,
    @Inject(OAUTH_API_ENDPOINT) private oauthApiEndpoint: string,
    @Inject(TT_COM_WWW_FRONTEND) public readonly ttComFrontendEndpoint: string,
    // media key
    @Inject(MEDIA_KEY_REDIRECT_URL) public mediaKeyRedirectUrl: string,
    @Inject(ENABLE_MEDIA_KEY) public enableMediaKey: string,
    // google login
    @Inject(GOOGLE_LOGIN_REDIRECT_URL) public googleLoginRedirectUrl: string,
    @Inject(ENABLE_GOOGLE_LOGIN) public enableGoogleLogin: string,
    // google login
    @Inject(APPLE_LOGIN_REDIRECT_URL) public appleLoginRedirectUrl: string,
    @Inject(ENABLE_APPLE_LOGIN) public enableAppleLogin: string,
    // PayPerLi login
    @Inject(PAY_PER_LI_LOGIN_REDIRECT_URL) public payPerLiLoginRedirectUrl: string,
    @Inject(ENABLE_PAY_PER_LI_LOGIN) public enablePayPerLiLogin: string,
    @Optional() @Inject(LOGIN_PAGE_SETTINGS) public loginPageSettings: LoginPageSettings
  ) {}

  ngOnInit(): void {
    this.login_challenge = this.activatedRoute.snapshot.queryParamMap.get('login_challenge');
    this.email = this.activatedRoute.snapshot.queryParamMap.get('nmo_email');
    this.uiVariant = this.activatedRoute.snapshot.queryParamMap.get('ui_variant');

    if (!this.login_challenge && this.windowService.isBrowser) {
      window.location.href = `${this.ttComFrontendEndpoint}/login`;
    }

    if (this.email !== null) {
      this.email = decodeURIComponent(this.email);
      this.changeDetectorRef.markForCheck();
    }

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        if (params.email && params.otpPart && this.formState !== 'verification') {
          this.formState = 'verification';
          this.changeDetectorRef.detectChanges();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public login(values): void {
    const url: string = this.oauthApiEndpoint + this.defaultProviderLoginPath;
    try {
      this.formData = values;
      const body: DefaultProviderLoginRequest = {
        email: values.email,
        password: values.password,
        remember: !values.notRemember,
        login_challenge: this.login_challenge,
      };

      this.sendLoginRequest(body);
    } catch (err) {
      console.error(err);
      this.errorResponseText =
        'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es noch einmal. ' +
        'Wenn das Problem weiterhin besteht, schreiben Sie uns bitte eine E-Mail an service@tt.com. Bitte schicken Sie folgende ' +
        'Information mit: ' +
        JSON.stringify({ url, func: 'LoginPageComponent.login' }, null, ' ');
      this.changeDetectorRef.markForCheck();
    }
  }

  public sendLoginRequest(body: DefaultProviderLoginRequest) {
    const defaultErrMessage =
      'Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung' +
      ' und versuchen Sie es noch einmal. Wenn das Problem weiterhin besteht, schreiben Sie uns bitte eine E-Mail an service@tt.com';

    this.errorResponseText = null;
    this.changeDetectorRef.markForCheck();
    this.loginService.login(body).subscribe({
      next: (res: CompletedRequestModel): void => {
        try {
          location.assign(res.redirect_to);
        } catch (err) {
          console.error(err);
          this.errorResponseText =
            'Es ist ein Fehler bei der Weiterleitung passiert. Um die Anmeldung fortzusetzen, ' +
            `klicken Sie bitte auf folgenden Link: <a href="${res.redirect_to}">${res.redirect_to}</a>`;
          this.changeDetectorRef.markForCheck();
        }
      },
      error: (err): void => {
        console.error(err);
        this.errorResponseText = err.error.message || defaultErrMessage;
        if (err.error.status === 401 && err.error.number === 8) {
          this.emailNotVerified = true;
        }
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  public showOverlay(): void {
    // Ignore subscription warning because we use takeUntil to unsubscribe
    // eslint-disable-next-line rxjs/no-ignored-subscription
    this.overlayService.showOverlay().pipe(takeUntil(this.destroy$)).subscribe();
  }

  public showLoadingIndicator(event: boolean): void {
    this.loadingIndicator = event;
    this.changeDetectorRef.detectChanges();
  }

  public resendVerificationEmail(): void {
    this.emailNotVerified = false;
    const email = this.formData.email;
    const defaultErrMessage =
      'Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung' +
      ' und versuchen Sie es noch einmal. Wenn das Problem weiterhin besteht, schreiben Sie uns bitte eine E-Mail an service@tt.com';

    this.loginService.resendEmailVerificationWithOtp(email).subscribe({
      next: (res: ResendEmailVerificationByOTPResponse): void => {
        this.router
          .navigate([], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            queryParams: { email, otpPart: res.data.otpPart },
          })
          .then();
      },
      error: (err): void => {
        this.errorResponseText = err.message || defaultErrMessage;
        console.error(err);
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  private allowLoginProvider(parameter: string | string[], provider: string): boolean {
    if (typeof parameter === 'string' ? parameter === 'all' : parameter.includes('all')) {
      return false;
    } else if (typeof parameter === 'string' ? parameter === provider : parameter.includes(provider)) {
      return false;
    }
    return true;
  }
}
