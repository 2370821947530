import type { StateKey, TransferState } from '@angular/core';
import { makeStateKey } from '@angular/core';
import type { ConfType } from '@candyland/angular/shared/model-nmo';

declare const window;

export const runtimeConfigFactory =
  (keySuffix: string, fallbackValue: string) => (transferState?: TransferState, nconf?: ConfType) => {
    const keyPrefix = 'NMO_RUNTIME';
    const key = `${keyPrefix}__${keySuffix}`;

    return (
      getConfigFromLocalStorage(key) ||
      getConfigFromServer(transferState, key) ||
      getConfigOnServer(nconf, keyPrefix, keySuffix) ||
      fallbackValue
    );
  };

export function getConfigFromServer(transferState: TransferState, key: string): any {
  const stateKey: StateKey<string> = makeStateKey<string>(key);
  return transferState && transferState.get(stateKey, null);
}

function getConfigOnServer(nconf: ConfType, keyPrefix: string, keySuffix: string): string {
  const nconfKey = `${keyPrefix}:${keySuffix}`;
  const nconfKeyOverride = `UNIVERSAL_XX_${nconfKey}`;
  return nconf && (nconf.get(nconfKeyOverride) || nconf.get(nconfKey));
}

function getConfigFromLocalStorage(key: string): string {
  return typeof window !== 'undefined' && window.localStorage?.getItem(key);
}

export function parseBool<T>(text: string, defaultTo: T): boolean | T {
  if (text === 'false') {
    return false;
  } else if (text === 'true') {
    return true;
  }
  return defaultTo;
}
