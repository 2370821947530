export const environment = {
  production: true,
  ttAssetsEndpoint: 'https://assets.tt.com',
  oauthApiEndpoint: 'http://localhost:9110',
  profileApiEndpoint: 'http://localhost:9120',
  ttComFrontendEnpoint: 'https://www.tt.com',
  // media key
  mediaKeyRedirectUrl:
    'https://tt-com-anmeldung-external-auth-provider-api.stage.oauth.nmo.at/media_key/code?login_challenge=',
  enableMediaKey: '',
  // google login
  googleLoginRedirectUrl:
    'https://tt-com-anmeldung-external-auth-provider-api.stage.oauth.nmo.at/google/code?login_challenge=',
  enableGoogleLogin: '',
  // apple login
  appleLoginRedirectUrl:
    'https://tt-com-anmeldung-external-auth-provider-api.stage.oauth.nmo.at/apple/code?login_challenge=',
  enableAppleLogin: '',
  payPerLiLoginRedirectUrl:
    'https://tt-com-anmeldung-external-auth-provider-api.stage.oauth.nmo.at/pay-per-li/code?login_challenge=',
  enablePayPerLiLogin: 'false',
  tt_com_processing_endpoint: 'processing.tt.com',
};
