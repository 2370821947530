import { CountryCode } from '../country-code';

export interface PhoneNumberCountry {
  code: CountryCode;
  name: string;
  prefix: string;
}

export interface PhoneNumberCountryFrontend extends PhoneNumberCountry {
  displayName: string;
}

export function getCountryData(code: CountryCode = null): PhoneNumberCountry {
  if (code === null || !countries[code]) {
    return {
      code: null,
      name: null,
      prefix: null,
    };
  }
  return countries[code];
}

export const countries: Record<CountryCode, PhoneNumberCountry> = {
  [CountryCode.AT]: {
    code: CountryCode.AT,
    name: 'Österreich',
    prefix: '+43',
  },
  [CountryCode.AU]: {
    code: CountryCode.AU,
    name: 'Australien',
    prefix: '+61',
  },
  [CountryCode.BE]: {
    code: CountryCode.BE,
    name: 'Belgien',
    prefix: '+32',
  },
  [CountryCode.BG]: {
    code: CountryCode.BG,
    name: 'Bulgarien',
    prefix: '+359',
  },
  [CountryCode.CH]: {
    code: CountryCode.CH,
    name: 'Schweiz',
    prefix: '+41',
  },
  [CountryCode.CY]: {
    code: CountryCode.CY,
    name: 'Zypern',
    prefix: '+357',
  },
  [CountryCode.CZ]: {
    code: CountryCode.CZ,
    name: 'Tschechien',
    prefix: '+420',
  },
  [CountryCode.DE]: {
    code: CountryCode.DE,
    name: 'Deutschland',
    prefix: '+49',
  },
  [CountryCode.DK]: {
    code: CountryCode.DK,
    name: 'Dänemark',
    prefix: '+45',
  },
  [CountryCode.EE]: {
    code: CountryCode.EE,
    name: 'Estland',
    prefix: '+372',
  },
  [CountryCode.ES]: {
    code: CountryCode.ES,
    name: 'Spanien',
    prefix: '+34',
  },
  [CountryCode.FI]: {
    code: CountryCode.FI,
    name: 'Finnland',
    prefix: '+358',
  },
  [CountryCode.FR]: {
    code: CountryCode.FR,
    name: 'Frankreich',
    prefix: '+33',
  },
  [CountryCode.GB]: {
    code: CountryCode.GB,
    name: 'Vereinigtes Königreich',
    prefix: '+44',
  },
  [CountryCode.GR]: {
    code: CountryCode.GR,
    name: 'Griechenland',
    prefix: '+30',
  },
  [CountryCode.HU]: {
    code: CountryCode.HU,
    name: 'Ungarn',
    prefix: '+36',
  },
  [CountryCode.IE]: {
    code: CountryCode.IE,
    name: 'Irland',
    prefix: '+353',
  },
  [CountryCode.IT]: {
    code: CountryCode.IT,
    name: 'Italien',
    prefix: '+39',
  },
  [CountryCode.HR]: {
    code: CountryCode.HR,
    name: 'Kroatien',
    prefix: '+385',
  },
  [CountryCode.JP]: {
    code: CountryCode.JP,
    name: 'Japan',
    prefix: '+81',
  },
  [CountryCode.LI]: {
    code: CountryCode.LI,
    name: 'Liechtenstein',
    prefix: '+423',
  },
  [CountryCode.LT]: {
    code: CountryCode.LT,
    name: 'Litauen',
    prefix: '+370',
  },
  [CountryCode.LU]: {
    code: CountryCode.LU,
    name: 'Luxemburg',
    prefix: '+352',
  },
  [CountryCode.LV]: {
    code: CountryCode.LV,
    name: 'Lettland',
    prefix: '+371',
  },
  [CountryCode.MT]: {
    code: CountryCode.MT,
    name: 'Malta',
    prefix: '+356',
  },
  [CountryCode.NL]: {
    code: CountryCode.NL,
    name: 'Niederlande',
    prefix: '+31',
  },
  [CountryCode.NO]: {
    code: CountryCode.NO,
    name: 'Norwegen',
    prefix: '+47',
  },
  [CountryCode.PL]: {
    code: CountryCode.PL,
    name: 'Polen',
    prefix: '+48',
  },
  [CountryCode.PT]: {
    code: CountryCode.PT,
    name: 'Portugal',
    prefix: '+351',
  },
  [CountryCode.RO]: {
    code: CountryCode.RO,
    name: 'Rumänien',
    prefix: '+40',
  },
  [CountryCode.SE]: {
    code: CountryCode.SE,
    name: 'Schweden',
    prefix: '+46',
  },
  [CountryCode.SI]: {
    code: CountryCode.SI,
    name: 'Slowenien',
    prefix: '+386',
  },
  [CountryCode.SK]: {
    code: CountryCode.SK,
    name: 'Slowakei',
    prefix: '+421',
  },
};
