<div (click)="stepBack()" class="back"><span>&laquo; Schritt zurück</span></div>
<div>
  <h3 *ngIf="linkSubscriptionMode === 'family'">Jetzt registrieren und am Familiengewinnspiel teilnehmen</h3>
  <h3 *ngIf="linkSubscriptionMode !== 'family'">TT-Benutzerkonto erstellen</h3>
</div>
<p>Schritt 2/2: Name und {{linkingType === 'telephone' ? 'Telefonnummer' : 'Kundennummer'}}</p>

<p *ngIf="linkingType === 'telephone'">Gerne aktivieren wir für Sie als Abonnent Ihr TT-Benutzerkonto. Dazu benötigen wir von Ihnen
  bitte noch die folgenden Informationen.</p>
  
<form [formGroup]="linkSubForm" (ngSubmit)="onSubmit()">
  <div class="name">
    <mat-form-field [appearance]="appearance">
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="firstName" autocomplete="off" type="text" placeholder="Vorname"/>
      <mat-error *ngIf="linkSubForm.controls.firstName.hasError('subscriptionNameError')">Vor/Nachname wird benötigt
      </mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="appearance">
      <mat-label>Nachname</mat-label>
      <input matInput formControlName="lastName" autocomplete="off" type="text" placeholder="Nachname"/>
      <mat-error *ngIf="linkSubForm.controls.lastName.hasError('subscriptionNameError')">Vor/Nachname wird benötigt
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field [appearance]="appearance" *ngIf="linkingType === 'customernumber'" class="sub-account-number-input">
    <mat-label>Kundennummer</mat-label>
    <input matInput formControlName="customerNumber" autocomplete="off" placeholder="Kundennummer" type="text" #customerNumberEl/>
    <mat-error *ngIf="linkSubForm.controls.customerNumber.hasError('required')"
    >Dieses Feld ist ein Pflichtfeld
    </mat-error>
  </mat-form-field>
  <div class="phone-wrapper" *ngIf="linkingType === 'telephone'">
    <mat-form-field [appearance]="appearance">
      <mat-label>Ländercode</mat-label>
      <mat-select formControlName="country">
        <mat-select-trigger>{{linkSubForm.value.country !== null ? countriesObj[linkSubForm.value.country].prefix : null}}</mat-select-trigger>
        <mat-option *ngFor="let item of countries" [value]="item.code">
          {{item.displayName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Telefonnummer</mat-label>
      <input matInput formControlName="phoneNumber" autocomplete="off" placeholder="Telefonnummer" type="tel" pattern="\+?[0-9]*"/>
      <mat-error *ngIf="linkSubForm.controls.phoneNumber.hasError('required')">Dieses Feld ist ein Pflichtfeld
      </mat-error>
      <mat-error *ngIf="linkSubForm.controls.phoneNumber.hasError('pattern')">Bitte geben sie ein gültige Telefonnummer
        an.
      </mat-error>
    </mat-form-field>
  </div>
  <p *ngIf="linkingType === 'customernumber'" class="hint">Die Kundennummer finden Sie auf Ihrer TT-Club-Karte, Ihrer Abobestätigung oder auf einer Ihrer Rechnungen.</p>
  <tt-com-anmeldung-ui-submit-buttton [buttonText]="'Registrieren'" 
                                      [form]="linkSubForm"
                                      [formDisabled]="formLoading"
                                      [success]="success">
  </tt-com-anmeldung-ui-submit-buttton>

  <tt-com-anmeldung-ui-default-message-box class="message-box-success" *ngIf="success" [boxType]="0">
    <p>{{ success.message }}</p>

    <ng-container *ngIf="redirectUrl !== null && linkSubscriptionMode !== 'family'">
      <p>Durch Klick auf “Weiter” gelangen Sie zur Ursprungsseite, von der aus Sie den Registrierungsprozess gestartet
        haben.</p>
      <button mat-raised-button (click)="doRedirect()">Weiter</button>
    </ng-container>

    <ng-container *ngIf="redirectUrl !== null && linkingType && linkSubscriptionMode === 'family'">
      <button mat-raised-button (click)="doRedirect()">Gewinnspielteilnahme abschließen</button>
    </ng-container>

    <div *ngIf="redirectUrl === null">
      <p>Folgende Möglichkeiten haben Sie nun mit Ihrem TT-Benutzerkonto:</p>
      <ul>
        <ng-container *ngIf="subscriptionActivated; else noSubscriptionActivated">
          <li><a href="https://www.tt.com/plus">TT-Plus-Artikel lesen</a></li>
          <li><a href="https://epaper.tt.com/">TT-ePaper lesen</a></li>
          <li><a href="https://club.tt.com">TT-Club Website besuchen</a></li>
          <li><a href="https://www.tt.com">tt.com-Startseite besuchen</a></li>
        </ng-container>
        <ng-template #noSubscriptionActivated>
          <li><a href="https://www.tt.com/plus">TT-Plus-Artikel lesen</a></li>
          <li><a href="https://www.tt.com">tt.com-Startseite besuchen</a></li>
        </ng-template>
      </ul>
    </div>
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box class="message-box-redirect-error" *ngIf="redirectError" [boxType]="1">
    {{ redirectError.message }}
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box class="message-box-subscription-error" *ngIf="subscriptionError"
                                           [boxType]="1">
    <h4>Die Verknüpfung Ihres Accounts mit Ihrem Konto ist Fehlgeschlagen.</h4>
    <p>Fehler: {{ subscriptionError.message }}</p>
    <p>Sie können die Verknüpfung jederzeit in Ihrem Profil vornehmen.</p>
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box class="message-box-http-error" *ngIf="httpError" [boxType]="2">
    {{ httpError.message }}
  </tt-com-anmeldung-ui-default-message-box>

  <div class="recaptcha">
    <candyland-grecaptcha #recaptcha></candyland-grecaptcha>
  </div>
</form>
