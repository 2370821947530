import { InjectionToken } from '@angular/core';

export interface ChangePasswordPageSettings {
  minPasswordLength: number;
  otpLength: number;
}

export const CHANGE_PASSWORD_PAGE_SETTINGS = new InjectionToken<ChangePasswordPageSettings>(
  'CHANGE_PASSWORD_PAGE_SETTINGS'
);
