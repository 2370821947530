import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PROFILE_API_ENDPOINT } from '@candyland/angular/shared/tt-com-anmeldung/model';
import type { HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type {
  AccountOTPVerificationRequestModel,
  AccountOTPVerificationResponseModel,
} from '@candyland/generic/tt-com-anmeldung/models';
import { HttpRequestHandler } from '@candyland/angular/shared/util-nmo';

@Injectable({
  providedIn: 'root',
})
export class EmailOtpVerificationStepService {
  constructor(private http: HttpClient, @Inject(PROFILE_API_ENDPOINT) private profileApiEndpoint: string) {}

  public verifyAccountWithOTP(otp: AccountOTPVerificationRequestModel) {
    return HttpRequestHandler.makeTrackable(
      this.http.post<HttpStatusModelTyped<AccountOTPVerificationResponseModel>>(
        `${this.profileApiEndpoint}/user/account/verify-otp`,
        otp
      )
    );
  }
}
