import type { OnInit } from '@angular/core';
import { Component, ViewChild, ElementRef, Inject, EventEmitter, PLATFORM_ID } from '@angular/core';
import { RegistrationPageSettings, REGISTRATION_PAGE_SETTINGS } from '../token';
import { isPlatformBrowser } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'candyland-grecaptcha',
  templateUrl: './grecaptcha.component.html',
  styleUrls: ['./grecaptcha.component.scss'],
})
export class GrecaptchaComponent implements OnInit {
  @ViewChild('recaptcha', { static: true })
  recaptchaElement: ElementRef;

  private recaptchaCode: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    @Inject(REGISTRATION_PAGE_SETTINGS) public settings: RegistrationPageSettings,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.addGRecaptchaScript();
  }

  public execute(): Promise<string> {
    return new Promise((resolve): void => {
      this.recaptchaCode.pipe(take(1)).subscribe((x: string): void => {
        resolve(x);
        window['grecaptcha'].reset();
      });

      window['grecaptcha'].execute();
    });
  }

  private addGRecaptchaScript(): void {
    if (!this.settings.gRecaptchaSiteKey || this.settings.gRecaptchaSiteKey === '') {
      return;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    window['grecaptchaCallback'] = (): void => {
      this.renderGRecaptcha();
    };

    const script: HTMLScriptElement = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }

  private renderGRecaptcha(): void {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: this.settings.gRecaptchaSiteKey,
      badge: 'inline',
      size: 'invisible',
      callback: (response): void => {
        this.recaptchaCode.next(response);
      },
      'expired-callback': (): void => {
        window['grecaptcha'].reset();
      },
    });
  }
}
