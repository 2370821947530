import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularTtComAnmeldungUiModule } from '@candyland/angular/tt-com-anmeldung/ui';
import { ActivateAccountPageComponent } from './activate-account-page/activate-account-page.component';
import { ChangeEmailSuccessComponent } from './change-email-success/change-email-success.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatCardModule, MatProgressSpinnerModule, AngularTtComAnmeldungUiModule, MatButtonModule],
  declarations: [ActivateAccountPageComponent, ChangeEmailSuccessComponent],
  exports: [ActivateAccountPageComponent],
})
export class AngularTtComAnmeldungFeatureActivateAccountModule {}
