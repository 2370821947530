import type { ErrorStateMatcher } from '@angular/material/core';
import type { FormGroupDirective, NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class RepeatPasswordStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;

    if (!control) {
      return false;
    }

    const invalCtrl = !!(control.invalid && (control.dirty || control.touched || isSubmitted));
    const invalPasswords = !!control.parent.errors?.notSame;

    return invalCtrl || invalPasswords;
  }
}

export function passwordRepeatValidator(form: UntypedFormGroup) {
  if (!form) {
    return null;
  }

  const password = form.controls.password.value;
  const passwordRepeat = form.controls.passwordRepeat.value;

  return password === passwordRepeat || !passwordRepeat ? null : { notSame: true };
}
