import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const window;

export const UNSAFE_DATA_LAYER = new InjectionToken('dataLayer abstraction', {
  providedIn: 'root',
  factory: () => getDataLayer(inject(PLATFORM_ID)),
});

function getDataLayer(platformId: Object): UnsafeDataLayer {
  if (isPlatformBrowser(platformId)) {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  } else {
    const dataLayer = [] as unknown as UnsafeDataLayer;
    dataLayer.push = () => true;
    return dataLayer;
  }
}

type X = { [key: string]: any }[];
export interface UnsafeDataLayer extends Pick<X, Exclude<keyof X, 'push'>> {
  push: (obj: any) => boolean;
}
