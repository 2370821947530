import { isPlatformBrowser } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import type { HttpStatusModel, HttpStatusModelTyped } from '@candyland/generic/shared/model-nmo';
import type { AccountVerificationResponseModel } from '@candyland/generic/tt-com-anmeldung/models';
import { interval, Observable, timer } from 'rxjs';
import { filter, map, scan, switchMap, take, tap } from 'rxjs/operators';
import { ActivateAccountService } from '../services/activate-account.service';
import type { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'candyland-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrls: ['./activate-account-page.component.scss'],
})
export class ActivateAccountPageComponent implements OnInit {
  httpError: HttpStatusModel = null;
  success: HttpStatusModelTyped<AccountVerificationResponseModel>;

  constructor(
    private activationService: ActivateAccountService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      timer(1000)
        .pipe(
          switchMap(() => this.route.queryParams),
          switchMap((q: Params) => this.activationService.activateAccount(q.vtoken, q.uid)),
          tap((x: HttpStatusModelTyped<AccountVerificationResponseModel>) => (this.success = x)),
          switchMap((d: HttpStatusModelTyped<AccountVerificationResponseModel>) => this.successWaitBeforeRedirect(d))
        )
        .subscribe({
          next: (success: HttpStatusModelTyped<AccountVerificationResponseModel>) => this.successHandler(success),
          error: (error) => this.errorHandler(error),
        });
    }
  }

  private errorHandler(error: HttpErrorResponse): void {
    console.log(error);
    this.httpError = error.error;
  }

  private successHandler(success: HttpStatusModelTyped<AccountVerificationResponseModel>): void {
    this.success = success;
  }

  private successWaitBeforeRedirect(
    d: HttpStatusModelTyped<AccountVerificationResponseModel>
  ): Observable<HttpStatusModelTyped<AccountVerificationResponseModel>> {
    return interval(1000).pipe(
      scan((acc: number) => --acc, 5),
      filter((x: number): boolean => x === 0),
      take(1),
      map(() => d)
    );
  }
}
