<mat-card appearance="outlined" class="section reset">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>

  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit(formGroup)">
    <ng-container *ngIf="!errorMessage">
      <p>
        Bitte geben Sie den Bestätigungscode ein, den wir an
        <span class="email-text">{{ queryParams.email }}</span> gesendet haben.
      </p>
      <mat-form-field [appearance]="'outline'">
        <mat-label>Bestätigungscode</mat-label>
        <input matInput formControlName="otp" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="formGroup.controls.otp.hasError('required')">Dieses Feld ist ein Pflichtfeld.</mat-error>
        <mat-error *ngIf="formGroup.controls.otp.hasError('pattern')">
          Bestätigungscode ungültig: Bitte überprüfen Sie den eingegebenen Code.
        </mat-error>
      </mat-form-field>
      <button
        *ngIf="!isOtpVerified"
        mat-raised-button
        (click)="isOtpVerified = true"
        [color]="'primary'"
        [disabled]="formGroup.controls.otp.invalid"
      >
        Weiter
      </button>
    </ng-container>

    <ng-container *ngIf="isOtpVerified && !errorMessage">
      <p>Bitte geben Sie hier Ihr neues Passwort an.</p>
      <mat-form-field [appearance]="'outline'">
        <mat-label> Neues Passwort </mat-label>
        <input
          matInput
          [type]="!showPassword ? 'password' : 'text'"
          formControlName="password"
          [errorStateMatcher]="matcher"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="showPassword = !showPassword"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="showPassword"
          type="button"
        >
          <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="formGroup.controls.password.hasError('required')">Dieses Feld ist ein Pflichtfeld.</mat-error>
        <mat-error *ngIf="formGroup.controls.password.hasError('minlength')">
          Mindestens {{ minPasswordLength }} Zeichen.
        </mat-error>
        <mat-hint>Mindestens {{ minPasswordLength }} Zeichen</mat-hint>
      </mat-form-field>

      <mat-form-field [appearance]="'outline'">
        <mat-label> Password wiederholen </mat-label>
        <input
          matInput
          [type]="!showPasswordRepeat ? 'password' : 'text'"
          formControlName="passwordRepeat"
          [errorStateMatcher]="matcher"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="showPasswordRepeat = !showPasswordRepeat"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="showPasswordRepeat"
          type="button"
        >
          <mat-icon>{{ !showPasswordRepeat ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="formGroup.controls.passwordRepeat.hasError('notequal')">
          Passwörter stimmen nicht überein.
        </mat-error>
        <mat-error *ngIf="formGroup.controls.passwordRepeat.hasError('required')">
          Dieses Feld ist ein Pflichtfeld.
        </mat-error>
      </mat-form-field>
      <tt-com-anmeldung-ui-submit-buttton
        buttonText="Absenden"
        [form]="formGroup"
        [buttonDisabled]="formGroup.disabled"
        [success]="success"
      ></tt-com-anmeldung-ui-submit-buttton>
      <a class="redo-reset" *ngIf="tokenInvalid" routerLink="/reset-password"
        >Bestätigungscode ungültig. Prozess neu starten?</a
      >
    </ng-container>

    <tt-com-anmeldung-ui-default-message-box
      *ngIf="httpErrorMessage || errorMessage"
      [boxType]="2"
      [message]="detailError"
    >
      <span [innerHtml]="httpErrorMessage || errorMessage"></span>
    </tt-com-anmeldung-ui-default-message-box>
    <tt-com-anmeldung-ui-default-message-box *ngIf="success" [boxType]="0">
      <span class="success-message">{{ success }}</span>
      <button
        type="button"
        *ngIf="redirectUrl !== null; else noRedirectUrlState"
        mat-raised-button
        (click)="doRedirect()"
      >
        Weiter
      </button>
    </tt-com-anmeldung-ui-default-message-box>
  </form>
</mat-card>

<ng-template #noRedirectUrlState>
  <div class="generic-redirections-block">
    <tt-com-anmeldung-ui-generic-redirect-block></tt-com-anmeldung-ui-generic-redirect-block>
  </div>
</ng-template>
