<mat-card appearance="outlined" class="section accept" *ngIf="!otpStep">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>

  <div class="family-member-acception-section section" *ngIf="!invitationError && !isLoading">
    <h3>Daten vervollständigen</h3>
    <p>Bitte überprüfen und vervollständigen Sie Ihre Daten.</p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field [appearance]="appearance">
        <mat-label>Anrede</mat-label>
        <mat-select formControlName="gender">
          <mat-option *ngFor="let gender of genders" [value]="gender.value">
            {{ gender.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="splitted-row">
        <mat-form-field [appearance]="appearance">
          <mat-label>Vorname</mat-label>
          <input matInput formControlName="firstname" type="text" placeholder="Vorname" />
          <mat-error *ngIf="form.controls.firstname.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance">
          <mat-label>Nachname</mat-label>
          <input matInput formControlName="lastname" type="text" placeholder="Nachname" />
          <mat-error *ngIf="form.controls.lastname.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field [appearance]="appearance">
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email" type="email" placeholder="E-Mail" readonly />
      </mat-form-field>

      <mat-form-field [appearance]="appearance">
        <mat-label>Geburtsdatum</mat-label>
        <input
          matInput
          formControlName="dateOfBirth"
          placeholder="TT.MM.JJJJ"
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="dateOfBirthPicker"
        />
        <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        <mat-error *ngIf="form.controls.dateOfBirth.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        <mat-error *ngIf="form.controls.dateOfBirth.touched && form.controls.dateOfBirth.invalid">
          Dieses Feld ist ungültig
        </mat-error>
      </mat-form-field>

      <div class="phone-wrapper">
        <mat-form-field [appearance]="appearance">
          <mat-label>Ländercode</mat-label>
          <mat-select formControlName="country">
            <mat-select-trigger
              >{{ form.value.country !== null ? countriesObj[form.value.country].prefix : null }}
            </mat-select-trigger>
            <mat-option *ngFor="let item of countries" [value]="item.code">
              {{ item.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Telefonnummer</mat-label>
          <input matInput formControlName="phone" placeholder="Telefonnummer" type="tel" pattern="\+?[0-9]*" />
          <mat-error *ngIf="form.controls.phone.hasError('pattern')"
            >Bitte geben sie ein gültige Telefonnummer an.
          </mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="!hasAnAccount">
        <mat-form-field [appearance]="appearance">
          <mat-label>Passwort</mat-label>
          <input matInput formControlName="password" placeholder="Passwort" type="password" />
          <mat-hint>Minimum {{ minPasswordLength }} Zeichen</mat-hint>
          <mat-error *ngIf="form.controls.password.hasError('minlength')">
            Das Passwort muss mindestens {{ minPasswordLength }} Zeichen haben.
          </mat-error>
          <mat-error *ngIf="form.controls.password.hasError('required')">Dieses Feld ist ein Pflichtfeld</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance">
          <mat-label>Passwort wiederholen</mat-label>
          <input matInput formControlName="passwordRepeat" placeholder="Passwort wiederholen" type="password" />
          <mat-error *ngIf="form.controls.passwordRepeat.hasError('minlength')">
            Das Passwort muss mindestens {{ minPasswordLength }} Zeichen haben.
          </mat-error>
          <mat-error *ngIf="form.controls.passwordRepeat.hasError('required')">
            Dieses Feld ist ein Pflichtfeld.
          </mat-error>
          <mat-error *ngIf="form.controls.passwordRepeat.hasError('notSame')">
            Passwörter stimmen nicht überein.
          </mat-error>
        </mat-form-field>
      </ng-container>

      <mat-checkbox color="primary" formControlName="termsAgreed">
        Ich akzeptiere die <a href="javascript:void(0);" (click)="openTermsDialog()">AGB</a>
      </mat-checkbox>

      <div class="buttons-wrapper">
        <tt-com-anmeldung-ui-submit-buttton [success]="success" [form]="form" buttonText="Speichern">
        </tt-com-anmeldung-ui-submit-buttton>
        <button mat-raised-button color="base" (click)="denyAcceptance()">Abbrechen</button>
      </div>
    </form>
  </div>

  <div class="loading-container" *ngIf="isLoading">
    <mat-spinner diameter="24"></mat-spinner>
  </div>

  <tt-com-anmeldung-ui-default-message-box *ngIf="invitationError" [boxType]="2">
    Ihr Einladungslink ist ungültig, entweder weil er abgelaufen ist oder der Link ungültig ist. Einladungen laufen
    sieben Tage nach dem Senden ab.
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box *ngIf="httpError" [boxType]="2">
    <span [innerHTML]="httpError.message"></span>
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box *ngIf="success" [boxType]="0"
    >{{ success.message }}
  </tt-com-anmeldung-ui-default-message-box>
</mat-card>

<div class="otp-step section" *ngIf="otpStep">
  <tt-com-anmeldung-ui-email-otp-verification></tt-com-anmeldung-ui-email-otp-verification>
</div>
