<mat-card appearance="outlined" class="section activation">
  <a href="https://www.tt.com">
    <img src="https://assets.tt.com/cd/logos/tiroler-tageszeitung.svg" alt="TT Logo" />
  </a>

  <div *ngIf="!httpError && !success" class="loading">
    <mat-spinner [diameter]="50"></mat-spinner><span>Ihr TT-Benutzerkonto wird aktiviert ...</span>
  </div>

  <tt-com-anmeldung-ui-default-message-box
    *ngIf="httpError"
    [boxType]="2"
    [scrollOnAppear]="false"
    [message]="httpError.message"
  >
    {{ httpError.message }}
  </tt-com-anmeldung-ui-default-message-box>

  <tt-com-anmeldung-ui-default-message-box
    *ngIf="success"
    class="message-box-success"
    [boxType]="0"
    [scrollOnAppear]="false"
  >
    <strong>Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.</strong>

    <div>
      <p>Folgende Möglichkeiten haben Sie nun mit Ihrem TT-Benutzerkonto:</p>
      <ul>
        <li><a href="https://epaper.tt.com/">TT-ePaper lesen</a></li>
        <li><a href="https://club.tt.com">TT-Club Website besuchen</a></li>
        <li><a href="https://www.tt.com">tt.com-Startseite besuchen</a></li>
        <li><a href="https://www.tt.com/plus">TT-Plus-Artikel lesen</a></li>
      </ul>
    </div>
  </tt-com-anmeldung-ui-default-message-box>
</mat-card>
